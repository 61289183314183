import {
  FETCH_MATCHED_QUESTIONS_SUCCESS,
  QUESTION_SELECTED,
  QUESTION_RESET
} from "src/actions/actionTypes";

export default function question(state = [], action) {
  switch (action.type) {
    case FETCH_MATCHED_QUESTIONS_SUCCESS:
      return state;
    default:
      return state;
  }
}

export function questionSelected(state = "", action) {
  switch (action.type) {
    case QUESTION_SELECTED: {
      return action.payload;
    }
    case QUESTION_RESET : {
      return ""
    }
    default:
      return state;
  }
}
