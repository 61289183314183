import {
  GET_BANK_LEDGERS_PAYMENT_RECEIPT_SUMMARY_SUCCESS,
  GET_BANK_LEDGER_CLOSING_BALANCES_LAST_30_DAYS_SUCCESS,
  GET_BANK_LEDGER_CLOSING_BALANCES_SUCCESS,
} from "src/actions/actionTypes";

const initialState = {
  ledgerClosingBalances: [],
  last30DaysClosingBalncesData: [],
  paymentsReceiptsSummaryData: [],
};

function bankLedgerReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BANK_LEDGER_CLOSING_BALANCES_SUCCESS: {
      return { ...state, ledgerClosingBalances: action.payload };
    }
    case GET_BANK_LEDGER_CLOSING_BALANCES_LAST_30_DAYS_SUCCESS: {
      return { ...state, last30DaysClosingBalncesData: action.payload };
    }
    case GET_BANK_LEDGERS_PAYMENT_RECEIPT_SUMMARY_SUCCESS: {
      return { ...state, paymentsReceiptsSummaryData: action.payload };
    }
    default:
      return state;
  }
}

export default bankLedgerReducer;
