import React, { useEffect } from "react";
import { Toast } from "primereact/toast";

const ToastMessage = ({
  severity = "success",
  summary = "Success",
  detail = "Message Content",
}) => {
  let toast;
  const showSuccess = () => {
    toast.show({
      severity,
      summary,
      detail,
      life: 3000,
    });
  };

  useEffect(() => {
    showSuccess();
  },[]);

  return (
    <>
    <div className="toast-mess">
      <Toast ref={(el) => (toast = el)} />
    </div>  
      {/* <Button
        label="Success"
        className="p-button-success"
        onClick={showSuccess}
      /> */}
    </>
  );
};
export default ToastMessage;
