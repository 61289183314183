import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUserId } from "src/util/miscUtil";

let isTawkConnected = false;

const LiveChat = () => {
  const userData = useSelector((state) => state?.userReduser?.loginUserData);

  const [, setState] = useState();

  useEffect(() => {
    // console.log(userData.userName);
    if (isTawkConnected && userData.userName && getUserId()) {
      window.$_Tawk.setAttributes(
        {
          name: userData?.userName,
          email: userData?.userEmail,
        },
        (error) => { }
      );
      //   window.$_Tawk.begin();
      window.$_Tawk.showWidget();
    } else if (!userData.userName && isTawkConnected) {
      if (window.$_Tawk.isVisitorEngaged()) {
        window.$_Tawk.endChat();
      }
      window.$_Tawk.hideWidget();
      //   window.location.reload();
    }
  }, [isTawkConnected, userData]);

  useEffect(() => {
    const interval = setInterval(() => {
      isTawkConnected = window?.$_Tawk?.connected;
      setState({});
      if (isTawkConnected) {
        clearInterval(interval);
      }
    }, 1000);
  }, []);

  return <></>;
};

export default LiveChat;
