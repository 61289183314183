import {
  SHARE_REPORT,
  SHARE_REPORT_FAILURE,
  SHARE_REPORT_SUCCESS,
  SHARE_REPORT_INIT_STATE,
} from "src/actions/actionTypes";
import { ReportSharingStatus } from "src/util/enums";

const initialState = {
  reportSharingStatus: ReportSharingStatus.NONE,
};

export default function reportsReducer(state = initialState, action) {
  switch (action.type) {
    case SHARE_REPORT_INIT_STATE: {
      return { ...state, reportSharingStatus: ReportSharingStatus.NONE };
    }
    case SHARE_REPORT: {
      return { ...state, reportSharingStatus: ReportSharingStatus.INPROGRESS };
    }
    case SHARE_REPORT_SUCCESS: {
      return { ...state, reportSharingStatus: ReportSharingStatus.SUCCESS };
    }
    case SHARE_REPORT_FAILURE: {
      return { ...state, reportSharingStatus: ReportSharingStatus.FAILED };
    }
    default:
      return state;
  }
}
