import {
    PUSH_NOTI_ADD_QUERY_SUCCESS,
    PUSH_NOTI_ADD_QUERY_FAILURE,

    PUSH_NOTI_CHANGE_PRIORITY_SUCCESS,
    PUSH_NOTI_CHANGE_PRIORITY_FAILURE,

    PUSH_NOTI_USER_COMMENTS_SUCCESS,
    PUSH_NOTI_USER_COMMENTS_FAILURE,
} from "src/actions/actionTypes";

const initialState = {
    pushNotiAddQueryResp:null,
    pushNotiPriorityChangedResp:null,
    pushNotiUserComments:null
};

export default function pushNotificationReducer(state = initialState, action) {
    switch (action.type) {
        case PUSH_NOTI_ADD_QUERY_SUCCESS : {
            return { ...state, pushNotiAddQueryResp: action.payload };
        }
        case PUSH_NOTI_ADD_QUERY_FAILURE : {
            return { ...state, pushNotiAddQueryResp: action.payload };
        }

        case PUSH_NOTI_CHANGE_PRIORITY_SUCCESS : {
            return { ...state, pushNotiPriorityChangedResp: action.payload };
        }
        case PUSH_NOTI_CHANGE_PRIORITY_FAILURE : {
            return { ...state, pushNotiPriorityChangedResp: action.payload };
        }

        case PUSH_NOTI_USER_COMMENTS_SUCCESS : {
            return { ...state, pushNotiUserComments: action.payload };
        }
        case PUSH_NOTI_USER_COMMENTS_FAILURE : {
            return { ...state, pushNotiUserComments: action.payload };
        }
        default:
            return state;
    }
}

