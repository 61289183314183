import {
    GET_GLOBAL_BUDGET_INIT,
    GET_GLOBAL_BUDGET_FINISH,
    GET_GLOBAL_BUDGET_SUCCESS,
    GET_GLOBAL_BUDGET_FAILED,
    GET_BUDGET_VS_ACTUAL_INIT,
    GET_BUDGET_VS_ACTUAL_FINISH,
    GET_BUDGET_VS_ACTUAL_SUCCESS,
    GET_BUDGET_VS_ACTUAL_FAILED,
    UPDATE_GLOBAL_BUDGET_INIT,
    UPDATE_GLOBAL_BUDGET_FINISH,
    UPDATE_GLOBAL_BUDGET_SUCCESS,
    UPDATE_GLOBAL_BUDGET_FAILED,
    GET_VARIANCE_INIT,
    GET_VARIANCE_FINISH,
    GET_VARIANCE_SUCCESS,
    GET_VARIANCE_FAILED,
    GLOBAL_BUDGET_LOADER_INIT,
    GLOBAL_BUDGET_LOADER_FINISH,
    GLOBAL_BUDGET_UPDATE,
    OPEN_MODAL,
    CLOSE_MODAL,
    RESET_GLOBAL_BUDGET_STATUS,
    BUDGET_FROM_DATE,
    BUDGET_TO_DATE,
    BUDGET_VS_ACTUAL_FROM_DATE,
    BUDGET_VS_ACTUAL_TO_DATE,
    VARIANCE_FROM_DATE,
    VARIANCE_TO_DATE,
    BUDGET_VARIANCE_DATE_RANGE,
    GLOBAL_VARIANCE_OLDEST_DATE,
    GLOBAL_VARIANCE_FROM_DATE,
    GLOBAL_VARIANCE_TO_DATE
  } from "src/actions/actionTypes";
  
  const inititalState = {
    globalBudgetReqStatus: false,
    globalBudgetStatus: null,
    globalBudgetData: null,
    globalBudgetDataRef: null,
    globalBudgetMessage: null,


    budgetVsActualReqStatus: false,
    budgetVsActualStatus: null,
    budgetVsActualData: null,
    budgetVsActualMessage: null,

    
    getVarianceReqStatus: false,
    getVarianceStatus: null,
    getVarianceData: null,
    getVarianceMessage: null,


    modalStaus: false,
    updateGlobalBudgetReqStatus: false,
    updateGlobalBudgetStatus: null,
    updateGlobalBudgetMessage: null,
    loaderStatus: false,
    budgetFromDate: null,
    budgetToDate: null,
    budgetVsActualFromDate: null,
    budgetVsActualToDate: null,
    
    varianceFromDate: null,
    varianceToDate: null,

    varianceDateRange: null,

    revenueDataNodes: null,
    globalVarianceOldestDate: null,    
    globalVarianceFromDate: null,    
    globalVarianceToDate: null,
  };
  
  export default function budgetReducer(state = inititalState, action) {
    switch (action.type) {

      case GET_GLOBAL_BUDGET_INIT: {
        return {...state, globalBudgetReqStatus: true}
      }
      case GET_GLOBAL_BUDGET_FINISH: {
        return {...state, globalBudgetReqStatus: false}
      }
      case GET_GLOBAL_BUDGET_SUCCESS: {
        return {...state, globalBudgetStatus: true, globalBudgetData: action.payload[0], globalBudgetDataRef: action.payload[0],  globalBudgetMessage: action.payload[1]}
      }
      case GET_GLOBAL_BUDGET_FAILED: {
        return {...state, globalBudgetStatus: false, globalBudgetMessage: action.payload}
      }

      case GET_BUDGET_VS_ACTUAL_INIT: {
        return {...state, budgetVsActualReqStatus: true}
      }
      case GET_BUDGET_VS_ACTUAL_FINISH: {
        return {...state, budgetVsActualReqStatus: false}
      }
      case GET_BUDGET_VS_ACTUAL_SUCCESS: {
        return {...state, budgetVsActualStatus: true, budgetVsActualData: action.payload[0], budgetVsActualMessage: action.payload[1]}
      }
      case GET_BUDGET_VS_ACTUAL_FAILED: {
        return {...state, budgetVsActualStatus: false, budgetVsActualMessage: action.payload}
      }














      case GET_VARIANCE_INIT: {
        return {...state, getVarianceReqStatus: true}
      }
      case GET_VARIANCE_FINISH: {
        return {...state, getVarianceReqStatus: false}
      }
      case GET_VARIANCE_SUCCESS: {
        return {...state, getVarianceStatus: true, getVarianceData: action.payload[0], getVarianceMessage: action.payload[1]}
      }
      case GET_VARIANCE_FAILED: {
        return {...state, getVarianceStatus: false, getVarianceMessage: action.payload}
      }














      case UPDATE_GLOBAL_BUDGET_INIT: {
        return {...state, updateGlobalBudgetReqStatus: true}
      }

      case UPDATE_GLOBAL_BUDGET_FINISH: {
        return {...state, updateGlobalBudgetReqStatus: false}
      }

      case UPDATE_GLOBAL_BUDGET_SUCCESS: {
        return {...state, updateGlobalBudgetStatus: action.payload[0], updateGlobalBudgetMessage: action.payload[1]}
      }

      case UPDATE_GLOBAL_BUDGET_FAILED: {
        return {...state, updateGlobalBudgetStatus: action.payload[0], updateGlobalBudgetMessage: action.payload[1]}
      }

      case GLOBAL_BUDGET_LOADER_INIT: {
        return {...state, loaderStatus: true}
      }
      
      case GLOBAL_BUDGET_LOADER_FINISH: {
        return {...state, loaderStatus: false}
      }

      case GLOBAL_BUDGET_UPDATE: {
        return {...state, globalBudgetData: action.payload}
      }

      case RESET_GLOBAL_BUDGET_STATUS: {
        return {...state, updateGlobalBudgetStatus: null}
      }

      case OPEN_MODAL: {
        return {...state, modalStaus: true}
      }
  
      case CLOSE_MODAL: {
        return {...state, modalStaus: false}
      }

      case BUDGET_FROM_DATE: {
        return {...state, budgetFromDate: action.payload}
      }

      case BUDGET_TO_DATE: {
        return {...state, budgetToDate: action.payload}
      }

      case BUDGET_VS_ACTUAL_FROM_DATE: {
        return {...state, budgetVsActualFromDate: action.payload}
      }

      case BUDGET_VS_ACTUAL_TO_DATE: {
        return {...state, budgetVsActualToDate: action.payload}
      }

      case VARIANCE_FROM_DATE: {
        return {...state, varianceFromDate: action.payload}
      }

      case VARIANCE_TO_DATE: {
        return {...state, varianceToDate: action.payload}
      }


      case BUDGET_VARIANCE_DATE_RANGE: {
        return {...state, varianceDateRange: action.payload}
      }


      case GLOBAL_VARIANCE_OLDEST_DATE: {
        return {...state, globalVarianceOldestDate: action.payload}
      }

      
    case GLOBAL_VARIANCE_FROM_DATE: {
      return {...state, globalVarianceFromDate: action.payload}
    }

    
    case GLOBAL_VARIANCE_TO_DATE: {
      return {...state, globalVarianceToDate: action.payload}
    }

      case 'SET_REVENUE_DATA_NODES': {
        return {...state, revenueDataNodes: action.payload}
      }

      default:
        return state;
    }
  }
  