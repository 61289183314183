import {
    COMMON_SEARCH_COUNT_SUCCESS,
    COMMON_SEARCH_TAGS_SUCCESS,
} from "src/actions/actionTypes";

const initialState = {
   commonSearchCount:[],
   commonSearchTags:[]
};

export default function dataSearchReducer(state = initialState, action) {
    switch (action.type) {
        case COMMON_SEARCH_COUNT_SUCCESS : {
            return { ...state, commonSearchCount: action.payload };
        }
        case COMMON_SEARCH_TAGS_SUCCESS : {
            return { ...state, commonSearchTags: action.payload };
        }
        
        default:
            return state;
    }
}

