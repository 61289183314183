import {
  GET_ALL_VOUCHERS_SUCCESS,
  GET_QUESTION_TRANSACTIONS_LIST_SUCCESS,
  GET_SELECTED_VOUCHER_DETAILS_SUCCESS,
  SAVE_VOUCHER_REMARKS,
  SAVE_VOUCHER_REMARKS_SUCCESS,
  SAVE_VOUCHER_REMARKS_FAILURE,
  GET_VOUCHERS_BETWEEN_DATES,
  GET_VOUCHERS_BETWEEN_DATES_SUCCESS,
  VOUCHER_REMARKS_DEFAULT,
  GET_VOUCHER_REMARKS_BY_ID_SUCCESS,
  GET_TRANSACTIONS_BY_AMOUNTS_SUCCESS,
  GET_TRANSACTIONS_BY_COMBINATION_SUCCESS,
  SET_FILTERED_JSON,
  RESET_FILTERED_JSON,
  GET_TRANSACTIONS_TYPES_SUCCESS,
  GET_REGISTERS_BY_TYPE_AND_DATES,
  GET_REGISTERS_BY_TYPE_AND_DATES_SUCCESS,
  GET_ALL_VOUCHERS,
  GET_EXPENSES_BY_AMOUNTS_SUCCESS,
  GET_EXPENSES_THIS_MONTH_SUCCESS,
  GET_ALL_VOUCHERS_BY_USER,
  GET_ALL_VOUCHERS_BY_USER_SUCCESS,
  GET_PAYMENT_OR_RECIPT_DATA_SUCCESS,
  GET_JOURNAL_DATA_SUCCESS,
  GET_PAYMENT_OR_RECIPT_DATA,
  GET_MONTHLY_WISE_SALES_REGISTER_SUCCESS,
  GET_MONTHLY_WISE_SALES_REGISTER,
  GET_PARTY_WISE_SALES_REGISTER,
  GET_PARTY_WISE_SALES_REGISTER_SUCCESS,
  GET_LEDGER_WISE_SALES_REGISTER,
  GET_LEDGER_WISE_SALES_REGISTER_SUCCESS,
  GET_ITEM_WISE_SALES_REGISTER,
  GET_ITEM_WISE_SALES_REGISTER_SUCCESS,
  GET_SALES_REGISTER_BY_MONTH,
  GET_SALES_REGISTER_BY_MONTH_SUCCESS,
  GET_SALES_REGISTER_BY_LEDGER,
  GET_SALES_REGISTER_BY_LEDGER_SUCCESS,
  GET_SALES_REGISTER_BY_PARTY,
  GET_SALES_REGISTER_BY_PARTY_SUCCESS,
  GET_SALES_REGISTER_BY_ITEM,
  GET_SALES_REGISTER_BY_ITEM_SUCCESS,
  SET_SELECTED_TRANSACTION_TYPES,
  CHECK_INVENTORY_APPLICABLE_SUCCESS,
  CHECK_INVENTORY_APPLICABLE,
  GET_ALL_VOUCHERS_REST,
  CHANGE_VOUCHER_MODE,
  CHANGE_VOUCHER_MODE_SUCCESS,
  CHANGE_VOUCHER_MODE_FAILURE,
  CHANGE_VOUCHER_MODE_DONE,
  GET_MONTH_PARTY_WISE_SALES_REGISTER,
  GET_MONTH_PARTY_WISE_SALES_REGISTER_SUCCESS,
  SELECTED_VOUCHER_LIST_TYPE,
  GET_ITEM_WISE_REGISTER_LIST_SUCCESS,
  GET_ITEM_WISE_REGISTER_LIST,
  GET_REGISTERWISE_VOUCHER_LIST,
  GET_REGISTERWISE_VOUCHER_LIST_SUCCESS,
  GET_CUSTOMERWISE_VOUCHER_LIST,
  GET_CUSTOMERWISE_VOUCHER_LIST_SUCCESS,
  GET_REG_LIST_BY_CUST_ID,
  GET_REG_LIST_BY_CUST_ID_SUCCESS
} from "src/actions/actionTypes";

import { getList, InsertFunctionStatus } from "src/util/enums";

const initialState = {
  vouchers: null,
  selectedVoucherId: -1,
  selectedVoucherDetails: {},
  questionTransactions: [],
  saveRemarksStatus: InsertFunctionStatus.NONE,
  selectedVoucherRemarks: {},
  vouchersBetweendates: [],
  vouchersByAmount: [],
  vouchersByCombination: [],
  filteredJson: {},
  transactionTypes: [],
  registersList: [],
  expensesByAmount: [],
  expensesByThisMonth: [],
  vouchersByUser: [],
  paymentOrReceiptData: {},
  journalData: {},
  monthlySalesRegistersList: [],
  salesRegistersListByMonth: [],
  partyWiseSalesRegistersList: [],
  salesRegistersListByParty: [],
  ledgerWiseSalesRegistersList: [],
  salesRegistersListByLedger: [],
  itemWiseSalesRegistersList: [],
  salesRegistersListByItem: [],
  selectedtransactionTypes: null,
  inventoryApplicableData: null,
  changeVoucherModeStatus: InsertFunctionStatus.NONE,
  monthParyWiseReciptRegList: null,
  selectedVoucherListType: "Regular",
  itemWiseRegisterList: [],
  registerwiseVoucherList: [],
  customerwiseVoucherList: [],
  regListByCustId: []
};

export default function voucherReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_VOUCHERS: {
      return { ...state, vouchers: getList.inprogress };
    }
    case GET_ALL_VOUCHERS_SUCCESS: {
      return { ...state, vouchers: action.payload };
    }
    case GET_ALL_VOUCHERS_REST: {
      return { ...state, vouchers: null };
    }
    case GET_SELECTED_VOUCHER_DETAILS_SUCCESS: {
      return { ...state, selectedVoucherDetails: action.payload };
    }
    case GET_QUESTION_TRANSACTIONS_LIST_SUCCESS: {
      return { ...state, questionTransactions: action.payload };
    }
    case SAVE_VOUCHER_REMARKS: {
      return { ...state, saveRemarksStatus: InsertFunctionStatus.INPROGRESS };
    }
    case SAVE_VOUCHER_REMARKS_SUCCESS: {
      return { ...state, saveRemarksStatus: InsertFunctionStatus.SUCCESS };
    }
    case SAVE_VOUCHER_REMARKS_FAILURE: {
      return { ...state, saveRemarksStatus: InsertFunctionStatus.FAILED };
    }
    case GET_VOUCHER_REMARKS_BY_ID_SUCCESS: {
      return { ...state, selectedVoucherRemarks: action.payload };
    }
    case GET_VOUCHERS_BETWEEN_DATES: {
      return { ...state, vouchers: getList.inprogress };
    }
    case GET_VOUCHERS_BETWEEN_DATES_SUCCESS: {
      return { ...state, vouchers: action.payload };
    }
    case VOUCHER_REMARKS_DEFAULT: {
      return { ...state, saveRemarksStatus: InsertFunctionStatus.NONE };
    }
    case GET_TRANSACTIONS_BY_AMOUNTS_SUCCESS: {
      return { ...state, vouchersByAmount: action.payload };
    }
    case GET_TRANSACTIONS_BY_COMBINATION_SUCCESS: {
      return { ...state, vouchersByCombination: action.payload };
    }
    case SET_FILTERED_JSON: {
      return { ...state, filteredJson: action.payload };
    }
    case SET_SELECTED_TRANSACTION_TYPES: {
      return { ...state, selectedtransactionTypes: action.payload };
    }
    case RESET_FILTERED_JSON: {
      return { ...state, filteredJson: {} };
    }
    case GET_TRANSACTIONS_TYPES_SUCCESS: {
      return { ...state, transactionTypes: action.payload };
    }
    case GET_REGISTERS_BY_TYPE_AND_DATES: {
      return { ...state, registersList: getList.inprogress };
    }
    case GET_REGISTERS_BY_TYPE_AND_DATES_SUCCESS: {
      return { ...state, registersList: action.payload };
    }
    case GET_EXPENSES_BY_AMOUNTS_SUCCESS: {
      return { ...state, expensesByAmount: action.payload };
    }
    case GET_EXPENSES_THIS_MONTH_SUCCESS: {
      return { ...state, expensesByThisMonth: action.payload };
    }
    case GET_ALL_VOUCHERS_BY_USER: {
      return { ...state, vouchersByUser: getList.inprogress };
    }
    case GET_ALL_VOUCHERS_BY_USER_SUCCESS: {
      return { ...state, vouchersByUser: action.payload };
    }
    case GET_PAYMENT_OR_RECIPT_DATA: {
      return { ...state, paymentOrReceiptData: getList.inprogress };
    }
    case GET_PAYMENT_OR_RECIPT_DATA_SUCCESS: {
      return { ...state, paymentOrReceiptData: action.payload };
    }
    case GET_JOURNAL_DATA_SUCCESS: {
      return { ...state, journalData: action.payload };
    }
    case GET_MONTHLY_WISE_SALES_REGISTER: {
      return { ...state, monthlySalesRegistersList: getList.inprogress };
    }
    case GET_MONTHLY_WISE_SALES_REGISTER_SUCCESS: {
      return { ...state, monthlySalesRegistersList: action.payload };
    }
    case GET_PARTY_WISE_SALES_REGISTER: {
      return { ...state, partyWiseSalesRegistersList: getList.inprogress };
    }
    case GET_PARTY_WISE_SALES_REGISTER_SUCCESS: {
      return { ...state, partyWiseSalesRegistersList: action.payload };
    }
    case GET_LEDGER_WISE_SALES_REGISTER: {
      return { ...state, ledgerWiseSalesRegistersList: getList.inprogress };
    }
    case GET_LEDGER_WISE_SALES_REGISTER_SUCCESS: {
      return { ...state, ledgerWiseSalesRegistersList: action.payload };
    }
    case GET_ITEM_WISE_SALES_REGISTER: {
      return { ...state, itemWiseSalesRegistersList: getList.inprogress };
    }
    case GET_ITEM_WISE_SALES_REGISTER_SUCCESS: {
      return { ...state, itemWiseSalesRegistersList: action.payload };
    }
    case GET_ITEM_WISE_REGISTER_LIST: {
      return { ...state, itemWiseRegisterList: getList.inprogress };
    }
    case GET_ITEM_WISE_REGISTER_LIST_SUCCESS: {
      return { ...state, itemWiseRegisterList: action.payload };
    }
    case GET_SALES_REGISTER_BY_MONTH: {
      return { ...state, salesRegistersListByMonth: getList.inprogress };
    }
    case GET_SALES_REGISTER_BY_MONTH_SUCCESS: {
      return { ...state, salesRegistersListByMonth: action.payload };
    }
    case GET_SALES_REGISTER_BY_LEDGER: {
      return { ...state, salesRegistersListByLedger: getList.inprogress };
    }
    case GET_SALES_REGISTER_BY_LEDGER_SUCCESS: {
      return { ...state, salesRegistersListByLedger: action.payload };
    }
    case GET_SALES_REGISTER_BY_PARTY: {
      return { ...state, salesRegistersListByParty: getList.inprogress };
    }
    case GET_SALES_REGISTER_BY_PARTY_SUCCESS: {
      return { ...state, salesRegistersListByParty: action.payload };
    }
    case GET_SALES_REGISTER_BY_ITEM: {
      return { ...state, salesRegistersListByItem: getList.inprogress };
    }
    case GET_SALES_REGISTER_BY_ITEM_SUCCESS: {
      return { ...state, salesRegistersListByItem: action.payload };
    }
    case CHECK_INVENTORY_APPLICABLE: {
      return { ...state, inventoryApplicableData: null };
    }
    case CHECK_INVENTORY_APPLICABLE_SUCCESS: {
      return { ...state, inventoryApplicableData: action.payload };
    }
    case CHANGE_VOUCHER_MODE: {
      return { ...state, changeVoucherModeStatus: InsertFunctionStatus.INPROGRESS };
    }
    case CHANGE_VOUCHER_MODE_FAILURE: {
      return { ...state, changeVoucherModeStatus: InsertFunctionStatus.FAILED };
    }
    case CHANGE_VOUCHER_MODE_SUCCESS: {
      return { ...state, changeVoucherModeStatus: InsertFunctionStatus.SUCCESS };
    }
    case CHANGE_VOUCHER_MODE_DONE: {
      return { ...state, changeVoucherModeStatus: InsertFunctionStatus.DONE };
    }
    case GET_MONTH_PARTY_WISE_SALES_REGISTER: {
      return { ...state, monthParyWiseReciptRegList: null };
    }
    case GET_MONTH_PARTY_WISE_SALES_REGISTER_SUCCESS: {
      return { ...state, monthParyWiseReciptRegList: action.payload };
    }
    case SELECTED_VOUCHER_LIST_TYPE: {
      return { ...state, selectedVoucherListType: action.payload };
    }
    case GET_REGISTERWISE_VOUCHER_LIST: {
      return { ...state, registerwiseVoucherList: getList.inprogress };
    }
    case GET_REGISTERWISE_VOUCHER_LIST_SUCCESS: {
      return { ...state, registerwiseVoucherList: action.payload };
    }
    case GET_CUSTOMERWISE_VOUCHER_LIST: {
      return { ...state, customerwiseVoucherList: getList.inprogress };
    }
    case GET_CUSTOMERWISE_VOUCHER_LIST_SUCCESS: {
      return { ...state, customerwiseVoucherList: action.payload };
    }
    case GET_REG_LIST_BY_CUST_ID: {
      return { ...state, regListByCustId: getList.inprogress };
    }
    case GET_REG_LIST_BY_CUST_ID_SUCCESS: {
      return { ...state, regListByCustId: action.payload };
    }
    default:
      return state;
  }
}
