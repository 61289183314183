import { GET_GROUP_LEDGER_TRANSACTIONS_SUCCESS } from "src/actions/actionTypes";

const initialState = {
  ledgerTransactions: [],
};

export default function groupLedgerReducer(state = initialState, action) {
  switch (action.type) {
    case GET_GROUP_LEDGER_TRANSACTIONS_SUCCESS: {
      return { ...state, ledgerTransactions: action.payload };
    }
    default:
      return state;
  }
}
