import { 
  GET_GROUPS_LIST_SUCCESS,
  GET_GROUP_LIST_WITH_CBAL_SUCCESS,
  GET_GROUP_DATA_BY_ID_SUCCESS,
  GET_GROUPS_LIST_BY_USER_SUCCESS,
  GET_GROUP_LIST_WITH_CBAL,
  GET_GROUP_DATA_BY_ID,
  GET_ALL_GROUP_LIST,
  GET_ALL_GROUP_LIST_SUCCESS,
  GET_ALL_GROUP_LIST_FAILURE,
 } from "src/actions/actionTypes";
import { getList } from "src/util/enums";

const initialState = {
  groupsList: [],
  groupList: [],
  groupData:{},
  groupListByUser:[],
  getAllGroupList:[]
};

export default function groupsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_GROUPS_LIST_SUCCESS: {
      return { ...state, groupsList: action.payload };
    }
    case GET_GROUP_LIST_WITH_CBAL: {
      return { ...state, groupList: getList.inprogress };
    }
    case GET_GROUP_LIST_WITH_CBAL_SUCCESS: {
      return { ...state, groupList: action.payload };
    }
    case GET_GROUP_DATA_BY_ID: {
      return { ...state, groupData: getList.inprogress };
    }
    case GET_GROUP_DATA_BY_ID_SUCCESS: {
      return { ...state, groupData: action.payload };
    }
    case GET_GROUPS_LIST_BY_USER_SUCCESS: {
      return { ...state, groupListByUser: action.payload };
    }
    case GET_ALL_GROUP_LIST: {
      return { ...state, getAllGroupList: getList.inprogress };
    }
    case GET_ALL_GROUP_LIST_SUCCESS: {
      return { ...state, getAllGroupList: action.payload };
    }
    case GET_ALL_GROUP_LIST_FAILURE: {
      return { ...state, getAllGroupList: action.payload };
    }
    default:
      return state;
  }
}
