import {
   SAVE_TAGS,
   SAVE_TAGS_SUCCESS,
   SAVE_TAGS_FAILURE,
   GET_TAGS,
   GET_TAGS_SUCCESS,
   GET_TAGS_FAILURE,
   CHIPS_TO_EMPTY,
   GET_ALL_TAGS_SUCCESS,
   GET_ALL_TAGS,
   GET_TAGS_DATA_BY_TAG_NAME_SUCCESS,
   GET_TAGS_DATA_BY_TAG_NAME
  } from "src/actions/actionTypes";
  import { InsertFunctionStatus } from "src/util/enums";
  
  const initialState = {
    tagsSaveStatus: InsertFunctionStatus.NONE,
    tagsList:[],
    allTagsList: null,
    tagsDetailsByTag: null
  };
  
  export default function chipsReducer(state = initialState, action) {
    switch (action.type) {
      case SAVE_TAGS: {
        return { ...state, tagsSaveStatus: InsertFunctionStatus.INPROGRESS };
      }
      case SAVE_TAGS_SUCCESS: {
        return { ...state, tagsSaveStatus: InsertFunctionStatus.SUCCESS };
      }
      case SAVE_TAGS_FAILURE: {
        return { ...state, tagsSaveStatus: InsertFunctionStatus.FAILED };
      }
      case GET_TAGS_SUCCESS: {
        return { ...state, tagsList: action.payload };
      }
      case CHIPS_TO_EMPTY: {
        return { ...state, tagsList: [] }
      }
      case GET_ALL_TAGS: {
        return { ...state, allTagsList: null }
      }
      case GET_ALL_TAGS_SUCCESS: {
        return { ...state, allTagsList: action.payload }
      }
      case GET_TAGS_DATA_BY_TAG_NAME: {
        return { ...state, tagsDetailsByTag: null }
      }
      case GET_TAGS_DATA_BY_TAG_NAME_SUCCESS: {
        return { ...state, tagsDetailsByTag: action.payload }
      }
      // 
      default:
        return state;
    }
  }
  