import {

    GET_PRACTICE_REG_INIT,
    GET_PRACTICE_REG_FINISH,
    GET_PRACTICE_REG_SUCCESS,
    GET_PRACTICE_REG_FAILED,


    GET_CLIENT_REG_INIT,
    GET_CLIENT_REG_FINISH,
    GET_CLIENT_REG_SUCCESS,
    GET_CLIENT_REG_FAILED,

    GET_TASK_LIST_INIT,
    GET_TASK_LIST_FINISH,
    GET_TASK_LIST_SUCCESS,
    GET_TASK_LIST_FAILED,

    GET_SCHEDULE_TASK_LIST_INIT,
    GET_SCHEDULE_TASK_LIST_FINISH,
    GET_SCHEDULE_TASK_LIST_SUCCESS,
    GET_SCHEDULE_TASK_LIST_FAILED,


    PM_REGISTRATION_INIT,
    PM_REGISTRATION_FINISH,
    PM_REGISTRATION_SUCCESS,
    PM_REGISTRATION_FAILED,


    UPDATE_PRACTICE_REG_STATUS,
    UPDATE_PRACTICE_INIT,
    UPDATE_PRACTICE_FINISH,

    CLIENT_REGISTRATION_MESSAGE,

    CLIENT_REGISTRATION_INIT,
    CLIENT_REGISTRATION_FINISH,
    CLIENT_REGISTRATION_SUCCESS,
    CLIENT_REGISTRATION_FAILED,

    TASK_ALLOCATION_INIT,
    TASK_ALLOCATION_FINISH,
    TASK_ALLOCATION_SUCCESS,
    TASK_ALLOCATION_FAILED,

    ADD_USER_INIT,
    ADD_USER_FINISH,
    ADD_USER_SUCCESS,
    ADD_USER_FAILED,


    GET_USERS_INIT,
    GET_USERS_FINISH,
    GET_USERS_SUCCESS,
    GET_USERS_FAILED,

    FIRM_CHANGE_UPDATE,

    DELETE_PRACTICE_STATUS,
    DELETE_CLIENT_STATUS,
    DELETE_TASK_STATUS,
    DELETE_TASK_DATA,


    UPDATE_TASK_STATUS,
    UPDATE_TASK_DATA,
    UPDATE_CLIENT_STATUS,

    UPDATE_TASK_MODAL,
    DELETE_ATTACHMENT_STATUS,
    UPDATE_TASK_LIST,
    CATEGORY_LIST_DATA,
    GET_COMMENTS_LIST,
    ADD_COMMENTS_SUCCESS,

    GET_COMPLETED_TASK_LIST,
    GET_COMPLETED_TASK_LIST_SUCCESS,
    GET_PRACTICE_DATA_BY_ID,
    GET_PRACTICE_DATA_BY_ID_SUCCESS,
    SELECTED_TASK_DATA,

    GSTIN_DETAILS,

    VIEW_ADD_TASK_MODAL,


    GET_COMPLIANCE_INIT,
    GET_COMPLIANCE_FINISH,
    GET_COMPLIANCE_SUCCESS,
    GET_COMPLIANCE_FAILED,


    BUCKET_GROUP_TYPE,
    BUCKET_LIST,

    ADD_COMPLIANCE_TO_CLIENT_INIT,
    ADD_COMPLIANCE_TO_CLIENT_FINISH,
    ADD_COMPLIANCE_TO_CLIENT_MESSAGE,

    UPDATE_COMLIANCE_INIT,
    UPDATE_COMLIANCE_FINISH,
    UPDATE_COMLIANCE_DATA,

    VIEW_ADD_CLIENT_MODAL,

    GET_CHARTS_INIT,
    GET_CHARTS_FINISH,
    GET_CHARTS_DATA,
    GET_CHARTS_FAILED,

    COMPLIANCE_APPLICABLE_DATA,
    DELETE_COMPLIANCE_APPLICABLE_DATA,

    GET_DOC_LIST_INIT,
    GET_DOC_LIST_FINISH,
    GET_DOC_LIST_SUCCESS,
    GET_DOC_LIST_FAILED,


    DELETE_COMPLIANCE_INIT,
    DELETE_COMPLIANCE_FINISH,
    DELETE_COMPLIANCE_SUCCSESS,
    DELETE_COMPLIANCE_FAILED,

    ADD_COMPLIANCE_INIT,
    ADD_COMPLIANCE_FINISH,
    ADD_COMPLIANCE_SUCCESS,
    ADD_COMPLIANCE_FAILED,

    UPDATE_SINGLE_DUE_DATE_DATA,

    GET_REMAINDER_DATA,
    GET_REMAINDER_MESSAGE,

    REMAINDER_EMAIL_INIT,
    REMAINDER_EMAIL_FINISH,
    REMAINDER_EMAIL_MESSAGE,
    REMAINDER_EMAIL_STATUS,

    
    REMAINDER_WHATSAPP_INIT,
    REMAINDER_WHATSAPP_FINISH,
    REMAINDER_WHATSAPP_MESSAGE,
    REMAINDER_WHATSAPP_STATUS,

    GET_BILLS_LIST,
    GET_BILLS_LIST_SUCCESS,
    GET_BILL_DATA_BY_ID,
    GET_BILL_DATA_BY_ID_SUCCESS,

    GET_REMAINDER_HISTORY_INIT,
    GET_REMAINDER_HISTORY_FINISH,
    GET_REMAINDER_HISTORY_DATA,


    GET_EXPENDITURE_BILLS_INIT,
    GET_EXPENDITURE_BILLS_FINISH,
    GET_EXPENDITURE_BILLS_SUCCESS,
    GET_EXPENDITURE_BILLS_FAILED,

    GET_BILLS_BY_ID_INIT,
    GET_BILLS_BY_ID_FINISH,
    GET_BILLS_BY_ID_SUCCESS,
    GET_BILLS_BY_ID_FAILED,

    DOC_ATTACHMENT_INIT,
    DOC_ATTACHMENT_FINISH,
    DOC_ATTACHMENT_DATA,

    UPDATE_EXP_BILLS_INIT,
    UPDATE_EXP_BILLS_FINISH,
    UPDATE_EXP_BILLS_SUCCESS,
    UPDATE_EXP_BILLS_FAILED,

    GENERATE_EXP_BILLS_INIT,
    GENERATE_EXP_BILLS_FINISH,
    GENERATE_EXP_BILLS_SUCCESS,
    GENERATE_EXP_BILLS_FAILED,


    GET_EXP_STATEMENT_INIT,
    GET_EXP_STATEMENT_FINISH,
    GET_EXP_STATEMENT_SUCCESS,
    GET_EXP_STATEMENT_FAILED,


    GET_EXP_PAYMENTS_INIT,
    GET_EXP_PAYMENTS_FINISH,
    GET_EXP_PAYMENTS_SUCCESS,
    GET_EXP_PAYMENTS_FAILED,


    CREATE_EXP_PAYMENTS_INIT,
    CREATE_EXP_PAYMENTS_FINISH,
    CREATE_EXP_PAYMENTS_SUCCESS,
    CREATE_EXP_PAYMENTS_FAILED,


    UPDATE_EXP_PAYMENTS_INIT,
    UPDATE_EXP_PAYMENTS_FINISH,
    UPDATE_EXP_PAYMENTS_SUCCESS,
    UPDATE_EXP_PAYMENTS_FAILED,
    
    DELETE_PRACTICE_INIT,
    DELETE_PRACTICE_FINISH,
    GET_AGAINST_BILL_DATA,
    
    UPDATE_BILL_AMOUNT,
    UPDATE_BILL_AMOUNT_SUCCESS,
    
    
    
    DOCUMENT_ERROR,
    COPY_TASK,

    DELETE_USER_INVITATION_INIT,
    DELETE_USER_INVITATION_FINISH,
    DELETE_USER_INVITATION_DATA,

    DELETE_COMPANY_USER_DATA,

} from "src/actions/PracticeManageActionTypes";

const initialState = {

    firmDetails: {},

    getPracRegReqStatus: false,
    getPracRegStatus: null,
    getPracRegData: null,
    getPracRegMessage: '',


    getClientRegReqStatus: false,
    getClientRegStatus: null,
    getClientRegData: null,
    getClientRegMessage: '',


    getTaskListReqStatus: false,
    getTaskListStatus: null,
    TaskListData: null,
    getTaskListMessage: '',



    scheduleTaskListReqStatus: false,
    scheduleTaskListStatus: null,
    scheduleTaskListData: null,
    scheduleTaskListMessage: '',


    PMRegReqStatus: false,
    PMRegStatus: null,
    PMRegMessage: '',

    clientRegReqStatus: false,
    clientRegStatus: null,
    clientRegMessage: '',
    clienRegData: null,


    taskAllocationReqStatus: false,
    taskAllocationData: null,
    taskAllocationStatus: null,
    taskAllocationMessage: '',

    deletePracticeReqStatus: false,
    deletePracticeStatus: null,
    deleteClientStatus: null,
    deleteTaskStatus: null,
    deleteTaskData: null,

    updatePracticeRegReqStatus: false,
    updatePracticeReg: null,


    updateTask: null,
    updateClient: null,


    addUserReqStatus: false,
    addUserStatus: null,
    addUserMessage: '',


    getUsersReqStatus: false,
    getUsersStatus: null,
    getUsersData: null,

    updateTaskModal: false,
    updateTaskData: null,

    categoryListData: null,
    commentList: null,
    addCommentStatus: null,
    deleteAttachmentData: null,

    categoryListData: null,

    completedTaskList: null,
    practiceDataById: null,
    selectedTaskData: null,

    GstinDetails: null,
    addTaskModal: false,

    complianceDataReqStatus: false,
    complianceDataStatus: null,
    complianceData: null,
    complianceDataMsg: '',


    bucketGroup: 'Bucket',
    bucketList: [],

    setAssigneeVal: '',
    setDueDateVal: '',
    setProgressVal: '',
    setPriorityVal: '',


    addComplianceToClientReqStatus: false,
    addComplianceToClientMsg: '',

    updateComplianceReqStatus: false,
    updateComplianceData: null,

    addClientModal: false,
    selectedAssignee: '',



    chartDataReqStatus: false,
    chartData: null,
    complianceApplicableData: [],
    deleteComplianceApplicableData: null,


    docListReqStatus: false,
    docListData: null,
    docListStatus: false,
    docListMessage: '',


    deleteComplinaceReqStatus: false,
    deleteComplinaceStatus: null,
    deleteComplinaceData: null,
    deleteComplinaceMsg: '',


    addComplinaceReqStatus: false,
    addComplinaceStatus: null,
    addComplinaceData: null,
    addComplinaceMsg: '',


    singleDueDateData: null,

    remainderData: null,
    remainderDataMsg: '',


    remainderEmailReqStatus: false,
    remainderEmailStatus: null,
    remainderEmailMessage: '',

    remainderWhatsappReqStatus: false,
    remainderWhatsappStatus: null,
    remainderWhatsappMessage: '',

    billsList: null,
    billDataById: null,
    addComplianceModal: false,


    remainderHistoryReqStatus: false,
    remainderHistoryData: null,


    expenditureBillReqStatus: false,
    expenditureBillData: null,


    expenditureByIDReqStatus: false,
    expenditureByIDData: null,


    docAttachmentReqStatus: false,
    docAttachmentData: null,



    addExpBillReqStatus: false,
    addExpBillData: null,


    updateExpBillReqStatus: false,
    updateExpBillData: null,


    expBillStatementReqStatus: false,
    expBillStatementData: null,



    expBillPaymentReqStatus: false,
    expBillPaymentData: null,



    addPaymentReqStatus: false,
    addPaymentData: null,

    updatePaymentReqStatus: false,
    updatePaymentData: null,

    expenditureBillNumber: null,

    getAgainstBillsData: null,
    billUpdateStatus: null,

    documentError: false,

    copyTask: false,

    editComlianceData: null,

    viewComplianceCategory: '',

    deleteUserInvitationData: null,

    deleteCompanyUserData: null,


    taskClientID: '',
    taskBucketID: '',

};
export default function PMReducer(state = initialState, action) {
    switch (action.type) {

        case "SET_TASK_CLIENT_ID": {
            return {...state, taskClientID: action.payload}
        }

        
        case "SET_TASK_BUCKET_ID": {
            return {...state, taskBucketID: action.payload}
        }

        case DELETE_COMPANY_USER_DATA: {
            return {...state, deleteCompanyUserData: action.payload}
        }

        case DELETE_USER_INVITATION_DATA: {
            return {...state, deleteUserInvitationData: action.payload}
        }

        case COPY_TASK: {
            return {...state, copyTask: action.payload}
        }

        case DOCUMENT_ERROR: {
            return { ...state, documentError: action.payload };
          }   

        case GET_AGAINST_BILL_DATA: {
            return {...state, getAgainstBillsData: action.payload}
        }

        case "GET_BILL_NUMBER": {
            return {...state, expenditureBillNumber: action.payload}
        }
        
        case UPDATE_EXP_PAYMENTS_INIT: {
            return { ...state, updatePaymentReqStatus: true, updatePaymentData: null }
        }

        case UPDATE_EXP_PAYMENTS_FINISH: {
            return { ...state, updatePaymentReqStatus: false }
        }

        case UPDATE_EXP_PAYMENTS_SUCCESS: {
            return { ...state, updatePaymentData: action.payload }
        }

        case UPDATE_EXP_PAYMENTS_FAILED: {
            return { ...state, updatePaymentData: action.payload }
        }

        /////////////////////////////

        case CREATE_EXP_PAYMENTS_INIT: {
            return { ...state, addPaymentReqStatus: true, addPaymentData: null }
        }

        case CREATE_EXP_PAYMENTS_FINISH: {
            return { ...state, addPaymentReqStatus: false }
        }

        case CREATE_EXP_PAYMENTS_SUCCESS: {
            return { ...state, addPaymentData: action.payload }
        }

        case CREATE_EXP_PAYMENTS_FAILED: {
            return { ...state, addPaymentData: action.payload }
        }


        case GET_EXP_PAYMENTS_INIT: {
            return { ...state, expBillPaymentReqStatus: true, expBillPaymentData: null }
        }

        case GET_EXP_PAYMENTS_FINISH: {
            return { ...state, expBillPaymentReqStatus: false }
        }

        case GET_EXP_PAYMENTS_SUCCESS: {
            return { ...state, expBillPaymentData: action.payload }
        }

        case GET_EXP_PAYMENTS_FAILED: {
            return { ...state, expBillPaymentData: action.payload }
        }




        case GET_EXP_STATEMENT_INIT: {
            return { ...state, expBillStatementReqStatus: true, expBillStatementData: null }
        }

        case GET_EXP_STATEMENT_FINISH: {
            return { ...state, expBillStatementReqStatus: false }
        }

        case GET_EXP_STATEMENT_SUCCESS: {
            return { ...state, expBillStatementData: action.payload }
        }

        case GET_EXP_STATEMENT_FAILED: {
            return { ...state, expBillStatementData: action.payload }
        }







        case GENERATE_EXP_BILLS_INIT: {
            return { ...state, addExpBillReqStatus: true, addExpBillData: null }
        }

        case GENERATE_EXP_BILLS_FINISH: {
            return { ...state, addExpBillReqStatus: false }
        }

        case GENERATE_EXP_BILLS_SUCCESS: {
            return { ...state, addExpBillData: action.payload }
        }

        case GENERATE_EXP_BILLS_FAILED: {
            return { ...state, addExpBillData: action.payload }
        }




        case UPDATE_EXP_BILLS_INIT: {
            return { ...state, updateExpBillReqStatus: true, updateExpBillData: null }
        }

        case UPDATE_EXP_BILLS_FINISH: {
            return { ...state, updateExpBillReqStatus: false }
        }

        case UPDATE_EXP_BILLS_SUCCESS: {
            return { ...state, updateExpBillData: action.payload }
        }

        case UPDATE_EXP_BILLS_FAILED: {
            return { ...state, updateExpBillData: action.payload }
        }

        case DOC_ATTACHMENT_INIT: {
            return { ...state, docAttachmentReqStatus: true, docAttachmentData: null }
        }

        case DOC_ATTACHMENT_FINISH: {
            return { ...state, docAttachmentReqStatus: false }
        }

        case DOC_ATTACHMENT_DATA: {
            return { ...state, docAttachmentData: action.payload }
        }

        case GET_BILLS_BY_ID_INIT: {
            return { ...state, expenditureByIDReqStatus: true, expenditureByIDData: null }
        }

        case GET_BILLS_BY_ID_FINISH: {
            return { ...state, expenditureByIDReqStatus: false }
        }

        case GET_BILLS_BY_ID_SUCCESS: {
            return { ...state, expenditureByIDData: action.payload }
        }

        case GET_BILLS_BY_ID_FAILED: {
            return { ...state, expenditureByIDData: action.payload }
        }


        case GET_EXPENDITURE_BILLS_INIT: {
            return { ...state, expenditureBillReqStatus: true, expenditureBillData: null }
        }

        case GET_EXPENDITURE_BILLS_FINISH: {
            return { ...state, expenditureBillReqStatus: false }
        }

        case GET_EXPENDITURE_BILLS_SUCCESS: {
            return { ...state, expenditureBillData: action.payload }
        }

        case GET_EXPENDITURE_BILLS_FAILED: {
            return { ...state, expenditureBillData: action.payload }
        }



        case GET_REMAINDER_HISTORY_INIT: {
            return { ...state, remainderHistoryReqStatus: true }
        }

        case GET_REMAINDER_HISTORY_FINISH: {
            return { ...state, remainderHistoryReqStatus: false }
        }

        case GET_REMAINDER_HISTORY_DATA: {
            return { ...state, remainderHistoryData: action.payload }
        }

        case "VIEW_ADD_COMPLIANCE_MODAL": {
            return { ...state, addComplianceModal: action.payload }
        }

        case "VIEW_EDIT_COMPLIANCE_MODAL": {
            return {...state, editComlianceData: action.payload}
        }
        
        case "VIEW_COMPLIANCE_MODAL_CATEGORY": {
            return {...state, viewComplianceCategory: action.payload}
        }

        case REMAINDER_EMAIL_INIT: {
            return { ...state, remainderEmailReqStatus: true }
        }

        case REMAINDER_EMAIL_FINISH: {
            return { ...state, remainderEmailReqStatus: false }
        }

        case REMAINDER_EMAIL_MESSAGE: {
            return { ...state, remainderEmailMessage: action.payload }
        }

        case REMAINDER_EMAIL_STATUS: {
            return { ...state, remainderEmailStatus: action.payload }
        }




        
        case REMAINDER_WHATSAPP_INIT: {
            return { ...state, remainderWhatsappReqStatus: true }
        }

        case REMAINDER_WHATSAPP_FINISH: {
            return { ...state, remainderWhatsappReqStatus: false }
        }

        case REMAINDER_WHATSAPP_MESSAGE: {
            return { ...state, remainderWhatsappMessage: action.payload }
        }

        case REMAINDER_WHATSAPP_STATUS: {
            return { ...state, remainderWhatsappStatus: action.payload }
        }




        case GET_REMAINDER_DATA: {
            return { ...state, remainderData: action.payload }
        }

        case GET_REMAINDER_MESSAGE: {
            return { ...state, remainderDataMsg: action.payload }
        }

        case UPDATE_SINGLE_DUE_DATE_DATA: {
            return { ...state, singleDueDateData: action.payload }
        }

        case ADD_COMPLIANCE_INIT: {
            return { ...state, addComplinaceReqStatus: true, addComplinaceStatus: null, addComplinaceData: null, addComplinaceMsg: '' }
        }

        case ADD_COMPLIANCE_FINISH: {
            return { ...state, addComplinaceReqStatus: false }
        }

        case ADD_COMPLIANCE_SUCCESS: {
            return { ...state, addComplinaceStatus: action.payload[0], addComplinaceMsg: action.payload[1], addComplinaceData: action.payload[2] }
        }

        case ADD_COMPLIANCE_FAILED: {
            return { ...state, addComplinaceStatus: action.payload[0], addComplinaceMsg: action.payload[1], addComplinaceData: action.payload[2] }
        }

        case DELETE_COMPLIANCE_INIT: {
            return { ...state, deleteComplinaceReqStatus: true, deleteComplinaceStatus: null, deleteComplinaceData: null, deleteComplinaceMsg: '' }
        }

        case DELETE_COMPLIANCE_FINISH: {
            return { ...state, deleteComplinaceReqStatus: false }
        }

        case DELETE_COMPLIANCE_SUCCSESS: {
            return { ...state, deleteComplinaceStatus: action.payload[0], deleteComplinaceMsg: action.payload[1], deleteComplinaceData: action.payload[2] }
        }

        case GET_DOC_LIST_INIT: {
            return { ...state, docListReqStatus: true, docListData: null, docListStatus: false, docListMessage: '', }
        }

        case GET_DOC_LIST_FINISH: {
            return { ...state, docListReqStatus: false }
        }

        case GET_DOC_LIST_SUCCESS: {
            return { ...state, docListStatus: action.payload[0], docListMessage: action.payload[1], docListData: action.payload[2] }
        }

        case GET_DOC_LIST_FAILED: {
            return { ...state, docListStatus: action.payload[0], docListMessage: action.payload[1], docListData: action.payload[2] }
        }

        case DELETE_COMPLIANCE_APPLICABLE_DATA: {
            return { ...state, deleteComplianceApplicableData: action.payload }
        }

        case COMPLIANCE_APPLICABLE_DATA: {
            return { ...state, complianceApplicableData: action.payload }
        }

        case GET_CHARTS_INIT: {
            return { ...state, chartDataReqStatus: true, chartData: null }
        }

        case GET_CHARTS_FINISH: {
            return { ...state, chartDataReqStatus: false }
        }

        case GET_CHARTS_DATA: {
            return { ...state, chartData: action.payload }
        }



        case "SELECTED_TASK_ASSIGNEE": {
            return { ...state, selectedAssignee: action.payload }
        }

        case VIEW_ADD_CLIENT_MODAL: {
            return { ...state, addClientModal: action.payload }
        }

        case UPDATE_COMLIANCE_INIT: {
            return { ...state, updateComplianceReqStatus: true }
        }

        case UPDATE_COMLIANCE_FINISH: {
            return { ...state, updateComplianceReqStatus: false }
        }

        case UPDATE_COMLIANCE_DATA: {
            return { ...state, updateComplianceData: action.payload }
        }


        case ADD_COMPLIANCE_TO_CLIENT_INIT: {
            return { ...state, addComplianceToClientReqStatus: action.payload }
        }

        case ADD_COMPLIANCE_TO_CLIENT_FINISH: {
            return { ...state, addComplianceToClientReqStatus: action.payload }
        }

        case ADD_COMPLIANCE_TO_CLIENT_MESSAGE: {
            return { ...state, addComplianceToClientMsg: action.payload }
        }




        case "SET_ASSIGNEE_FOR_TASK": {
            return { ...state, setAssigneeVal: action.payload }
        }

        case "SET_DUE_DATE_FOR_TASK": {
            return { ...state, setDueDateVal: action.payload }
        }


        case "SET_PROGRESS_FOR_TASK": {
            return { ...state, setProgressVal: action.payload }
        }

        case "SET_PRIORITY_FOR_TASK": {
            return { ...state, setPriorityVal: action.payload }
        }





        case BUCKET_LIST: {
            return { ...state, bucketList: action.payload }
        }

        case BUCKET_GROUP_TYPE: {
            return { ...state, bucketGroup: action.payload }
        }


        case GET_COMPLIANCE_INIT: {
            return { ...state, complianceDataReqStatus: true, complianceDataStatus: null, complianceData: null, complianceDataMsg: '' }
        }

        case GET_COMPLIANCE_FINISH: {
            return { ...state, complianceDataReqStatus: false }
        }

        case GET_COMPLIANCE_SUCCESS: {
            return { ...state, complianceData: action.payload }
        }

        case VIEW_ADD_TASK_MODAL: {
            return { ...state, addTaskModal: action.payload }
        }

        case GSTIN_DETAILS: {
            return { ...state, GstinDetails: action.payload }
        }

        case ADD_COMMENTS_SUCCESS: {
            return { ...state, addCommentStatus: action.payload }
        }

        case GET_COMMENTS_LIST: {
            return { ...state, commentList: action.payload }
        }

        case CATEGORY_LIST_DATA: {
            return { ...state, categoryListData: action.payload }
        }

        case DELETE_TASK_DATA: {
            return { ...state, deleteTaskData: action.payload }
        }

        case UPDATE_TASK_DATA: {
            return {
                ...state, updateTaskData: action.payload
            }
        }

        case DELETE_ATTACHMENT_STATUS: {
            return { ...state, deleteAttachmentData: action.payload }
        }

        case UPDATE_TASK_MODAL: {
            return { ...state, updateTaskModal: action.payload }
        }

        case GET_USERS_INIT: {
            return { ...state, getUsersReqStatus: true, getUsersStatus: null, getUsersData: null }
        }
        case GET_USERS_FINISH: {
            return { ...state, getUsersReqStatus: false }
        }
        case GET_USERS_SUCCESS: {
            return { ...state, getUsersStatus: action.payload[0], getUsersData: action.payload[1] }
        }
        case GET_USERS_FAILED: {
            return { ...state, getUsersStatus: action.payload }
        }


        case ADD_USER_INIT: {
            return { ...state, addUserReqStatus: true, addUserStatus: null, addUserData: null, addUserMessage: '' }
        }
        case ADD_USER_FINISH: {
            return { ...state, addUserReqStatus: false }
        }
        case ADD_USER_SUCCESS: {
            return { ...state, addUserStatus: action.payload[0], addUserMessage: action.payload[1] }
        }
        case ADD_USER_FAILED: {
            return { ...state, addUserStatus: action.payload[0], addUserMessage: action.payload[1] }
        }

        case "RESET_ADD_USER_MESSAGE": {
            return { ...state, addUserMessage: action.payload }
        }

        case UPDATE_CLIENT_STATUS: {
            return { ...state, updateClient: action.payload }
        }

        case UPDATE_PRACTICE_INIT: {
            return { ...state, updatePracticeRegReqStatus: true }
        }

        case UPDATE_PRACTICE_FINISH: {
            return { ...state, updatePracticeRegReqStatus: false }
        }

        case UPDATE_PRACTICE_REG_STATUS: {
            return { ...state, updatePracticeReg: action.payload }
        }


        case UPDATE_TASK_STATUS: {
            return { ...state, updateTask: action.payload }
        }

        case DELETE_TASK_STATUS: {
            return { ...state, deleteTaskStatus: action.payload }
        }

        case DELETE_PRACTICE_INIT: {
            return { ...state, deletePracticeReqStatus: true }
        }
        
        case DELETE_PRACTICE_FINISH: {
            return { ...state, deletePracticeReqStatus: false }
        }

        case DELETE_PRACTICE_STATUS: {
            return { ...state, deletePracticeStatus: action.payload }
        }

        case DELETE_CLIENT_STATUS: {
            return { ...state, deleteClientStatus: action.payload }
        }

        case FIRM_CHANGE_UPDATE: {
            return { ...state, firmDetails: action.payload }
        }

        case GET_PRACTICE_REG_INIT: {
            return { ...state, getPracRegReqStatus: true, getPracRegStatus: null, getPracRegMessage: '', }
        }

        case GET_PRACTICE_REG_FINISH: {
            return { ...state, getPracRegReqStatus: false }
        }

        case GET_PRACTICE_REG_SUCCESS: {
            return { ...state, getPracRegData: action.payload[0], getPracRegMessage: action.payload[1] }
        }

        case GET_PRACTICE_REG_FAILED: {
            return { ...state, getPracRegStatus: action.payload[0], getPracRegMessage: action.payload[1], }
        }



        case GET_CLIENT_REG_INIT: {
            return { ...state, getClientRegReqStatus: true, getClientRegStatus: null, getClientRegMessage: '', clienRegData: null }
        }

        case GET_CLIENT_REG_FINISH: {
            return { ...state, getClientRegReqStatus: false }
        }

        case GET_CLIENT_REG_SUCCESS: {
            return { ...state, getClientRegData: action.payload[0], getClientRegMessage: action.payload[1] }
        }

        case GET_CLIENT_REG_FAILED: {
            return { ...state, getClientRegStatus: action.payload[0], getClientRegMessage: action.payload[1] }
        }

        case "UPDATE_CLIENT_REG_DATA": {
            return { ...state, getClientRegData: action.payload }
        }



        case GET_TASK_LIST_INIT: {
            return { ...state, getTaskListReqStatus: true, TaskListStatus: null, TaskListMessage: '', }
        }

        case GET_TASK_LIST_FINISH: {
            return { ...state, getTaskListReqStatus: false }
        }

        case UPDATE_TASK_LIST: {
            return { ...state, TaskListData: action.payload }
        }

        case GET_TASK_LIST_SUCCESS: {
            return { ...state, TaskListData: action.payload[0], TaskListMessage: action.payload[1] }
        }


        case GET_SCHEDULE_TASK_LIST_INIT: {
            return { ...state, scheduleTaskListReqStatus: true, scheduleTaskListStatus: null, scheduleTaskListData: null, scheduleTaskListMessage: '' }
        }

        case GET_SCHEDULE_TASK_LIST_FINISH: {
            return { ...state, scheduleTaskListReqStatus: false }
        }

        case GET_SCHEDULE_TASK_LIST_SUCCESS: {
            return { ...state, scheduleTaskListData: action.payload[0], scheduleTaskListMessage: action.payload[1] }
        }


        case GET_SCHEDULE_TASK_LIST_FAILED: {
            return { ...state, scheduleTaskListData: action.payload[0], scheduleTaskListMessage: action.payload[1] }
        }


        case GET_TASK_LIST_FAILED: {
            return { ...state, TaskListStatus: action.payload[0], TaskListData: action.payload[1], TaskListMessage: action.payload[2], }
        }


        case PM_REGISTRATION_INIT: {
            return { ...state, PMRegReqStatus: true, PMRegStatus: null, PMRegMessage: '' }
        }

        case PM_REGISTRATION_FINISH: {
            return { ...state, PMRegReqStatus: false }
        }

        case PM_REGISTRATION_SUCCESS: {
            return { ...state, PMRegStatus: action.payload[0], PMRegMessage: action.payload[1] }
        }

        case PM_REGISTRATION_FAILED: {
            return { ...state, PMRegStatus: action.payload[0], PMRegMessage: action.payload[1] }
        }


        case CLIENT_REGISTRATION_MESSAGE: {
            return { ...state, clientRegMessage: action.payload }
        }

        case CLIENT_REGISTRATION_INIT: {
            return { ...state, clientRegReqStatus: true, clientRegStatus: null, clientRegMessage: '' }
        }

        case CLIENT_REGISTRATION_FINISH: {
            return { ...state, clientRegReqStatus: false }
        }

        case CLIENT_REGISTRATION_SUCCESS: {
            return { ...state, clientRegStatus: action.payload[0], clientRegMessage: action.payload[1], clienRegData: action.payload[2] }
        }

        case CLIENT_REGISTRATION_FAILED: {
            return { ...state, clientRegStatus: action.payload[0], clientRegMessage: action.payload[1] }
        }

        case "UPDATE_CLIENT_REGISTRATION_STATUS": {
            return {...state, clientRegStatus: action.payload}
        }




        case TASK_ALLOCATION_INIT: {
            return { ...state, taskAllocationReqStatus: true, taskAllocationData: null, taskAllocationStatus: null, taskAllocationMessage: '' }
        }

        case TASK_ALLOCATION_FINISH: {
            return { ...state, taskAllocationReqStatus: false }
        }

        case TASK_ALLOCATION_SUCCESS: {
            return { ...state, taskAllocationStatus: action.payload[0], taskAllocationMessage: action.payload[1], taskAllocationData: action.payload[2] }
        }

        case TASK_ALLOCATION_FAILED: {
            return { ...state, taskAllocationStatus: action.payload[0], taskAllocationMessage: action.payload[1] }
        }

        case "RESET_TASK_ALLOCATION_MSG": {
            return {...state, taskAllocationMessage: ''}
        }

        case GET_COMPLETED_TASK_LIST: {
            return { ...state, completedTaskList: null }
        }
        case GET_COMPLETED_TASK_LIST_SUCCESS: {
            return { ...state, completedTaskList: action.payload }
        }
        case GET_PRACTICE_DATA_BY_ID: {
            return { ...state, practiceDataById: null }
        }
        case GET_PRACTICE_DATA_BY_ID_SUCCESS: {
            return { ...state, practiceDataById: action.payload }
        }
        case SELECTED_TASK_DATA: {
            return { ...state, selectedTaskData: action.payload }
        }
        case GET_BILLS_LIST: {
            return { ...state, billsList: null }
        }
        case GET_BILLS_LIST_SUCCESS: {
            return { ...state, billsList: action.payload }
        }
        case GET_BILL_DATA_BY_ID: {
            return { ...state, billDataById: null }
        }
        case GET_BILL_DATA_BY_ID_SUCCESS: {
            return { ...state, billDataById: action.payload }
        }
        case UPDATE_BILL_AMOUNT: {
            return { ...state, billUpdateStatus: null }
        }
        case UPDATE_BILL_AMOUNT_SUCCESS: {
            return { ...state, billUpdateStatus: action.payload }
        }

        default:
            return state;
    }
}
