import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Logofull from "src/assets/icons/logo-large.png";
import {
    CCard,
    CCardBody,
    CCol,
    CContainer,
    CRow
} from '@coreui/react'
import { useDispatch } from "react-redux";
import { emailVerify } from 'src/actions/registration';



import { useLocation } from "react-router-dom";

const VerificationFailed = () => {
    const location = useLocation();
    const dispatch = useDispatch()
    const [token,setToken] = useState('')
    useEffect(() => {
        const search = location?.search
        const token = search.split('=')[1]
        setToken(token)
     }, []);

     const resendVefication = () => {
         dispatch(emailVerify({"token":token}))
     }
    return (
        <>
            <div className="">

                <div className="c-app c-default-layout flex-row align-items-center mdb-shadow">
                    <CContainer>
                        <CRow className="justify-content-center">
                            <CCol xl="7" lg="8" md="12" sm="12">
                                <CCard className="border-0">
                                    <CCardBody className="p-0">
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                                <div className="p-2 verify-bg">
                                                    <img src={Logofull} className="c-sidebar-brand-full mobile-logo-width img-fluid" />{" "}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center p-5 inside-border-radius">
                                            <span className="verify-warning-icon-view">
                                                <i className="fas fa-exclamation" aria-hidden="true"></i>
                                            </span>
                                            <h5 className="bold-label mb-4 mt-4">Verification Failed</h5>
                                            <hr className="hr-light-border mt-3 mb-2 w-25"></hr>
                                            <div className="pl-2 pr-2">
                                                <h6 className="status-font mb-4 line-height-custom">Your email address was not verified sucsessfully. Check the link provided to your email address and attempt it again.</h6>
                                                <h6  className="bold-label mb-4">Or</h6>
                                                <button onClick={resendVefication} className="btn btn-primary btn-md btn-shadow">Resend Verification Link</button>
                                               
                                            </div>    
                                        </div>
                                       
                                    </CCardBody>
                                </CCard>
                                {/* <hr className="hr-light-border mt-5"></hr> */}
                                        <h6 className="text-center">Already Have an account? <Link to="/login" className="text-success bold-label">Login</Link></h6>  
                            </CCol>
                        </CRow>
                    </CContainer>
                </div>
            </div>
        </>
    )
}


export default VerificationFailed
