import {

} from "src/actions/actionTypes";
import {
    CREATE_INV_SETTINGS,
    CREATE_INV_SETTINGS_SUCCESS,
    FIND_HSN_CODE,
    FIND_HSN_CODE_SUCCESS,
    GET_ADDITIONAL_CHARGES,
    GET_ADDITIONAL_CHARGES_SUCCESS,
    GET_ADDITIONAL_GROUP_LIST,
    GET_ADDITIONAL_GROUP_LIST_SUCCESS,
    GET_CATEGORY_LIST,
    GET_CATEGORY_LIST_SUCCESS,
    GET_COMPANY_DATA,
    GET_COMPANY_DATA_SUCCESS,
    GET_GROUP_LIST,
    GET_GROUP_LIST_SUCCESS,
    GET_INV_COMPANY_DETAILS,
    GET_INV_COMPANY_DETAILS_SUCCESS,
    GET_INV_SETTINGS,
    GET_INV_SETTINGS_SUCCESS,
    GET_ITEM_LIST,
    GET_ITEM_LIST_SUCCESS,
    GET_PARTY_BY_ID,
    GET_PARTY_BY_ID_SUCCESS,
    GET_PARTY_LIST,
    GET_PARTY_LIST_SUCCESS,
    GET_STOCK_GROUP_LIST,
    GET_STOCK_GROUP_LIST_SUCCESS,
    GET_UNIT_LIST,
    GET_UNIT_LIST_SUCCESS,
    ITEM_CREATION_STATUS,
    ITEM_CREATION_STATUS_SUCCESS,
    PARTY_CREATION_STATUS,
    PARTY_CREATION_STATUS_SUCCESS,
    UPDATE_INV_COMPANY_DETAILS,
    UPDATE_INV_COMPANY_DETAILS_SUCCESS,
    UPLOAD_FILE,
    UPLOAD_FILE_SUCCESS,
    REMOVE_FILE,
    REMOVE_FILE_SUCCESS,
    GET_LEDGERS_FOR_SYNC_SETTINGS,
    GET_LEDGERS_FOR_SYNC_SETTINGS_SUCCESS,
    GET_GROUPS_FOR_SYNC_SETTINGS,
    GET_GROUPS_FOR_SYNC_SETTINGS_SUCCESS,
    GET_SYNC_SETTINGS_SUCCESS,
    GET_SYNC_SETTINGS,
    GET_GST_LEDGERS_FOR_SYNC_SETTINGS,
    GET_GST_LEDGERS_FOR_SYNC_SETTINGS_SUCCESS,
    SAVE_SYNC_SETTINGS,
    SAVE_SYNC_SETTINGS_SUCCESS,
    GET_QUICK_INVOICE_LIST,
    GET_QUICK_INVOICE_LIST_SUCCESS,
    SAVE_QUICK_INVOICE,
    SAVE_QUICK_INVOICE_SUCCESS,
    GET_QUICK_INVOICE_DATA,
    GET_QUICK_INVOICE_DATA_SUCCESS,
    MAP_CONFIRMED_VOUCHERS,
    MAP_CONFIRMED_VOUCHERS_SUCCESS,
    DELETE_TRANSACTION,
    DELETE_TRANSACTION_SUCCESS,
    TAB_ACTIVATION_ACTION,
    CONFIRM_PEN_LIST,
    CONFIRM_PEN_LIST_SUCCESS,
    VERIFY_UPI,
    VERIFY_UPI_SUCCESS,
    GENERATE_QR,
    GENERATE_QR_SUCCESS,
    GET_GODOWN_LIST_SUCCESS,
    GET_GODOWN_LIST,
    SAVE_UPI,
    SAVE_UPI_SUCCESS,
    GET_GST_LIST_WITH_RATES_SUCCESS,
    GET_GST_LIST_WITH_RATES,
    GET_SALE_LEDGERS_SUCCESS,
    GET_SALE_LEDGERS
} from "src/actions/saleInvoiceActionTypes";

const initialState = {
    itemCreationStatus: null,
    partyCreationStatus: null,
    partyList: null,
    groupList: null,
    partyData: null,
    invoiceSettings: null,
    itemList: null,
    categoryList: null,
    hsnList: null,
    createSettingStatus: null,
    stockGroupList: null,
    unitsList: null,
    additionalCharges: null,
    companyData: null,
    additionalGroupList: null,
    invCompanyDetails: null,
    invCompanyUpdateStatus: null,
    uploadFileStatus: null,
    removeFilestatus: null,
    syncSetLedgers: null,
    syncSetgroups: null,
    syncSettings: null,
    syncSetGSTLedgers: null,
    saveSyncSettingsStatus: null,
    saleInvoiceList: null,
    saveQuickInvStatus: null,
    quickInvoiceData: null,
    tnxMappingStatus: null,
    voucherDeleteStatus: null,
    activeTab: 0,
    confirmPendingList: null,
    upiVerifyStatus: null,
    generatedQRData: null,
    godownList: null,
    upiSaveStatus: null,
    gstListWithRates: null,
    saleLedgers: null
};

export default function saleInvoiceReducer(state = initialState, action) {
    switch (action.type) {
        case PARTY_CREATION_STATUS: {
            return { ...state, partyCreationStatus: null };
        }
        case PARTY_CREATION_STATUS_SUCCESS: {
            return { ...state, partyCreationStatus: action.payload };
        }
        case GET_PARTY_LIST: {
            return { ...state, partyList: null };
        }
        case GET_PARTY_LIST_SUCCESS: {
            return { ...state, partyList: action.payload };
        }
        case GET_PARTY_BY_ID: {
            return { ...state, partyData: null };
        }
        case GET_PARTY_BY_ID_SUCCESS: {
            return { ...state, partyData: action.payload };
        }
        case GET_GROUP_LIST: {
            return { ...state, groupList: null };
        }
        case GET_GROUP_LIST_SUCCESS: {
            return { ...state, groupList: action.payload };
        }
        case ITEM_CREATION_STATUS: {
            return { ...state, itemCreationStatus: null };
        }
        case ITEM_CREATION_STATUS_SUCCESS: {
            return { ...state, itemCreationStatus: action.payload };
        }
        case GET_INV_SETTINGS: {
            return { ...state, invoiceSettings: null };
        }
        case GET_INV_SETTINGS_SUCCESS: {
            return { ...state, invoiceSettings: action.payload };
        }
        case CREATE_INV_SETTINGS: {
            return { ...state, createSettingStatus: null };
        }
        case CREATE_INV_SETTINGS_SUCCESS: {
            return { ...state, createSettingStatus: action.payload };
        }
        // 
        case GET_ITEM_LIST: {
            return { ...state, itemList: null };
        }
        case GET_ITEM_LIST_SUCCESS: {
            return { ...state, itemList: action.payload };
        }
        case GET_CATEGORY_LIST: {
            return { ...state, categoryList: null };
        }
        case GET_CATEGORY_LIST_SUCCESS: {
            return { ...state, categoryList: action.payload };
        }
        case FIND_HSN_CODE: {
            return { ...state, hsnList: null };
        }
        case FIND_HSN_CODE_SUCCESS: {
            return { ...state, hsnList: action.payload };
        }
        case GET_STOCK_GROUP_LIST: {
            return { ...state, stockGroupList: null };
        }
        case GET_STOCK_GROUP_LIST_SUCCESS: {
            return { ...state, stockGroupList: action.payload };
        }
        case GET_UNIT_LIST: {
            return { ...state, unitsList: null };
        }
        case GET_UNIT_LIST_SUCCESS: {
            return { ...state, unitsList: action.payload };
        }
        case GET_ADDITIONAL_CHARGES: {
            return { ...state, additionalCharges: null };
        }
        case GET_ADDITIONAL_CHARGES_SUCCESS: {
            return { ...state, additionalCharges: action.payload };
        }
        case GET_COMPANY_DATA: {
            return { ...state, companyData: null };
        }
        case GET_COMPANY_DATA_SUCCESS: {
            return { ...state, companyData: action.payload };
        }
        case GET_ADDITIONAL_GROUP_LIST: {
            return { ...state, additionalGroupList: null };
        }
        case GET_ADDITIONAL_GROUP_LIST_SUCCESS: {
            return { ...state, additionalGroupList: action.payload };
        }
        case GET_INV_COMPANY_DETAILS: {
            return { ...state, invCompanyDetails: null };
        }
        case GET_INV_COMPANY_DETAILS_SUCCESS: {
            return { ...state, invCompanyDetails: action.payload };
        }
        case UPDATE_INV_COMPANY_DETAILS: {
            return { ...state, invCompanyUpdateStatus: null };
        }
        case UPDATE_INV_COMPANY_DETAILS_SUCCESS: {
            return { ...state, invCompanyUpdateStatus: action.payload };
        }
        case UPLOAD_FILE: {
            return { ...state, uploadFileStatus: null };
        }
        case UPLOAD_FILE_SUCCESS: {
            return { ...state, uploadFileStatus: action.payload };
        }
        case REMOVE_FILE: {
            return { ...state, removeFilestatus: null };
        }
        case REMOVE_FILE_SUCCESS: {
            return { ...state, removeFilestatus: action.payload };
        }
        case GET_LEDGERS_FOR_SYNC_SETTINGS: {
            return { ...state, syncSetLedgers: null };
        }
        case GET_LEDGERS_FOR_SYNC_SETTINGS_SUCCESS: {
            return { ...state, syncSetLedgers: action.payload };
        }
        case GET_GST_LEDGERS_FOR_SYNC_SETTINGS: {
            return { ...state, syncSetGSTLedgers: null };
        }
        case GET_GST_LEDGERS_FOR_SYNC_SETTINGS_SUCCESS: {
            return { ...state, syncSetGSTLedgers: action.payload };
        }
        case GET_GROUPS_FOR_SYNC_SETTINGS: {
            return { ...state, syncSetgroups: null };
        }
        case GET_GROUPS_FOR_SYNC_SETTINGS_SUCCESS: {
            return { ...state, syncSetgroups: action.payload };
        }
        case GET_SYNC_SETTINGS: {
            return { ...state, syncSettings: null };
        }
        case GET_SYNC_SETTINGS_SUCCESS: {
            return { ...state, syncSettings: action.payload };
        }
        case SAVE_SYNC_SETTINGS: {
            return { ...state, saveSyncSettingsStatus: null };
        }
        case SAVE_SYNC_SETTINGS_SUCCESS: {
            return { ...state, saveSyncSettingsStatus: action.payload };
        }
        case GET_QUICK_INVOICE_LIST: {
            return { ...state, saleInvoiceList: null };
        }
        case GET_QUICK_INVOICE_LIST_SUCCESS: {
            return { ...state, saleInvoiceList: action.payload };
        }
        case SAVE_QUICK_INVOICE: {
            return { ...state, saveQuickInvStatus: null };
        }
        case SAVE_QUICK_INVOICE_SUCCESS: {
            return { ...state, saveQuickInvStatus: action.payload };
        }
        case GET_QUICK_INVOICE_DATA: {
            return { ...state, quickInvoiceData: null };
        }
        case GET_QUICK_INVOICE_DATA_SUCCESS: {
            return { ...state, quickInvoiceData: action.payload };
        }
        case MAP_CONFIRMED_VOUCHERS: {
            return { ...state, tnxMappingStatus: null };
        }
        case MAP_CONFIRMED_VOUCHERS_SUCCESS: {
            return { ...state, tnxMappingStatus: action.payload };
        }
        case DELETE_TRANSACTION: {
            return { ...state, voucherDeleteStatus: null };
        }
        case DELETE_TRANSACTION_SUCCESS: {
            return { ...state, voucherDeleteStatus: action.payload };
        }
        case TAB_ACTIVATION_ACTION: {
            return { ...state, activeTab: action.payload };
        }
        case CONFIRM_PEN_LIST: {
            return { ...state, confirmPendingList: null };
        }
        case CONFIRM_PEN_LIST_SUCCESS: {
            return { ...state, confirmPendingList: action.payload };
        }
        case VERIFY_UPI: {
            return { ...state, upiVerifyStatus: null };
        }
        case VERIFY_UPI_SUCCESS: {
            return { ...state, upiVerifyStatus: action.payload };
        }
        case GET_GODOWN_LIST: {
            return { ...state, godownList: null };
        }
        case GET_GODOWN_LIST_SUCCESS: {
            return { ...state, godownList: action.payload };
        }
        case GENERATE_QR: {
            return { ...state, generatedQRData: null };
        }
        case GENERATE_QR_SUCCESS: {
            return { ...state, generatedQRData: action.payload };
        }
        case SAVE_UPI: {
            return { ...state, upiSaveStatus: null };
        }
        case SAVE_UPI_SUCCESS: {
            return { ...state, upiSaveStatus: action.payload };
        }
        case GET_GST_LIST_WITH_RATES: {
            return { ...state, gstListWithRates: null };
        }
        case GET_GST_LIST_WITH_RATES_SUCCESS: {
            return { ...state, gstListWithRates: action.payload };
        }
        case GET_SALE_LEDGERS: {
            return { ...state, saleLedgers: null };
        }
        case GET_SALE_LEDGERS_SUCCESS: {
            return { ...state, saleLedgers: action.payload };
        }
        default:
            return state;
    }
}

