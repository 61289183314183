import {
    CREATE_PAYMENT,
  CREATE_PAYMENT_DONE,
    CREATE_PAYMENT_INIT,
    CREATE_PAYMENT_SUCCESS
   } from "src/actions/actionTypes";
import { InsertFunctionStatus } from "src/util/enums";

   const initialState = {
     paymentSaveStatus: InsertFunctionStatus.NONE,
   };
   export default function paymentReducer(state = initialState, action) {
     switch (action.type) {
       case CREATE_PAYMENT: {
         return { ...state, paymentSaveStatus: InsertFunctionStatus.INPROGRESS };
       }
       case CREATE_PAYMENT_SUCCESS: {
        return { ...state, paymentSaveStatus: InsertFunctionStatus.SUCCESS };
      }
      case CREATE_PAYMENT_INIT: {
        return { ...state, paymentSaveStatus: InsertFunctionStatus.NONE };
      }
      case CREATE_PAYMENT_DONE: {
        return { ...state, paymentSaveStatus: InsertFunctionStatus.DONE };
      }
      
       default:
         return state;
     }
   }
   