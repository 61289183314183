
export const GET_INV_SETTINGS = "GET_INV_SETTINGS";
export const GET_INV_SETTINGS_SUCCESS = "GET_INV_SETTINGS_SUCCESS";
export const GET_INV_SETTINGS_FAILURE = "GET_INV_SETTINGS_FAILURE";

export const CREATE_INV_SETTINGS = "CREATE_INV_SETTINGS";
export const CREATE_INV_SETTINGS_SUCCESS = "CREATE_INV_SETTINGS_SUCCESS";
export const CREATE_INV_SETTINGS_FAILURE = "CREATE_INV_SETTINGS_FAILURE";

export const PARTY_CREATION_STATUS = "PARTY_CREATION_STATUS";
export const PARTY_CREATION_STATUS_SUCCESS = "PARTY_CREATION_STATUS_SUCCESS";
export const PARTY_CREATION_STATUS_FAILURE = "PARTY_CREATION_STATUS_FAILURE";

export const ITEM_CREATION_STATUS = "ITEM_CREATION_STATUS";
export const ITEM_CREATION_STATUS_SUCCESS = "ITEM_CREATION_STATUS_SUCCESS";
export const ITEM_CREATION_STATUS_FAILURE = "ITEM_CREATION_STATUS_FAILURE";

export const GET_ITEM_LIST = "GET_ITEM_LIST";
export const GET_ITEM_LIST_SUCCESS = "GET_ITEM_LIST_SUCCESS";
export const GET_ITEM_LIST_FAILURE = "GET_ITEM_LIST_FAILURE";

export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export const GET_CATEGORY_LIST_SUCCESS = "GET_CATEGORY_LIST_SUCCESS";
export const GET_CATEGORY_LIST_FAILURE = "GET_CATEGORY_LIST_FAILURE";

export const GET_GODOWN_LIST = "GET_GODOWN_LIST";
export const GET_GODOWN_LIST_SUCCESS = "GET_GODOWN_LIST_SUCCESS";
export const GET_GODOWN_LIST_FAILURE = "GET_GODOWN_LIST_FAILURE";

export const GET_STOCK_GROUP_LIST = "GET_STOCK_GROUP_LIST";
export const GET_STOCK_GROUP_LIST_SUCCESS = "GET_STOCK_GROUP_LIST_SUCCESS";
export const GET_STOCK_GROUP_LIST_FAILURE = "GET_STOCK_GROUP_LIST_FAILURE";

export const GET_UNIT_LIST = "GET_UNIT_LIST";
export const GET_UNIT_LIST_SUCCESS = "GET_UNIT_LIST_SUCCESS";
export const GET_UNIT_LIST_FAILURE = "GET_UNIT_LIST_FAILURE";

export const GET_PARTY_LIST = "GET_PARTY_LIST";
export const GET_PARTY_LIST_SUCCESS = "GET_PARTY_LIST_SUCCESS";
export const GET_PARTY_LIST_FAILURE = "GET_PARTY_LIST_FAILURE";

export const GET_PARTY_BY_ID = "GET_PARTY_BY_ID";
export const GET_PARTY_BY_ID_SUCCESS = "GET_PARTY_BY_ID_SUCCESS";
export const GET_PARTY_BY_ID_FAILURE = "GET_PARTY_BY_ID_FAILURE";

export const GET_GROUP_LIST = "GET_GROUP_LIST";
export const GET_GROUP_LIST_SUCCESS = "GET_GROUP_LIST_SUCCESS";
export const GET_GROUP_LIST_FAILURE = "GET_GROUP_LIST_FAILURE";

export const FIND_HSN_CODE = "FIND_HSN_CODE";
export const FIND_HSN_CODE_SUCCESS = "FIND_HSN_CODE_SUCCESS";
export const FIND_HSN_CODE_FAILURE = "FIND_HSN_CODE_FAILURE";

export const GET_ADDITIONAL_CHARGES = "GET_ADDITIONAL_CHARGES";
export const GET_ADDITIONAL_CHARGES_SUCCESS = "GET_ADDITIONAL_CHARGES_SUCCESS";
export const GET_ADDITIONAL_CHARGES_FAILURE = "GET_ADDITIONAL_CHARGES_FAILURE";

export const GET_COMPANY_DATA = "GET_COMPANY_DATA";
export const GET_COMPANY_DATA_SUCCESS = "GET_COMPANY_DATA_SUCCESS";
export const GET_COMPANY_DATA_FAILURE = "GET_COMPANY_DATA_FAILURE";

export const GET_ADDITIONAL_GROUP_LIST = "GET_ADDITIONAL_GROUP_LIST";
export const GET_ADDITIONAL_GROUP_LIST_SUCCESS = "GET_ADDITIONAL_GROUP_LIST_SUCCESS";
export const GET_ADDITIONAL_GROUP_LIST_FAILURE = "GET_ADDITIONAL_GROUP_LIST_FAILURE";

export const GET_INV_COMPANY_DETAILS = "GET_INV_COMPANY_DETAILS";
export const GET_INV_COMPANY_DETAILS_SUCCESS = "GET_INV_COMPANY_DETAILS_SUCCESS";
export const GET_INV_COMPANY_DETAILS_FAILURE = "GET_INV_COMPANY_DETAILS_FAILURE";

export const UPDATE_INV_COMPANY_DETAILS = "UPDATE_INV_COMPANY_DETAILS";
export const UPDATE_INV_COMPANY_DETAILS_SUCCESS = "UPDATE_INV_COMPANY_DETAILS_SUCCESS";
export const UPDATE_INV_COMPANY_DETAILS_FAILURE = "UPDATE_INV_COMPANY_DETAILS_FAILURE";

export const UPLOAD_FILE = "UPLOAD_FILE"
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS"
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE"

export const REMOVE_FILE = "REMOVE_FILE"
export const REMOVE_FILE_SUCCESS = "REMOVE_FILE_SUCCESS"
export const REMOVE_FILE_FAILURE = "REMOVE_FILE_FAILURE"

export const GET_LEDGERS_FOR_SYNC_SETTINGS = "GET_LEDGERS_FOR_SYNC_SETTINGS"
export const GET_LEDGERS_FOR_SYNC_SETTINGS_SUCCESS = "GET_LEDGERS_FOR_SYNC_SETTINGS_SUCCESS"
export const GET_LEDGERS_FOR_SYNC_SETTINGS_FAILURE = "GET_LEDGERS_FOR_SYNC_SETTINGS_FAILURE"

export const GET_GROUPS_FOR_SYNC_SETTINGS = "GET_GROUPS_FOR_SYNC_SETTINGS"
export const GET_GROUPS_FOR_SYNC_SETTINGS_SUCCESS = "GET_GROUPS_FOR_SYNC_SETTINGS_SUCCESS"
export const GET_GROUPS_FOR_SYNC_SETTINGS_FAILURE = "GET_GROUPS_FOR_SYNC_SETTINGS_FAILURE"

export const GET_SYNC_SETTINGS = "GET_SYNC_SETTINGS"
export const GET_SYNC_SETTINGS_SUCCESS = "GET_SYNC_SETTINGS_SUCCESS"
export const GET_SYNC_SETTINGS_FAILURE = "GET_SYNC_SETTINGS_FAILURE"

export const GET_GST_LEDGERS_FOR_SYNC_SETTINGS = "GET_GST_LEDGERS_FOR_SYNC_SETTINGS"
export const GET_GST_LEDGERS_FOR_SYNC_SETTINGS_SUCCESS = "GET_GST_LEDGERS_FOR_SYNC_SETTINGS_SUCCESS"
export const GET_GST_LEDGERS_FOR_SYNC_SETTINGS_FAILURE = "GET_GST_LEDGERS_FOR_SYNC_SETTINGS_FAILURE"

export const SAVE_SYNC_SETTINGS = "SAVE_SYNC_SETTINGS"
export const SAVE_SYNC_SETTINGS_SUCCESS = "SAVE_SYNC_SETTINGS_SUCCESS"
export const SAVE_SYNC_SETTINGS_FAILURE = "SAVE_SYNC_SETTINGS_FAILURE"

export const SAVE_QUICK_INVOICE = "SAVE_QUICK_INVOICE"
export const SAVE_QUICK_INVOICE_SUCCESS = "SAVE_QUICK_INVOICE_SUCCESS"
export const SAVE_QUICK_INVOICE_FAILURE = "SAVE_QUICK_INVOICE_FAILURE"

export const GET_QUICK_INVOICE_LIST = "GET_QUICK_INVOICE_LIST"
export const GET_QUICK_INVOICE_LIST_SUCCESS = "GET_QUICK_INVOICE_LIST_SUCCESS"
export const GET_QUICK_INVOICE_LIST_FAILURE = "GET_QUICK_INVOICE_LIST_FAILURE"

export const CONFIRM_PEN_LIST = "CONFIRM_PEN_LIST"
export const CONFIRM_PEN_LIST_SUCCESS = "CONFIRM_PEN_LIST_SUCCESS"
export const CONFIRM_PEN_LIST_FAILURE = "CONFIRM_PEN_LIST_FAILURE"

export const GET_QUICK_INVOICE_DATA = "GET_QUICK_INVOICE_DATA"
export const GET_QUICK_INVOICE_DATA_SUCCESS = "GET_QUICK_INVOICE_DATA_SUCCESS"
export const GET_QUICK_INVOICE_DATA_FAILURE = "GET_QUICK_INVOICE_DATA_FAILURE"

export const DELETE_TRANSACTION = "DELETE_TRANSACTION"
export const DELETE_TRANSACTION_SUCCESS = "DELETE_TRANSACTION_SUCCESS"
export const DELETE_TRANSACTION_FAILURE = "DELETE_TRANSACTION_FAILURE"

export const MAP_CONFIRMED_VOUCHERS = "MAP_CONFIRMED_VOUCHERS"
export const MAP_CONFIRMED_VOUCHERS_SUCCESS = "MAP_CONFIRMED_VOUCHERS_SUCCESS"
export const MAP_CONFIRMED_VOUCHERS_FAILURE = "MAP_CONFIRMED_VOUCHERS_FAILURE"

export const TAB_ACTIVATION_ACTION = "TAB_ACTIVATION_ACTION"

export const VERIFY_UPI = "VERIFY_UPI"
export const VERIFY_UPI_SUCCESS = "VERIFY_UPI_SUCCESS"
export const VERIFY_UPI_FAILURE = "VERIFY_UPI_FAILURE"

export const SAVE_UPI = "SAVE_UPI"
export const SAVE_UPI_SUCCESS = "SAVE_UPI_SUCCESS"
export const SAVE_UPI_FAILURE = "SAVE_UPI_FAILURE"

export const GENERATE_QR = "GENERATE_QR"
export const GENERATE_QR_SUCCESS = "GENERATE_QR_SUCCESS"
export const GENERATE_QR_FAILURE = "GENERATE_QR_FAILURE"

export const GET_GST_LIST_WITH_RATES = "GET_GST_LIST_WITH_RATES"
export const GET_GST_LIST_WITH_RATES_SUCCESS = "GET_GST_LIST_WITH_RATES_SUCCESS"
export const GET_GST_LIST_WITH_RATES_FAILURE = "GET_GST_LIST_WITH_RATES_FAILURE"

export const GET_SALE_LEDGERS = "GET_SALE_LEDGERS"
export const GET_SALE_LEDGERS_SUCCESS = "GET_SALE_LEDGERS_SUCCESS"
export const GET_SALE_LEDGERS_FAILURE = "GET_SALE_LEDGERS_FAILURE"