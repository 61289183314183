import {
   REMARKS_SAVE,
   REMARKS_SAVE_SUCCESS,
   REMARKS_SAVE_FAILURE,
   GET_REMARKS_SUCCESS,
   REMARKS_SAVE_DEFAULT
  } from "src/actions/actionTypes";
  import { InsertFunctionStatus } from "src/util/enums";
  
  const initialState = {
    remarksSaveStatus: InsertFunctionStatus.NONE,
    remarks:[]
  };
  
  export default function remarksReducer(state = initialState, action) {
    switch (action.type) {
      case REMARKS_SAVE: {
        return { ...state, remarksSaveStatus: InsertFunctionStatus.INPROGRESS };
      }
      case REMARKS_SAVE_SUCCESS: {
        return { ...state, remarksSaveStatus: InsertFunctionStatus.SUCCESS };
      }
      case REMARKS_SAVE_FAILURE: {
        return { ...state, remarksSaveStatus: InsertFunctionStatus.FAILED };
      }
      case GET_REMARKS_SUCCESS: {
        return { ...state, remarks: action.payload };
      }
      case REMARKS_SAVE_DEFAULT: {
        return { ...state, remarksSaveStatus: InsertFunctionStatus.NONE };
      }
      default:
        return state;
    }
  }
  