export const ReportSharingStatus = {
  NONE: 0,
  INPROGRESS: 1,
  SUCCESS: 2,
  FAILED: 3,
};

export const AttachmentUploadStatus = {
  NONE: 0,
  INPROGRESS: 1,
  SUCCESS: 2,
  FAILED: 3,
};

export const InsertFunctionStatus = {
  NONE: 0,
  INPROGRESS: 1,
  SUCCESS: 2,
  FAILED: 3,
  DONE:4
}

export const loginInprogressStatus = [
  {'status':"inprogress"}
]

export const getList = {
  inprogress:[{status:"inprogress"}]

}
