import {
  GET_TOP_EXPENSES,
  GET_TOP_EXPENSES_SUCCESS,
  GET_TOP_RECEIVABLES,
  GET_TOP_RECEIVABLES_SUCCESS,
  GET_PROFITABLE_SUMMARY,
  GET_PROFITABLE_SUMMARY_SUCCESS,
  GET_TOP_CUSTOMERS,
  GET_TOP_SUPPLIERS,
  GET_TOP_SUPPLIERS_SUCCESS,
  GET_TOP_CUSTOMERS_SUCCESS,
  GET_TOP_ASSETS,
  GET_TOP_ASSETS_SUCCESS,
  GET_TOP_LIABILITIES,
  GET_TOP_LIABILITIES_SUCCESS,
  FLAG_FOR_NAVIGATION_FROM_DASHBOARD,
  RESET_FLAG_FOR_NAVIGATION_FROM_DASHBOARD,
  GET_FISCAL_YEARS_SUCCESS,
  GET_SUBSCRIPTION_STATUS,
  GET_SEARCH_RESULTS,
  GET_SEARCH_RESULTS_SUCCESS,
  SET_SEARCH_KEY_WORD,
  GET_MONTH_WISE_EXPENSES_SUCCESS,
  GET_TOP_CAPITAL_AND_RESERVES_SUCCESS,
  GET_MONTH_WISE_EXPENSES,
  SAVE_LEDGER_TO_HIGHLIGHT_SUCCESS,
  SAVE_LEDGER_TO_HIGHLIGHT_DONE,
  GET_LEDGERS_FOR_HIGHLIGHT_SUCCESS,
  DELETE_HIGHLIGHT_LEDGERS_SUCCESS,
  DELETE_HIGHLIGHT_LEDGERS_DONE,
  GET_SEARCH_GROUP_RESULTS_SUCCESS,
  GET_SEARCH_LEDGER_RESULTS_SUCCESS,
  GET_SEARCH_STOCK_ITEM_RESULTS_SUCCESS,
  GET_SEARCH_STOCK_CATEGORY_RESULTS_SUCCESS,
  GET_SEARCH_STOCK_GROUP_RESULTS_SUCCESS,
  GET_SEARCH_GODOWN_RESULTS_SUCCESS,
  GET_SEARCH_NARRATION_RESULTS_SUCCESS,
  GET_SEARCH_LABEL_RESULTS_SUCCESS,
  GET_SELECTED_FISCAL_YEARS,
  GET_FISCAL_YEARS,
  GET_TOP_CAPITAL_AND_RESERVES,
  NAVIGATION_FLAG_SEARCH
} from "src/actions/actionTypes";
   import { getList, InsertFunctionStatus } from "src/util/enums";
   
   const initialState = {
     topExpenses: null,
     topRecivables: null,
     topAssets: null,
     topLiabilities: null,
     topCustomers: null,
     topSuppliers: null,
     profitableSummary: null,
     navigationFromDashboard: null,
     fiscalYears:[],
     selectedFinYear:null,
     subscriptionStatus: false,
     searchResultData:null,
     searchKeyWord:null,
     monthWiseExpence: null,
     topCapitalAndReservesData: null,
     saveToHighlights: InsertFunctionStatus.NONE,
     ledgersForHighlights:[],
     ledgerDeleteStatus: InsertFunctionStatus.NONE,
     groupList: null,
     ledgerList: null,
     stockCategoriesList: null,
     stockGroupList: null,
     stockItemList: null,
     vouchersList: null,
     labelList: null,
     stockGodownList: null,
     serchResultNavigation:null
   };
   
export default function highlightsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROFITABLE_SUMMARY: {
      return { ...state, profitableSummary: null };
    }
    case GET_PROFITABLE_SUMMARY_SUCCESS: {
      return { ...state, profitableSummary: action.payload };
    }
    case GET_TOP_EXPENSES: {
      return { ...state, topExpenses: null };
    }
    case GET_TOP_EXPENSES_SUCCESS: {
      return { ...state, topExpenses: action.payload };
    }
    case GET_TOP_RECEIVABLES: {
      return { ...state, topRecivables: null };
    }
    case GET_TOP_RECEIVABLES_SUCCESS: {
      return { ...state, topRecivables: action.payload };
    }
    case GET_TOP_ASSETS: {
      return { ...state, topAssets: null };
    }
    case GET_TOP_ASSETS_SUCCESS: {
      return { ...state, topAssets: action.payload };
    }
    case GET_TOP_LIABILITIES: {
      return { ...state, topLiabilities: null };
    }
    case GET_TOP_LIABILITIES_SUCCESS: {
      return { ...state, topLiabilities: action.payload };
    }
    case GET_TOP_CUSTOMERS: {
      return { ...state, topCustomers: null };
    }
    case GET_TOP_CUSTOMERS_SUCCESS: {
      return { ...state, topCustomers: action.payload };
    }
    case GET_TOP_SUPPLIERS: {
      return { ...state, topSuppliers: null };
    }
    case GET_TOP_SUPPLIERS_SUCCESS: {
      return { ...state, topSuppliers: action.payload };
    }
    case FLAG_FOR_NAVIGATION_FROM_DASHBOARD: {
      return { ...state, navigationFromDashboard: action.payload };
    }
    case RESET_FLAG_FOR_NAVIGATION_FROM_DASHBOARD: {
      return { ...state, navigationFromDashboard: null };
    }
    case GET_FISCAL_YEARS: {
      return { ...state, fiscalYears: null };
    }
    case GET_FISCAL_YEARS_SUCCESS: {
      return { ...state, fiscalYears: action.payload };
    }
    case GET_SELECTED_FISCAL_YEARS: {
      return { ...state, selectedFinYear: action.payload };
    }
    case GET_SUBSCRIPTION_STATUS: {
      return { ...state, subscriptionStatus: true };
    }
    case GET_SEARCH_RESULTS: {
      return { ...state, searchResultData: null };
    }
    case GET_SEARCH_RESULTS_SUCCESS: {
      return { ...state, searchResultData: action.payload,
        groupList:action.payload.groupList,
        ledgerList:action.payload.ledgerList,
        stockItemList:action.payload.stockItemList,
        stockCategoriesList:action.payload.stockCategoriesList,
        stockGroupList:action.payload.stockGroupList,
        stockGodownList:action.payload.stockGodownList,
        vouchersList:action.payload.vouchersList,
        labelList:action.payload.labelList, };
    }
    case SET_SEARCH_KEY_WORD: {
      return { ...state, searchKeyWord: action.payload };
    }
    case GET_MONTH_WISE_EXPENSES: {
      return { ...state, monthWiseExpence: getList.inprogress };
    }
    case GET_MONTH_WISE_EXPENSES_SUCCESS: {
      return { ...state, monthWiseExpence: action.payload };
    }
    case GET_TOP_CAPITAL_AND_RESERVES: {
      return { ...state, topCapitalAndReservesData: null };
    }
    case GET_TOP_CAPITAL_AND_RESERVES_SUCCESS: {
      return { ...state, topCapitalAndReservesData: action.payload };
    }
    case SAVE_LEDGER_TO_HIGHLIGHT_SUCCESS: {
      return { ...state, saveToHighlights: InsertFunctionStatus.SUCCESS };
    }
    case SAVE_LEDGER_TO_HIGHLIGHT_DONE: {
      return { ...state, saveToHighlights: InsertFunctionStatus.DONE };
    }
    case GET_LEDGERS_FOR_HIGHLIGHT_SUCCESS: {
      return { ...state, ledgersForHighlights: action.payload };
    }
    case DELETE_HIGHLIGHT_LEDGERS_SUCCESS: {
      return { ...state, ledgerDeleteStatus: InsertFunctionStatus.SUCCESS };
    }
    case DELETE_HIGHLIGHT_LEDGERS_DONE: {
      return { ...state, ledgerDeleteStatus: InsertFunctionStatus.DONE };
    }
    case GET_SEARCH_GROUP_RESULTS_SUCCESS: {
      return { ...state, groupList: action.payload.groupList,
        searchResultData:[] };
    }
    case GET_SEARCH_LEDGER_RESULTS_SUCCESS: {
      return { ...state, ledgerList: action.payload?.ledgerList,
        searchResultData:[] };
    }
    case GET_SEARCH_STOCK_ITEM_RESULTS_SUCCESS: {
      return { ...state, stockItemList: action.payload.stockItemList,
        searchResultData:[] };
    }
    case GET_SEARCH_STOCK_CATEGORY_RESULTS_SUCCESS: {
      return { ...state, stockCategoriesList: action.payload.stockCategoriesList,
        searchResultData:[] };
    }
    case GET_SEARCH_STOCK_GROUP_RESULTS_SUCCESS: {
      return { ...state, stockGroupList: action.payload.stockGroupList,
        searchResultData:[] };
    }
    case GET_SEARCH_GODOWN_RESULTS_SUCCESS: {
      return { ...state, stockGodownList: action.payload.stockGodownList,
        searchResultData:[] };
    }
    case GET_SEARCH_NARRATION_RESULTS_SUCCESS: {
      return { ...state, vouchersList: action.payload.vouchersList,
        searchResultData:[] };
    }
    case GET_SEARCH_LABEL_RESULTS_SUCCESS: {
      return { ...state, labelList: action.payload.labelList,
        searchResultData:[] };
    }
    case NAVIGATION_FLAG_SEARCH: {
      return { ...state, serchResultNavigation: action.payload };
    }
    default:
      return state;
  }
}
   