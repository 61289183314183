import {
  GET_EXPENSES,
  GET_EXPENSES_SUCCESS,
  GET_BILL_PAY_RECEIVABLES,
  GET_BILL_PAY_RECEIVABLES_SUCCESS,
  GET_BILLS_BY_LEDGER_ID_AND_REF_NO_SUCCESS,
  GET_TRAIL_BALANCE_DATA,
  GET_TRAIL_BALANCE_DATA_SUCCESS,
  GET_PARTY_WISE_BILLS_RECEIVABLES,
  GET_PARTY_WISE_BILLS_RECEIVABLES_SUCCESS,
  GET_BILLS_RECEIVABLES_BY_PARTY_SUCCESS,
  GET_BILLS_RECEIVABLES_BY_PARTY,
  GET_TOP_EXPENSE_LIST,
  GET_TOP_EXPENSE_LIST_SUCCESS,
  GET_EXPENSES_BETWEEN_DATES,
  GET_EXPENSES_BETWEEN_DATES_SUCCESS,
  GET_BILLS_BY_LEDGER_ID_AND_REF_NO
} from "src/actions/actionTypes";
import { getList } from "src/util/enums";

const initialState = {
  expenseList: [],
  billPayReceivalbleList:[],
  billListByLedgerIdAndRefNo:[],
  trailBalanceList:[],
  partyWiseBilsReceivables: [],
  bilsReceivablesByParty:[],
  topExpenses:[],
  expensesOfParticularMonth:[]
};
export default function expenseReduser(state = initialState, action) {
  switch (action.type) {
    case GET_EXPENSES: {
      return { ...state, expenseList: getList.inprogress };
    } case GET_EXPENSES_SUCCESS: {
      return { ...state, expenseList: action.payload };
    }case GET_BILL_PAY_RECEIVABLES: {
      return { ...state, billPayReceivalbleList: getList.inprogress };
    }case GET_BILL_PAY_RECEIVABLES_SUCCESS: {
      return { ...state, billPayReceivalbleList: action.payload };
    }case GET_BILLS_BY_LEDGER_ID_AND_REF_NO: {
      return { ...state, billListByLedgerIdAndRefNo: null };
    }case GET_BILLS_BY_LEDGER_ID_AND_REF_NO_SUCCESS: {
      return { ...state, billListByLedgerIdAndRefNo: action.payload };
    }case GET_TRAIL_BALANCE_DATA: {
      return { ...state, trailBalanceList: getList.inprogress };
    }case GET_TRAIL_BALANCE_DATA_SUCCESS: {
      return { ...state, trailBalanceList: action.payload };
    }
    case GET_PARTY_WISE_BILLS_RECEIVABLES: {
      return { ...state, partyWiseBilsReceivables: getList.inprogress };
    }
    case GET_PARTY_WISE_BILLS_RECEIVABLES_SUCCESS: {
      return { ...state, partyWiseBilsReceivables: action.payload };
    }
    case GET_BILLS_RECEIVABLES_BY_PARTY: {
      return { ...state, bilsReceivablesByParty: getList.inprogress };
    }
    case GET_BILLS_RECEIVABLES_BY_PARTY_SUCCESS: {
      return { ...state, bilsReceivablesByParty: action.payload };
    }
    case GET_TOP_EXPENSE_LIST: {
      return { ...state, topExpenses: getList.inprogress };
    }
    case GET_TOP_EXPENSE_LIST_SUCCESS: {
      return { ...state, topExpenses: action.payload };
    }
    case GET_EXPENSES_BETWEEN_DATES: {
      return { ...state, expensesOfParticularMonth: getList.inprogress };
    }
    case GET_EXPENSES_BETWEEN_DATES_SUCCESS: {
      return { ...state, expensesOfParticularMonth: action.payload };
    }
    default:
      return state;
  }
}
