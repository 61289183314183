import {
    SET_DATE_FILTER_DATA,
    RESET_DATE_FILTER_DATA
   } from "src/actions/actionTypes";

   
   const initialState = {
     filterData:{
       transType:null,
       fromDate: null,
       toDate: null,
       resetStatus: true
     },
     
   };
   export default function trnasactionTypeDateFilterReducer(state = initialState, action) {
     switch (action.type) {
       case SET_DATE_FILTER_DATA: {
         return { ...state, filterData: action.payload };
       }
       case RESET_DATE_FILTER_DATA: {
         return {
           ...state, filterData: action.payload};
       }
       default:
         return state;
     }
   }
   