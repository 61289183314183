import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Logofull from "src/assets/icons/logo-large.png";
import {
    CCard,
    CCardBody,
    CCol,
    CContainer,
    CInput,
    CInputGroup,
    CRow
} from '@coreui/react'
import { Formik } from "formik"
import * as yup from "yup"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { registerUnRegisteredUser } from 'src/actions/registration';



const UsernotRegistered = () => {
    const inviteeStatus = useSelector(state => state?.userReduser?.inviteeStatus)
    const jwtToken = useSelector(state => state?.userReduser?.jwtToken)
    const history = useHistory()
    const dispatch = useDispatch()
    const redirectToLoginPage = () => {
        history.push('/login')
    }
    const [mailContent, setmailContent] = useState("")
    useEffect(() => {
        if(inviteeStatus && Object.keys(inviteeStatus).length > 0){
            if(inviteeStatus?.response){
                setmailContent(inviteeStatus?.response)
            }
        }
    }, [inviteeStatus])

    const [initialValuesReg, setInitialValuesReg] = useState({
        firstName: "",
        lastName: "",
        password: "",
    });
    const validationSchema = yup.object().shape({
        firstName: yup
            .string()
            // .min(8)
            .required(),
        lastName: yup
            .string()
            // .min(8)
            .required(),
        password: yup
            .string()
            // .min()
            .required(),

    })
    const saveUseDetails =(values)=>{
        const data = {
            "token": jwtToken,
            "invitee_data": {
                "first_name": values.firstName,
                "last_name": values.lastName,
                "password": values.password
            }
        }
        dispatch(registerUnRegisteredUser(data))
    }
    const [visibilityPassWord, setVisibilityPassWord] = useState(false)
    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialValuesReg}
                validationSchema={validationSchema}
                onSubmit={((values, errors) => {
                    saveUseDetails(values)
                })}

            >
                {({ handleChange, handleSubmit, handleBlur, values, errors, touched, }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="">
                            <div className="c-app c-default-layout flex-row align-items-center mdb-shadow">
                                <CContainer>
                                    <CRow className="justify-content-center">
                                        <CCol xl="6" lg="6" md="12" sm="12">
                                            <CCard className="border-0">
                                                <CCardBody className="p-0">
                                                    <div className="row">
                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                                            <div className="p-2 verify-bg">
                                                                <img src={Logofull} className="c-sidebar-brand-full mobile-logo-width img-fluid" />{" "}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="text-center pt-5 pb-5 pl-5 pr-5 inside-border-radius">

                                                        <div className="pl-2 pr-2">
                                                            <h6 className="status-font mb-4 line-height-custom">{mailContent}</h6>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <CInputGroup className="mb-3 mt-1">
                                                                    <CInput
                                                                        type="text" className="shadow-sm rounded-pil border-r-b pl-2 form-control-lg border-0 custom-input"
                                                                        placeholder="First Name"
                                                                        name="firstName"
                                                                        value={values.firstName}
                                                                        onChange={handleChange}
                                                                    />
                                                                    <i className="fas fa-user inside-icon"></i>
                                                                </CInputGroup>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <CInputGroup className="mb-3 mt-1">
                                                                    <CInput
                                                                        type="text" className="shadow-sm rounded-pil border-r-b pl-2 form-control-lg border-0 custom-input"
                                                                        placeholder="Last Name"
                                                                        name="lastName"
                                                                        value={values.lastName}
                                                                        onChange={handleChange}
                                                                    />
                                                                    <i className="fas fa-user inside-icon"></i>
                                                                </CInputGroup>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <CInputGroup className="mb-3 mt-1">
                                                                    <CInput
                                                                        type={visibilityPassWord ? "text" : "password"} 
                                                                        className="shadow-sm rounded-pil border-r-b pl-2 form-control-lg border-0 custom-input"
                                                                        placeholder="Password"
                                                                        name="password"
                                                                        value={values.password}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {!values?.password && <i className="fas fa-key inside-icon"></i>}
                                                                    {!visibilityPassWord && values?.password && <i className="fa fa-eye inside-icon c-pointer" aria-hidden="true" onClick={e => setVisibilityPassWord(!visibilityPassWord)}></i>}
                                                                    {visibilityPassWord && values?.password && <i className="fa fa-eye-slash inside-icon c-pointer" aria-hidden="true" onClick={e => setVisibilityPassWord(!visibilityPassWord)}></i>}
                                                                </CInputGroup>
                                                            </div>
                                                            <div className="col-md-12 mt-2">
                                                                <button type="submit" className="btn btn-block btn-primary btn-lg bold-label btn-shadow" onClick={handleSubmit}>Join</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </CCardBody>
                                            </CCard>

                                            <h6 className="text-center">Already Have an Account? <Link to="/login" className="text-success bold-label">Login</Link></h6>
                                        </CCol>
                                    </CRow>
                                </CContainer>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
            
        </>
    )
}


export default UsernotRegistered
