import {
    GET_SUBSCRIPTION_INVOICE_LIST,
    GET_SUBSCRIPTION_INVOICE_LIST_SUCCESS,
    GET_SUBSCTIPTION_DATA, GET_SUBSCTIPTION_DATA_SUCCESS, GET_SUBSCTIPTION_VALIDITY, GET_SUBSCTIPTION_VALIDITY_SUCCESS
} from "src/actions/actionTypes";

const initialState = {
    subscriptionData: null,
    subscriptionInvList: null,
    subsriptionValidity: null
};

export default function subscriptionReducer(state = initialState, action) {
    switch (action.type) {
        case GET_SUBSCTIPTION_DATA: {
            return { ...state, subscriptionData: null };
        }
        case GET_SUBSCTIPTION_DATA_SUCCESS: {
            return { ...state, subscriptionData: action.payload };
        }
        case GET_SUBSCRIPTION_INVOICE_LIST: {
            return { ...state, subscriptionInvList: null };
        }
        case GET_SUBSCRIPTION_INVOICE_LIST_SUCCESS: {
            return { ...state, subscriptionInvList: action.payload };
        }
        case GET_SUBSCTIPTION_VALIDITY: {
            return { ...state, subsriptionValidity: null };
        }
        case GET_SUBSCTIPTION_VALIDITY_SUCCESS: {
            return { ...state, subsriptionValidity: action.payload };
        }
        default:
            return state;
    }
}

