import cookie from "react-cookies"
import { productionServiceUrl } from "src/util/serviceUrls";
const { default: Axios } = require("axios");
const tallyStaticRepAxios = Axios.create({
  baseURL: productionServiceUrl + "tallystaticreports/",
  headers: {
    Tenant: cookie.load("TENANT"),
  },
});

const tallyCrudRepAxios = Axios.create({
  baseURL: productionServiceUrl + "tallycrudapi/",
  headers: {
    Tenant: cookie.load("TENANT"),
  },
});
const tallyCompanyDataSyncApi = Axios.create({
  baseURL: productionServiceUrl + "tallycompanydatasyncapi/",
})
const tallyStockRepAxios = Axios.create({
  baseURL: productionServiceUrl + "tallystockapi/",

  headers: {
    Tenant: cookie.load("TENANT"),
  },
});

// For modified stock apis

const tallyInventoryRepAxios = Axios.create({
  baseURL: productionServiceUrl + "tallyreportsapi/",

  headers: {
    Tenant: cookie.load("TENANT"),
  },
});

const paymentsAPI = Axios.create({
  baseURL: productionServiceUrl + "finsightspaymentsapi",
});
tallyCrudRepAxios.interceptors.request.use(
  (req) => {
    if (cookie.load("TENANT")) {
      req.headers["Tenant"] = cookie.load("TENANT");
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

tallyStaticRepAxios.interceptors.request.use(
  (req) => {
    if (cookie.load("TENANT")) {
      req.headers["Tenant"] = cookie.load("TENANT");
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

tallyStockRepAxios.interceptors.request.use(
  (req) => {
    if (cookie.load("TENANT")) {
      req.headers["Tenant"] = cookie.load("TENANT");
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

tallyInventoryRepAxios.interceptors.request.use(
  (req) => {
    if (cookie.load("TENANT")) {
      req.headers["Tenant"] = cookie.load("TENANT");
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);


const tallyOrgCompaniesAxios = Axios.create({
  baseURL: productionServiceUrl + "tallyuserorgcompanies/",
  headers: {
    Tenant: cookie.load("TENANT"),
  },
});

const tallyDataSearchAxios = Axios.create({
  baseURL: productionServiceUrl + "tallydatasearch/",
  headers: {
    Tenant: cookie.load("TENANT"),
  },
});

tallyDataSearchAxios.interceptors.request.use(
  (req) => {
    if (cookie.load("TENANT")) {
      req.headers["Tenant"] = cookie.load("TENANT");
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);


tallyOrgCompaniesAxios.interceptors.request.use(
  (req) => {
    if (cookie.load("TENANT")) {
      req.headers["Tenant"] = cookie.load("TENANT");
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);


const tallyLedgersAxios = Axios.create({
  baseURL: productionServiceUrl + 'tallystaticreports/ledgerInfo/',
  headers: {
    Tenant: cookie.load("TENANT"),
  },
})

const tallyCompaniesListAxios = Axios.create({
  baseURL: productionServiceUrl,
})



const tallyGroupsAxios = Axios.create({
  baseURL: productionServiceUrl + 'tallystaticreports/',
  headers: {
    Tenant: cookie.load("TENANT"),
  },
})

tallyLedgersAxios.interceptors.request.use(
  (req) => {
    if (cookie.load("TENANT")) {
      req.headers["Tenant"] = cookie.load("TENANT");
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

tallyGroupsAxios.interceptors.request.use(
  (req) => {
    if (cookie.load("TENANT")) {
      req.headers["Tenant"] = cookie.load("TENANT");
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);
const productionUrlForLogin = Axios.create({
  baseURL: productionServiceUrl + "python/",
  // headers: {
  //   Tenant: cookie.load("TENANT"),
  // },
});


productionUrlForLogin.interceptors.request.use(
  (req) => {
    // if (cookie.load("TENANT")) {
    //   req.headers["Tenant"] = cookie.load("TENANT");
    // }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const pushNotificationAxios = Axios.create({
  baseURL: productionServiceUrl + 'tallypushnotificationsapi/',
});

const budgetAxios = Axios.create({
  baseURL: productionServiceUrl + 'python/api/',
});

const gstAxios = Axios.create({
  baseURL: productionServiceUrl + 'python/api/',
});


const ServiceAxios = Axios.create({
  baseURL: productionServiceUrl + 'finsightspracticemanagementapi/api/',
});

// const ServiceAxios = Axios.create({
//   baseURL: 'https://a92e-2409-4070-2ead-295a-608f-eb67-ca6a-bcac.ngrok.io' + '/api/',
// });


const productionUrl = Axios.create({
  baseURL: productionServiceUrl + "python/",
});

export {
  ServiceAxios,
  pushNotificationAxios,
  productionUrlForLogin,
  tallyGroupsAxios,
  tallyStaticRepAxios,
  tallyCrudRepAxios,
  tallyOrgCompaniesAxios,
  tallyStockRepAxios,
  tallyInventoryRepAxios,
  productionUrl,
  tallyLedgersAxios,
  tallyCompaniesListAxios,
  tallyDataSearchAxios,
  budgetAxios,
  gstAxios,
  tallyCompanyDataSyncApi,
  paymentsAPI,
};
