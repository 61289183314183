import {
    GET_EXPENCE_GROUP_CHART_DATA,
    GET_EXPENCE_GROUP_CHART_DATA_SUCCESS,
    GET_PROFIT_AND_LOSS_CHART_DATA,
    GET_PROFIT_AND_LOSS_CHART_DATA_SUCCESS,
} from "src/actions/actionTypes";
import { getList } from "src/util/enums";

const initialState = {
    expenceGroupChartData:{},
    profitAndLossChartData:{}
};

export default function chartReducer(state = initialState, action) {
    switch (action.type) {
        case GET_EXPENCE_GROUP_CHART_DATA : {
            return { ...state, expenceGroupChartData: getList.inprogress };
        }
        case GET_EXPENCE_GROUP_CHART_DATA_SUCCESS : {
            return { ...state, expenceGroupChartData: action.payload };

        }case GET_PROFIT_AND_LOSS_CHART_DATA : {
            return { ...state, profitAndLossChartData: getList.inprogress };
        }
        case GET_PROFIT_AND_LOSS_CHART_DATA_SUCCESS : {
            return { ...state, profitAndLossChartData: action.payload };
        }
        default:
            return state;
    }
}

