import {
    SET_GLOBAL_FILTER_VALUE
   } from "src/actions/actionTypes";

   
   const initialState = {
     globalFilterValue:"",
     
   };
   export default function globalFilterReducer(state = initialState, action) {
     switch (action.type) {
       case SET_GLOBAL_FILTER_VALUE: {
         return { ...state, globalFilterValue: action.payload };
       }
       default:
         return state;
     }
   }
   