import {
    GET_PAN_OR_GST_NO_SUCCESS,
    GET_USER_COMPANIES_SUCCESS
} from "src/actions/actionTypes";
import { getList, InsertFunctionStatus } from "src/util/enums";

const initialState = {
   panOrGst: "",
   userCompanies: [],
};

export default function miscReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PAN_OR_GST_NO_SUCCESS: {
            return { ...state, panOrGst: action.payload };
        }
        case GET_USER_COMPANIES_SUCCESS: {
            return { ...state, userCompanies: action.payload };
        }
        default:
            return state;
    }
}
