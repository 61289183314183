import {
    GET_SALES_BY_COMBINATION_SUCCESS   } from "src/actions/actionTypes";

   
   const initialState = {
     salesByCombination:[],
   };
   export default function salesReducer(state = initialState, action) {
     switch (action.type) {
       case GET_SALES_BY_COMBINATION_SUCCESS: {
         return { ...state, salesByCombination: action.payload };
       }
       default:
         return state;
     }
   }
   