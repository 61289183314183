export const FETCH_MATCHED_QUESTIONS = "FETCH_MATCHED_QUESTIONS";
export const FETCH_MATCHED_QUESTIONS_SUCCESS =
  "FETCH_MATCHED_QUESTIONS_SUCCESS";
export const FETCH_MATCHED_QUESTIONS_FAILURE =
  "FETCH_MATCHED_QUESTIONS_FAILURE";

export const QUESTION_SELECTED = "QUESTION_SELECTED";
export const QUESTION_RESET = "QUESTION_RESET";

export const FETCH_ALL_LEDGER_TRANSACTIONS = "FETCH_ALL_LEDGER_TRANSACTIONS";
export const FETCH_ALL_LEDGER_TRANSACTIONS_SUCCESS =
  "FETCH_ALL_LEDGER_TRANSACTIONS_SUCESS";
export const FETCH_ALL_LEDGER_TRANSACTIONS_FAILURE =
  "FETCH_ALL_LEDGER_TRANSACTIONS_FAILURE";

export const FETCH_AGAINST_LEDGER_GROUPING_DATA =
  "FETCH_AGAINST_LEDGER_GROUPING_DATA";
export const FETCH_AGAINST_LEDGER_GROUPING_DATA_SUCCESS =
  "FETCH_AGAINST_LEDGER_GROUPING_DATA_SUCCESS";
export const FETCH_AGAINST_LEDGER_GROUPING_DATA_FAILURE =
  "FETCH_AGAINST_LEDGER_GROUPING_DATA_FAILURE";

export const FETCH_AGAINST_LEDGER_GROUPING_BY_TRANSACTION_TYPE =
  "FETCH_AGAINST_LEDGER_GROUPING_BY_TRANSACTION_TYPE";
export const FETCH_AGAINST_LEDGER_GROUPING_BY_TRANSACTION_TYPE_SUCCESS =
  "FETCH_AGAINST_LEDGER_GROUPING_BY_TRANSACTION_TYPE_SUCCESS";
export const FETCH_AGAINST_LEDGER_GROUPING_BY_TRANSACTION_TYPE_FAILURE =
  "FETCH_AGAINST_LEDGER_GROUPING_BY_TRANSACTION_TYPE_FAILURE";

export const FETCH_AGAINST_LEDGER_GROUPING_BY_MONTH =
  "FETCH_AGAINST_LEDGER_GROUPING_BY_MONTH";
export const FETCH_AGAINST_LEDGER_GROUPING_BY_MONTH_SUCCESS =
  "FETCH_AGAINST_LEDGER_GROUPING_BY_MONTH_SUCCESS";
export const FETCH_AGAINST_LEDGER_GROUPING_BY_MONTH_FAILURE =
  "FETCH_AGAINST_LEDGER_GROUPING_BY_MONTH_FAILURE";

export const FETCH_AGAINST_LEDGER_GROUPING_BY_DATE =
  "FETCH_AGAINST_LEDGER_GROUPING_BY_DATE";
export const FETCH_AGAINST_LEDGER_GROUPING_BY_DATE_SUCCESS =
  "FETCH_AGAINST_LEDGER_GROUPING_BY_DATE_SUCCESS";
export const FETCH_AGAINST_LEDGER_GROUPING_BY_DATE_FAILURE =
  "FETCH_AGAINST_LEDGER_GROUPING_BY_DATE_FAILURE";

export const FETCH_ALL_LEDGER_TRANSACTIONS_BETWEEN_DATES =
  "FETCH_ALL_LEDGER_TRANSACTIONS_BETWEEN_DATES";
export const FETCH_ALL_LEDGER_TRANSACTIONS_BETWEEN_DATES_SUCCESS =
  "FETCH_ALL_LEDGER_TRANSACTIONS_BETWEEN_DATES_SUCCESS";
export const FETCH_ALL_LEDGER_TRANSACTIONS_BETWEEN_DATES_FAILURE =
  "FETCH_ALL_LEDGER_TRANSACTIONS_BETWEEN_DATES_FAILURE";

export const FETCH_ALL_LEDGER_TRANSACTIONS_BY_VOUCHER_ID =
  "FETCH_ALL_LEDGER_TRANSACTIONS_BY_VOUCHER_ID";
export const FETCH_ALL_LEDGER_TRANSACTIONS_BY_VOUCHER_ID_SUCCESS =
  "FETCH_ALL_LEDGER_TRANSACTIONS_BY_VOUCHER_ID_SUCCESS";
export const FETCH_ALL_LEDGER_TRANSACTIONS_BY_VOUCHER_ID_FAILURE =
  "FETCH_ALL_LEDGER_TRANSACTIONS_BY_VOUCHER_ID_FAILURE";

export const FETCH_ALL_LEDGER_TRANSACTIONS_BY_TRANSACTION_TYPE_AND_BETWEEN_DATES =
  "FETCH_ALL_LEDGER_TRANSACTIONS_BY_TRANSACTION_TYPE_AND_BETWEEN_DATES";
export const FETCH_ALL_LEDGER_TRANSACTIONS_BY_TRANSACTION_TYPE_AND_BETWEEN_DATES_SUCCESS =
  "FETCH_ALL_LEDGER_TRANSACTIONS_BY_TRANSACTION_TYPE_AND_BETWEEN_DATES_SUCCESS";
export const FETCH_ALL_LEDGER_TRANSACTIONS_BY_TRANSACTION_TYPE_AND_BETWEEN_DATES_FAILURE =
  "FETCH_ALL_LEDGER_TRANSACTIONS_BY_TRANSACTION_TYPE_AND_BETWEEN_DATES_FAILURE";

export const TOGGLE_LEDGER_TRANSACTION_AGGR_CHART =
  "TOGGLE_LEDGER_TRANSACTION_AGGR_CHART";

export const GET_BANK_LEDGER_CLOSING_BALANCES =
  "GET_BANK_LEDGER_CLOSING_BALANCES";
export const GET_BANK_LEDGER_CLOSING_BALANCES_SUCCESS =
  "GET_BANK_LEDGER_CLOSING_BALANCES_SUCCESS";
export const GET_BANK_LEDGER_CLOSING_BALANCES_FAILURE =
  "GET_BANK_LEDGER_CLOSING_BALANCES_FAILURE";

export const GET_BANK_LEDGER_CLOSING_BALANCES_LAST_30_DAYS =
  "GET_BANK_LEDGER_CLOSING_BALANCES_LAST_30_DAYS";
export const GET_BANK_LEDGER_CLOSING_BALANCES_LAST_30_DAYS_SUCCESS =
  "GET_BANK_LEDGER_CLOSING_BALANCES_LAST_30_DAYS_SUCCESS";
export const GET_BANK_LEDGER_CLOSING_BALANCES_LAST_30_DAYS_FAILURE =
  "GET_BANK_LEDGER_CLOSING_BALANCES_LAST_30_DAYS_FAILURE";

export const GET_BANK_LEDGERS_PAYMENT_RECEIPT_SUMMARY =
  "GET_BANK_LEDGERS_PAYMENT_RECEIPT_SUMMARY";
export const GET_BANK_LEDGERS_PAYMENT_RECEIPT_SUMMARY_SUCCESS =
  "GET_BANK_LEDGERS_PAYMENT_RECEIPT_SUMMARY_SUCCESS";
export const GET_BANK_LEDGERS_PAYMENT_RECEIPT_SUMMARY_FAILURE =
  "GET_BANK_LEDGERS_PAYMENT_RECEIPT_SUMMARY_FAILURE";

export const GET_GROUP_LEDGER_TRANSACTIONS = "GET_GROUP_LEDGER_TRANSACTIONS";
export const GET_GROUP_LEDGER_TRANSACTIONS_SUCCESS =
  "GET_GROUP_LEDGER_TRANSACTIONS_SUCCESS";
export const GET_GROUP_LEDGER_TRANSACTIONS_FAILURE =
  "GET_GROUP_LEDGER_TRANSACTIONS_FAILURE";

//#@ VOUCHER REGION STARTS

export const GET_ALL_VOUCHERS = "GET_ALL_VOUCHERS";
export const GET_ALL_VOUCHERS_SUCCESS = "GET_ALL_VOUCHERS_SUCCESS";
export const GET_ALL_VOUCHERS_FAILURE = "GET_ALL_VOUCHERS_FAILURE";
export const GET_ALL_VOUCHERS_REST = "GET_ALL_VOUCHERS_REST";


export const GET_VOUCHERS_BETWEEN_DATES = "GET_VOUCHERS_BETWEEN_DATES";
export const GET_VOUCHERS_BETWEEN_DATES_SUCCESS =
  "GET_VOUCHERS_BETWEEN_DATES_SUCCESS";
export const GET_VOUCHERS_BETWEEN_DATES_FAILURE =
  "GET_VOUCHERS_BETWEEN_DATES_FAILURE";

export const GET_SELECTED_VOUCHER_DETAILS = "GET_SELECTED_VOUCHER_DETAILS";
export const GET_SELECTED_VOUCHER_DETAILS_SUCCESS =
  "GET_SELECTED_VOUCHER_DETAILS_SUCCESS";
export const GET_SELECTED_VOUCHER_DETAILS_FAILURE =
  "GET_SELECTED_VOUCHER_DETAILS_FAILURE";

export const SAVE_VOUCHER_REMARKS = "SAVE_VOUCHER_REMARKS";
export const SAVE_VOUCHER_REMARKS_SUCCESS = "SAVE_VOUCHER_REMARKS_SUCCESS";
export const SAVE_VOUCHER_REMARKS_FAILURE = "SAVE_VOUCHER_REMARKS_FAILURE";
export const VOUCHER_REMARKS_DEFAULT = "VOUCHER_REMARKS_DEFAULT";


export const GET_VOUCHER_REMARKS_BY_ID = "GET_VOUCHER_REMARKS_BY_ID";
export const GET_VOUCHER_REMARKS_BY_ID_SUCCESS = "GET_VOUCHER_REMARKS_BY_ID";
export const GET_VOUCHER_REMARKS_BY_ID_FAILURE = "GET_VOUCHER_REMARKS_BY_ID_FAILURE";

export const GET_ALL_VOUCHERS_BY_USER = "GET_ALL_VOUCHERS_BY_USER";
export const GET_ALL_VOUCHERS_BY_USER_SUCCESS = "GET_ALL_VOUCHERS_BY_USER_SUCCESS";
export const GET_ALL_VOUCHERS_BY_USER_FAILURE = "GET_ALL_VOUCHERS_BY_USER_FAILURE";

//#@ VOUCHER REGION ENDS

export const GET_LEDGERS_LIST = "GET_LEDGERS_LIST";
export const GET_LEDGERS_LIST_SUCCESS = "GET_LEDGERS_LIST_SUCCESS";
export const GET_LEDGERS_LIST_FAILURE = "GET_LEDGERS_LIST_FAILURE";

export const GET_LEDGERS_LIST_REPORT = "GET_LEDGERS_LIST_REPORT";
export const GET_LEDGERS_LIST_REPORT_SUCCESS =
  "GET_LEDGERS_LIST_REPORT_SUCCESS";
export const GET_LEDGERS_LIST_REPORT_FAILURE =
  "GET_LEDGERS_LIST_REPORT_FAILURE";

export const GET_LEDGERS_BY_TYPES = "GET_LEDGERS_BY_TYPES";
export const GET_LEDGERS_BY_TYPES_SUCCESS = "GET_LEDGERS_BY_TYPES_SUCCESS";
export const GET_LEDGERS_BY_TYPES_FAILURE = "GET_LEDGERS_BY_TYPES_FAILURE";

export const GET_LEDGERS_BY_USERS = "GET_LEDGERS_BY_USERS";
export const GET_LEDGERS_BY_USERS_SUCCESS = "GET_LEDGERS_BY_USERS_SUCCESS";
export const GET_LEDGERS_BY_USERS_FAILURE = "GET_LEDGERS_BY_USERS_FAILURE";
export const GET_LEDGERS_BY_USERS_INPROGESS = "GET_LEDGERS_BY_USERS_INPROGESS";

export const GET_LEDGERS_BY_ID = "GET_LEDGERS_BY_ID";
export const GET_LEDGERS_BY_ID_SUCCESS = "GET_LEDGERS_BY_ID_SUCCESS";
export const GET_LEDGERS_BY_ID_FAILURE = "GET_LEDGERS_BY_ID_FAILURE";

export const GET_TRANSACTIONS_BY_LEDGER_ID = "GET_TRANSACTIONS_BY_LEDGER_ID";
export const GET_TRANSACTIONS_BY_LEDGER_ID_SUCCESS = "GET_TRANSACTIONS_BY_LEDGER_ID_SUCCESS";
export const GET_TRANSACTIONS_BY_LEDGER_ID_FAILURE = "GET_TRANSACTIONS_BY_LEDGER_ID_FAILURE";

export const GET_GROUPS_LIST = "GET_GROUPS_LIST";
export const GET_GROUPS_LIST_SUCCESS = "GET_GROUPS_LIST_SUCCESS";
export const GET_GROUPS_LIST_FAILURE = "GET_GROUPS_LIST_FAILURE";

export const GET_GROUPS_LIST_BY_USER = "GET_GROUPS_LIST_BY_USER";
export const GET_GROUPS_LIST_BY_USER_SUCCESS = "GET_GROUPS_LIST_BY_USER_SUCCESS";
export const GET_GROUPS_LIST_BY_USER_FAILURE = "GET_GROUPS_LIST_BY_USER_FAILURE";
export const GET_GROUPS_LIST_BY_USER_INPROGRESS = "GET_GROUPS_LIST_BY_USER_INPROGRESS";

export const GET_GROUP_LIST_WITH_CBAL = "GET_GROUP_LIST_WITH_CBAL";
export const GET_GROUP_LIST_WITH_CBAL_SUCCESS = "GET_GROUP_LIST_WITH_CBAL_SUCCESS";
export const GET_GROUP_LIST_WITH_CBAL_FAILURE = "GET_GROUP_LIST_WITH_CBAL_FAILURE";

export const GET_GROUP_DATA_BY_ID = "GET_GROUP_DATA_BY_ID";
export const GET_GROUP_DATA_BY_ID_SUCCESS = "GET_GROUP_DATA_BY_ID_SUCCESS";
export const GET_GROUP_DATA_BY_ID_FAILURE = "GET_GROUP_DATA_BY_ID_FAILURE";

export const TOP_RECEIVABLES_LIST = "TOP_RECEIVABLES_LIST";
export const TOP_RECEIVABLES_LIST_SUCCESS = "TOP_RECEIVABLES_LIST_SUCCESS";
export const TOP_RECEIVABLES_LIST_FAILURE = "TOP_RECEIVABLES_LIST_FAILURE";

export const TOP_PAYABLES_LIST = "TOP_PAYABLES_LIST";
export const TOP_PAYABLES_LIST_SUCCESS = "TOP_PAYABLES_LIST_SUCCESS";
export const TOP_PAYABLES_LIST_FAILURE = "TOP_PAYABLES_LIST_FAILURE";

export const GET_QUESTION_TRANSACTIONS_LIST = "GET_QUESTION_TRANSACTIONS_LIST";
export const GET_QUESTION_TRANSACTIONS_LIST_SUCCESS = "GET_QUESTION_TRANSACTIONS_LIST_SUCCESS"
export const GET_QUESTION_TRANSACTIONS_LIST_FAILURE = "GET_QUESTION_TRANSACTIONS_LIST_FAILURE"

//#region Report Sharing
export const SHARE_REPORT_INIT_STATE = "SHARE_REPORT_INIT_STATE";
export const SHARE_REPORT = "SHARE_REPORT";
export const SHARE_REPORT_SUCCESS = "SHARE_REPORT_SUCCESS";
export const SHARE_REPORT_FAILURE = "SHARE_REPORT_FAILURE";
//#endregion Report Sharing

//#region Attachments
export const ATT_INIT_STATUS = "ATT_INIT_STATUS";
export const ATTACHMENT_UPLOAD = "ATTACHMENT_UPLOAD";
export const ATTACHMENT_UPLOAD_SUCCESS = "ATTACHMENT_UPLOAD_SUCCESS";
export const ATTACHMENT_UPLOAD_FAILURE = "ATTACHMENT_UPLOAD_FAILURE";

export const GET_ATTACHMENT_LIST = "GET_ATTACHMENT_LIST";
export const GET_ATTACHMENT_LIST_SUCCESS = "GET_ATTACHMENT_LIST_SUCCESS";
export const GET_ATTACHMENT_LIST_FAILURE = "GET_ATTACHMENT_LIST_FAILURE";

export const ATTACHMENT_DELETE = "ATTACHMENT_DELETE";
export const ATTACHMENT_DELETE_SUCCESS = "ATTACHMENT_DELETE_SUCCESS";
export const ATTACHMENT_DELETE_FAILURE = "ATTACHMENT_DELETE_FAILURE";

export const GET_ATTACHMENT_BY_SEARCH = "GET_ATTACHMENT_BY_SEARCH";
export const GET_ATTACHMENT_BY_SEARCH_SUCCESS = "GET_ATTACHMENT_BY_SEARCH_SUCCESS";
export const GET_ATTACHMENT_BY_SEARCH_FAILURE = "GET_ATTACHMENT_BY_SEARCH_FAILURE";
////#endregion Attachments


//#region Tags
export const SAVE_TAGS = "SAVE_TAGS";
export const SAVE_TAGS_SUCCESS = "SAVE_TAGS_SUCCESS";
export const SAVE_TAGS_FAILURE = "SAVE_TAGS_FAILURE";


export const GET_TAGS = "GET_TAGS";
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS";
export const GET_TAGS_FAILURE = "GET_TAGS_FAILURE";
export const CHIPS_TO_EMPTY = "CHIPS_TO_EMPTY"
////#endregion Tags


export const GET_TRANSACTIONS_BY_AMOUNTS = "GET_TRANSACTIONS_BY_AMOUNTS";
export const GET_TRANSACTIONS_BY_AMOUNTS_SUCCESS = "GET_TRANSACTIONS_BY_AMOUNTS_SUCCESS";
export const GET_TRANSACTIONS_BY_AMOUNTS_FAILURE = "GET_TRANSACTIONS_BY_AMOUNTS_FAILURE";

export const GET_TRANSACTIONS_BY_COMBINATION = "GET_TRANSACTIONS_BY_COMBINATION";
export const GET_TRANSACTIONS_BY_COMBINATION_SUCCESS = "GET_TRANSACTIONS_BY_COMBINATION_SUCCESS";
export const GET_TRANSACTIONS_BY_COMBINATION_FAILURE = "GET_TRANSACTIONS_BY_COMBINATION_FAILURE";
export const SET_FILTERED_JSON = "SET_FILTERED_JSON"
export const SET_SELECTED_TRANSACTION_TYPES = "SET_SELECTED_TRANSACTION_TYPES"
export const RESET_FILTERED_JSON = "RESET_FILTERED_JSON"

export const GET_TRANSACTIONS_TYPES = "GET_TRANSACTIONS_TYPES";
export const GET_TRANSACTIONS_TYPES_SUCCESS = "GET_TRANSACTIONS_TYPES_SUCCESS";
export const GET_TRANSACTIONS_TYPES_FAILURE = "GET_TRANSACTIONS_TYPES_FAILURE";

export const REMARKS_SAVE = "REMARKS_SAVE";
export const REMARKS_SAVE_SUCCESS = "REMARKS_SAVE_SUCCESS";
export const REMARKS_SAVE_FAILURE = "REMARKS_SAVE_SUCCESS";
export const REMARKS_SAVE_DEFAULT = "REMARKS_SAVE_DEFAULT";

export const GET_REMARKS = "GET_REMARKS";
export const GET_REMARKS_SUCCESS = "GET_REMARKS_SUCCESS";
export const GET_REMARKS_FAILURE = "GET_REMARKS_FAILURE";


export const SAVE_QUERY = "SAVE_QUERY";
export const SAVE_QUERY_SUCCESS = "SAVE_QUERY_SUCCESS";
export const SAVE_QUERY_FAILURE = "SAVE_QUERY_FAILURE";


export const GET_QUERY_BY_TYPE = "GET_QUERY_BY_TYPE";
export const GET_QUERY_BY_TYPE_SUCCESS = "GET_QUERY_BY_TYPE_SUCCESS";
export const GET_QUERY_BY_TYPE_FAILURE = "GET_QUERY_BY_TYPE_FAILURE";
export const QUERY_SAVE_DEFAULT = "QUERY_SAVE_DEFAULT";


export const GET_QUERY_GROUP_LIST = "GET_QUERY_GROUP_LIST";
export const GET_QUERY_GROUP_LIST_SUCCESS = "GET_QUERY_GROUP_LIST_SUCCESS";
export const GET_QUERY_GROUP_LIST_FAILURE = "GET_QUERY_GROUP_LIST_FAILURE";

export const GET_QUERY_COUNT_INFO = "GET_QUERY_COUNT_INFO";
export const GET_QUERY_COUNT_INFO_SUCCESS = "GET_QUERY_COUNT_INFO_SUCCESS";
export const GET_QUERY_COUNT_INFO_FAILURE = "GET_QUERY_COUNT_INFO_FAILURE";

export const GET_QUERIES_BY_QUERY_GROUP = "GET_QUERIES_BY_QUERY_GROUP";
export const GET_QUERIES_BY_QUERY_GROUP_SUCCESS = "GET_QUERIES_BY_QUERY_GROUP_SUCCESS";
export const GET_QUERIES_BY_QUERY_GROUP_FAILURE = "GET_QUERIES_BY_QUERY_GROUP_FAILURE";

export const SAVE_MESSAGE = "SAVE_MESSAGE";
export const SAVE_MESSAGE_SUCCESS = "SAVE_MESSAGE_SUCCESS";
export const SAVE_MESSAGE_FAILURE = "SAVE_MESSAGE_FAILURE";
export const SAVE_MESSAGE_DEFAULT = "SAVE_MESSAGE_DEFAULT";

export const GET_QUERY_DATA = "GET_QUERY_DATA";
export const GET_QUERY_DATA_SUCCESS = "GET_QUERY_DATA_SUCCESS";
export const GET_QUERY_DATA_FAILURE = "GET_QUERY_DATA_FAILURE";


export const GET_MESSAGE = "GET_MESSAGE";
export const GET_MESSAGE_SUCCESS = "GET_MESSAGE_SUCCESS";
export const GET_MESSAGE_FAILURE = "GET_MESSAGE_FAILURE";

export const GET_REGISTERS_BY_TYPE_AND_DATES = "GET_REGISTERS_BY_TYPE_AND_DATES";
export const GET_REGISTERS_BY_TYPE_AND_DATES_SUCCESS = "GET_REGISTERS_BY_TYPE_AND_DATES_SUCCESS";
export const GET_REGISTERS_BY_TYPE_AND_DATES_FAILURE = "GET_REGISTERS_BY_TYPE_AND_DATES_FAILURE";

export const SAVE_USER = "SAVE_USER";
export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";
export const SAVE_USER_FAILURE = "SAVE_USER_FAILURE";
export const SAVE_USER_DEFAULT_STATUS = "SAVE_USER_DEFAULT_STATUS";

/*Organisation Region */

export const SAVE_ORGANISATION = "SAVE_ORGANISATION";
export const SAVE_ORGANISATION_SUCCESS = "SAVE_ORGANISATION_SUCCESS";
export const SAVE_ORGANISATION_FAILURE = "SAVE_ORGANISATION_FAILURE";
export const SAVE_ORGANISATION_DEFAULT_STATUS = "SAVE_ORGANISATION_DEFAULT_STATUS";

export const UPDATE_ORGANISATION = "UPDATE_ORGANISATION";
export const UPDATE_ORGANISATION_SUCCESS = "UPDATE_ORGANISATION_SUCCESS";
export const UPDATE_ORGANISATION_FAILURE = "UPDATE_ORGANISATION_FAILURE";
export const UPDATE_ORGANISATION_DEFAULT_STATUS = "UPDATE_ORGANISATION_DEFAULT_STATUS";

export const GET_ORGANISATION_BY_ORG_ID = "GET_ORGANISATION_BY_ORG_ID";
export const GET_ORGANISATION_BY_ORG_ID_SUCCESS = "GET_ORGANISATION_BY_ORG_ID_SUCCESS";
export const GET_ORGANISATION_BY_ORG_ID_FAILURE = "GET_ORGANISATION_BY_ORG_ID_FAILURE";
export const GET_ORGANISATION_BY_ORG_ID_DEFAULT_STATUS = "GET_ORGANISATION_BY_ORG_ID_DEFAULT_STATUS";

export const GET_ORGANISATION_BY_ORG_OWNER_ID = "GET_ORGANISATION_BY_ORG_OWNER_ID";
export const GET_ORGANISATION_BY_ORG_OWNER_ID_SUCCESS = "GET_ORGANISATION_BY_ORG_OWNER_ID_SUCCESS";
export const GET_ORGANISATION_BY_ORG_OWNER_ID_FAILURE = "GET_ORGANISATION_BY_ORG_OWNER_ID_FAILURE";
export const GET_ORGANISATION_BY_ORG_OWNER_ID_DEFAULT_STATUS = "GET_ORGANISATION_BY_ORG_OWNER_ID_DEFAULT_STATUS";

export const GET_EXPENSES = "GET_EXPENSES";
export const GET_EXPENSES_SUCCESS = "GET_EXPENSES_SUCCESS";
export const GET_EXPENSES_FAILURE = "GET_EXPENSES_FAILURE";

export const GET_EXPENSES_BY_AMOUNTS = "GET_EXPENSES_BY_AMOUNTS";
export const GET_EXPENSES_BY_AMOUNTS_SUCCESS = "GET_EXPENSES_BY_AMOUNTS_SUCCESS";
export const GET_EXPENSES_BY_AMOUNTS_FAILURE = "GET_EXPENSES_BY_AMOUNTS_FAILURE";


export const GET_EXPENSES_THIS_MONTH = "GET_EXPENSES_THIS_MONTH";
export const GET_EXPENSES_THIS_MONTH_SUCCESS = "GET_EXPENSES_THIS_MONTH_SUCCESS";
export const GET_EXPENSES_THIS_MONTH_FAILURE = "GET_EXPENSES_THIS_MONTH_FAILURE";


export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const CLEAR_AFER_LOGIN = "CLEAR_AFER_LOGIN";

export const LOGGEDIN_USERS_ROLE = "LOGGEDIN_USERS_ROLE";


export const SET_DATE_FILTER_DATA = "SET_DATE_FILTER_DATA"
export const RESET_DATE_FILTER_DATA = "RESET_DATE_FILTER_DATA"
export const SET_GLOBAL_FILTER_VALUE = "SET_GLOBAL_FILTER_VALUE"

export const GET_PAN_OR_GST_NO = "GET_PAN_OR_GST_NO"
export const GET_PAN_OR_GST_NO_SUCCESS = "GET_PAN_OR_GST_NO_SUCCESS"
export const GET_PAN_OR_GST_NO_FAILURE = "GET_PAN_OR_GST_NO_FAILURE"


export const GET_CASH_PAYMENTS = "GET_CASH_PAYMENTS"
export const GET_CASH_PAYMENTS_SUCCESS = "GET_CASH_PAYMENTS_SUCCESS"
export const GET_CASH_PAYMENTS_FAILURE = "GET_CASH_PAYMENTS_FAILURE"

export const GET_SALES_BY_COMBINATION = "GET_SALES_BY_COMBINATION"
export const GET_SALES_BY_COMBINATION_SUCCESS = "GET_SALES_BY_COMBINATION_SUCCESS"
export const GET_SALES_BY_COMBINATION_FAILURE = "GET_SALES_BY_COMBINATION_FAILURE"

export const GET_PURCHASE_INVOICE_LIST = "GET_PURCHASE_INVOICE_LIST"
export const GET_PURCHASE_INVOICE_LIST_SUCCESS = "GET_PURCHASE_INVOICE_LIST_SUCCESS"
export const GET_PURCHASE_INVOICE_LIST_FAILURE = "GET_PURCHASE_INVOICE_LIST_FAILURE"

export const GET_INVOICE_LIST_BY_TYPE = "GET_INVOICE_LIST_BY_TYPE"
export const GET_INVOICE_LIST_BY_TYPE_SUCCESS = "GET_INVOICE_LIST_BY_TYPE_SUCCESS"
export const GET_INVOICE_LIST_BY_TYPE_FAILURE = "GET_INVOICE_LIST_BY_TYPE_FAILURE"


//#region Tags

export const GET_PROFIT_AND_LOSS = "GET_PROFIT_AND_LOSS";
export const GET_PROFIT_AND_LOSS_SUCCESS = "GET_PROFIT_AND_LOSS_SUCCESS";
export const GET_PROFIT_AND_LOSS_FAILURE = "GET_PROFIT_AND_LOSS_FAILURE";

export const GET_PURCHASE_VALUE = "GET_PURCHASE_VALUE";
export const GET_PURCHASE_VALUE_SUCCESS = "GET_PURCHASE_VALUE_SUCCESS";
export const GET_PURCHASE_VALUE_FAILURE = "GET_PURCHASE_VALUE_FAILURE";

export const GET_SALES_VALUE = "GET_SALES_VALUE";
export const GET_SALES_VALUE_SUCCESS = "GET_SALES_VALUE_SUCCESS";
export const GET_SALES_VALUE_FAILURE = "GET_SALES_VALUE_FAILURE";

export const GET_FIXED_ASSET_VALUE = "GET_FIXED_ASSET_VALUE";
export const GET_FIXED_ASSET_VALUE_SUCCESS = "GET_FIXED_ASSET_VALUE_SUCCESS";
export const GET_FIXED_ASSET_VALUE_FAILURE = "GET_FIXED_ASSET_VALUE_FAILURE";

export const GET_CURRENT_ASSET_VALUE = "GET_CURRENT_ASSET_VALUE";
export const GET_CURRENT_ASSET_VALUE_SUCCESS = "GET_CURRENT_ASSET_VALUE_SUCCESS";
export const GET_CURRENT_ASSET_VALUE_FAILURE = "GET_CURRENT_ASSET_VALUE_FAILURE";

export const GET_CURRENT_LIABILITIES_VALUE = "GET_CURRENT_LIABILITIES_VALUE";
export const GET_CURRENT_LIABILITIES_VALUE_SUCCESS = "GET_CURRENT_LIABILITIES_VALUE_SUCCESS";
export const GET_CURRENT_LIABILITIES_VALUE_FAILURE = "GET_CURRENT_LIABILITIES_VALUE_FAILURE";

////#endregion Tags


//#inventory Region

export const GET_STOCK_ITEMS = "GET_STOCK_ITEMS";
export const GET_STOCK_ITEMS_SUCCESS = "GET_STOCK_ITEMS_SUCCESS";
export const GET_STOCK_ITEMS_FAILURE = "GET_STOCK_ITEMS_FAILURE";

export const GET_STOCK_GROUPS = "GET_STOCK_GROUPS";
export const GET_STOCK_GROUPS_SUCCESS = "GET_STOCK_GROUPS_SUCCESS";
export const GET_STOCK_GROUPS_FAILURE = "GET_STOCK_GROUPS_FAILURE";

export const GET_STOCK_VOUCHERS = "GET_STOCK_VOUCHERS";
export const GET_STOCK_VOUCHERS_SUCCESS = "GET_STOCK_VOUCHERS_SUCCESS";
export const GET_STOCK_VOUCHERS_FAILURE = "GET_STOCK_VOUCHERS_FAILURE";

export const GET_GODOWN = "GET_GODOWN";
export const GET_GODOWN_SUCCESS = "GET_GODOWN_SUCCESS";
export const GET_GODOWN_FAILURE = "GET_GODOWN_FAILURE";

export const GET_STOCK_ITEM_LIST_BY_TYPE_ID = "GET_STOCK_ITEM_LIST_BY_TYPE_ID";
export const GET_STOCK_ITEM_LIST_BY_TYPE_ID_SUCCESS = "GET_STOCK_ITEM_LIST_BY_TYPE_ID_SUCCESS";
export const GET_STOCK_ITEM_LIST_BY_TYPE_ID_FAILURE = "GET_STOCK_ITEM_LIST_BY_TYPE_ID_FAILURE";

//#inventory Region

export const CREATE_PAYMENT = "CREATE_PAYMENT";
export const CREATE_PAYMENT_SUCCESS = "CREATE_PAYMENT_SUCCESS";
export const CREATE_PAYMENT_FAILURE = "CREATE_PAYMENT_FAILURE";
export const CREATE_PAYMENT_INIT = "CREATE_PAYMENT_INIT";
export const CREATE_PAYMENT_DONE = "CREATE_PAYMENT_DONE";



export const GET_PAYMENT_MADE_FROM_LEDGER_LIST = "GET_PAYMENT_MADE_FROM_LEDGER_LIST";
export const GET_PAYMENT_MADE_FROM_LEDGER_LIST_SUCCESS = "GET_PAYMENT_MADE_FROM_LEDGER_LIST_SUCCESS";
export const GET_PAYMENT_MADE_FROM_LEDGER_LIST_FAILURE = "GET_PAYMENT_MADE_FROM_LEDGER_LIST_FAILURE";

export const GET_PAYMENT_MADE_TO_LEDGER_LIST = "GET_PAYMENT_MADE_TO_LEDGER_LIST";
export const GET_PAYMENT_MADE_TO_LEDGER_LIST_SUCCESS = "GET_PAYMENT_MADE_TO_LEDGER_LIST_SUCCESS";
export const GET_PAYMENT_MADE_TO_LEDGER_LIST_FAILURE = "GET_PAYMENT_MADE_TO_LEDGER_LIST_FAILURE";


export const GET_BILL_REF_NO = "GET_BILL_REF_NO";
export const GET_BILL_REF_NO_SUCCESS = "GET_BILL_REF_NO_SUCCESS";
export const GET_BILL_REF_NO_FAILURE = "GET_BILL_REF_NO_FAILURE";

export const GET_AMOUNT_BY_REF_NO = "GET_AMOUNT_BY_REF_NO";
export const GET_AMOUNT_BY_REF_NO_SUCCESS = "GET_AMOUNT_BY_REF_NO_SUCCESS";
export const GET_AMOUNT_BY_REF_NO_FAILURE = "GET_AMOUNT_BY_REF_NO_FAILURE";


export const GET_LEDGER_LIST_FOR_DOUBLE_ENTRY = "GET_LEDGER_LIST_FOR_DOUBLE_ENTRY";
export const GET_LEDGER_LIST_FOR_DOUBLE_ENTRY_SUCCESS = "GET_LEDGER_LIST_FOR_DOUBLE_ENTRY_SUCCESS";
export const GET_LEDGER_LIST_FOR_DOUBLE_ENTRY_FAILURE = "GET_LEDGER_LIST_FOR_DOUBLE_ENTRY_FAILURE";

export const GET_TRANSACTIONS_BY_ID_AND_BETWEEN_DATES = "GET_TRANSACTIONS_BY_ID_AND_BETWEEN_DATES";
export const GET_TRANSACTIONS_BY_ID_AND_BETWEEN_DATES_SUCCESS = "GET_TRANSACTIONS_BY_ID_AND_BETWEEN_DATES_SUCCESS";
export const GET_TRANSACTIONS_BY_ID_AND_BETWEEN_DATES_FAILURE = "GET_TRANSACTIONS_BY_ID_AND_BETWEEN_DATES_FAILURE";


export const CHEK_DUPLICATE_BILL_REF_NO = "CHEK_DUPLICATE_BILL_REF_NO";
export const CHEK_DUPLICATE_BILL_REF_NO_SUCCESS = "CHEK_DUPLICATE_BILL_REF_NO_SUCCESS";
export const CHEK_DUPLICATE_BILL_REF_NO_FAILURE = "CHEK_DUPLICATE_BILL_REF_NO_FAILURE";

export const GET_PURCHASE_GROUP_LIST = "GET_PURCHASE_GROUP_LIST";
export const GET_PURCHASE_GROUP_LIST_SUCCESS = "GET_PURCHASE_GROUP_LIST_SUCCESS";
export const GET_PURCHASE_GROUP_LIST_FAILURE = "GET_PURCHASE_GROUP_LIST_FAILURE";
export const GET_PURCHASE_GROUP_LIST_INIT = "GET_PURCHASE_GROUP_LIST_INIT";

export const CREATE_PURCHASE = "CREATE_PURCHASE";
export const CREATE_PURCHASE_SUCCESS = "CREATE_PURCHASE_SUCCESS";
export const CREATE_PURCHASE_FAILURE = "CREATE_PURCHASE_FAILURE";
export const CREATE_PURCHASE_INIT = "CREATE_PURCHASE_INIT";
export const CREATE_PURCHASE_DONE = "CREATE_PURCHASE_DONE";


export const CREATE_SALE = "CREATE_PURCHASE";
export const CREATE_SALE_SUCCESS = "CREATE_PURCHASE_SUCCESS";
export const CREATE_SALE_FAILURE = "CREATE_PURCHASE_FAILURE";


export const GET_PAYMENT_OR_RECIPT_DATA = "GET_PAYMENT_OR_RECIPT_DATA";
export const GET_PAYMENT_OR_RECIPT_DATA_SUCCESS = "GET_PAYMENT_OR_RECIPT_DATA_SUCCESS";
export const GET_PAYMENT_OR_RECIPT_DATA_FAILURE = "GET_PAYMENT_OR_RECIPT_DATA_FAILURE";

export const GET_PURCHASE_AS_VOUCHER_DATA = "GET_PURCHASE_AS_VOUCHER_DATA";
export const GET_PURCHASE_AS_VOUCHER_DATA_SUCCESS = "GET_PURCHASE_AS_VOUCHER_DATA_SUCCESS";
export const GET_PURCHASE_AS_VOUCHER_DATA_FAILURE = "GET_PURCHASE_AS_VOUCHER_DATA_FAILURE";

export const GET_JOURNAL_DATA = "GET_JOURNAL_DATA";
export const GET_JOURNAL_DATA_SUCCESS = "GET_JOURNAL_DATA_SUCCESS";
export const GET_JOURNAL_DATA_FAILURE = "GET_JOURNAL_DATA_FAILURE";

export const GET_CREATE_GROUP_DATA = "GET_CREATE_GROUP_DATA";
export const GET_CREATE_GROUP_DATA_SUCCESS = "GET_CREATE_GROUP_DATA_SUCCESS";
export const GET_CREATE_GROUP_DATA_ERROR = "GET_CREATE_GROUP_DATA_ERROR";

export const POST_CREATE_GROUP_DATA = "POST_CREATE_GROUP_DATA";
export const POST_CREATE_GROUP_DATA_SUCCESS = "POST_CREATE_GROUP_DATA_SUCCESS";
export const POST_CREATE_GROUP_DATA_ERROR = "POST_CREATE_GROUP_DATA_ERROR";
export const CLEAR_POST_GROUP_DATA_RESP = "CLEAR_POST_GROUP_DATA_RESP";


export const CLEAR_LOGIN_RESP_DATA = "CLEAR_LOGIN_RESP_DATA";

export const EMAIL_TOKEN = "EMAIL_TOKEN";
export const EMAIL_TOKEN_SUCCESS = "EMAIL_TOKEN_SUCCESS";
export const EMAIL_TOKEN_FAILURE = "EMAIL_TOKEN_FAILURE";

export const CLEAR_LOGIN_TOKEN = "CLEAR_LOGIN_TOKEN";

export const REQ_RESET_EMAIL = "REQ_RESET_EMAIL";
export const REQ_RESET_EMAIL_SUCCESS = "REQ_RESET_EMAIL_SUCCESS";
export const REQ_RESET_EMAIL_ERROR = "REQ_RESET_EMAIL_ERROR";

export const CONFIRM_PASSWORD = "CONFIRM_PASSWORD";
export const CONFIRM_PASSWORD_SUCCESS = "CONFIRM_PASSWORD_SUCCESS";
export const CONFIRM_PASSWORD_ERROR = "CONFIRM_PASSWORD_ERROR";

export const USER_REGISTER = "USER_REGISTER";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_ERROR = "USER_REGISTER_ERROR";

export const USER_EMAIl_EXISTS = "USER_EMAIl_EXISTS";
export const USER_EMAIl_EXISTS_SUCCESS = "USER_EMAIl_EXISTS_SUCCESS";
export const USER_EMAIl_EXISTS_ERROR = "USER_EMAIl_EXISTS_ERROR";

export const CLEAR_EMAIL_EXIST_RESP = "CLEAR_EMAIL_EXIST_RESP";

export const GET_SALES_LEDGER = "GET_SALES_LEDGER";
export const GET_SALES_LEDGER_SUCCESS = "GET_SALES_LEDGER_SUCCESS";
export const GET_SALES_LEDGER_ERROR = "GET_SALES_LEDGER_ERROR";

export const GET_LEDGER = "GET_LEDGER";
export const GET_LEDGER_SUCCESS = "GET_LEDGER_SUCCESS";
export const GET_LEDGER_ERROR = "GET_LEDGER_ERROR";

export const GET_ITEMS = "GET_ITEMS";
export const GET_ITEMS_SUCCESS = "GET_ITEMS_SUCCESS";
export const GET_ITEMS_ERROR = "GET_ITEMS_ERROR";

export const GET_PROFILES = "GET_PROFILES";
export const GET_PROFILES_SUCCESS = "GET_PROFILES_SUCCESS";
export const GET_PROFILES_ERROR = "GET_PROFILES_ERROR";

export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_ERROR = "GET_ROLES_ERROR";

export const ASSIGN_USERS = "ASSIGN_USERS";
export const ASSIGN_USERS_SUCCESS = "ASSIGN_USERS_SUCCESS";
export const ASSIGN_USERS_ERROR = "ASSIGN_USERS_ERROR";

export const UPDATE_ASSIGN_USERS = "UPDATE_ASSIGN_USERS";
export const UPDATE_ASSIGN_USERS_SUCCESS = "UPDATE_ASSIGN_USERS_SUCCESS";
export const UPDATE_ASSIGN_USERS_FAILURE = "UPDATE_ASSIGN_USERS_FAILURE";

export const DELETE_ASSIGN_USERS = "DELETE_ASSIGN_USERS";
export const DELETE_ASSIGN_USERS_SUCCESS = "DELETE_ASSIGN_USERS_SUCCESS";
export const DELETE_ASSIGN_USERS_FAILURE = "DELETE_ASSIGN_USERS_FAILURE";

export const USER_VERIFICATION = "USER_VERIFICATION";
export const USER_VERIFICATION_SUCCESS = "USER_VERIFICATION_SUCCESS";
export const USER_VERIFICATION_FAILURE = "USER_VERIFICATION_FAILURE";

export const RESEND_INVITATION_LINK = "RESEND_INVITATION_LINK";
export const RESEND_INVITATION_LINK_SUCCESS = "RESEND_INVITATION_LINK_SUCCESS";
export const RESEND_INVITATION_LINK_FAILURE = "RESEND_INVITATION_LINK_FAILURE";

export const GET_JWT_TOKEN = "GET_JWT_TOKEN";
export const GET_JWT_TOKEN_SUCCESS = "GET_JWT_TOKEN_SUCCESS";
export const GET_JWT_TOKEN_FAILURE = "GET_JWT_TOKEN_FAILURE";

export const REGISTER_INVITEE = "REGISTER_INVITEE";
export const REGISTER_INVITEE_SUCCESS = "REGISTER_INVITEE_SUCCESS";
export const REGISTER_INVITEE_FAILURE = "REGISTER_INVITEE_FAILURE";

export const GET_USER_COMPANIES = "GET_USER_COMPANIES";
export const GET_USER_COMPANIES_SUCCESS = "GET_USER_COMPANIES_SUCCESS";
export const GET_USER_COMPANIES_FAILURE = "GET_USER_COMPANIES_FAILURE";


export const GET_USER_COMPANIES_IN_LOGIN = "GET_USER_COMPANIES_IN_LOGIN";
export const GET_USER_COMPANIES_IN_LOGIN_SUCCESS = "GET_USER_COMPANIES_IN_LOGIN_SUCCESS";
export const GET_USER_COMPANIES_IN_LOGIN_FAILURE = "GET_USER_COMPANIES_IN_LOGIN_FAILURE";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"

export const STORE_TANSACTION_DATA = "STORE_TANSACTION_DATA"


export const GET_UNDERGROUP_LIST = "GET_UNDERGROUP_LIST";
export const GET_UNDERGROUP_LIST_SUCCESS = "GET_UNDERGROUP_LIST_SUCCESS";
export const GET_UNDERGROUP_LIST_FAILURE = "GET_UNDERGROUP_LIST_FAILURE";
export const CREATE_LEDGER = "CREATE_LEDGER"
export const CREATE_LEDGER_SUCCESS = "CREATE_LEDGER_SUCCESS"
export const CREATE_LEDGER_ERROR = "CREATE_LEDGER_ERROR";
export const CLEAR_CREATE_LEDGER_RESP = "CLEAR_CREATE_LEDGER_RESP";

export const GET_COMPANY_PROFILE_INFO = "GET_COMPANY_PROFILE_INFO"
export const GET_COMPANY_PROFILE_INFO_SUCCESS = "GET_COMPANY_PROFILE_INFO_SUCCESS";
export const GET_COMPANY_PROFILE_INFO_ERROR = "GET_COMPANY_PROFILE_INFO_ERROR";

export const GET_LEDGER_MAILING_DETAILS = "GET_LEDGER_MAILING_DETAILS"
export const GET_LEDGER_MAILING_DETAILS_SUCCESS = "GET_LEDGER_MAILING_DETAILS_SUCCESS";
export const GET_LEDGER_MAILING_DETAILS_FAILURE = "GET_LEDGER_MAILING_DETAILS_FAILURE";

export const GET_LEDGER_BANK_ACC_DETAILS = "GET_LEDGER_BANK_ACC_DETAILS"
export const GET_LEDGER_BANK_ACC_DETAILS_SUCCESS = "GET_LEDGER_BANK_ACC_DETAILS_SUCCESS";
export const GET_LEDGER_BANK_ACC_DETAILS_FAILURE = "GET_LEDGER_BANK_ACC_DETAILS_FAILURE";

export const GET_LEDGER_STATUTORY_DETAILS = "GET_LEDGER_STATUTORY_DETAILS"
export const GET_LEDGER_STATUTORY_DETAILS_SUCCESS = "GET_LEDGER_STATUTORY_DETAILS_SUCCESS";
export const GET_LEDGER_STATUTORY_DETAILS_FAILURE = "GET_LEDGER_STATUTORY_DETAILS_FAILURE";

export const GET_LEDGER_PARTY_DETAILS = "GET_LEDGER_PARTY_DETAILS"
export const GET_LEDGER_PARTY_DETAILS_SUCCESS = "GET_LEDGER_PARTY_DETAILS_SUCCESS";
export const GET_LEDGER_PARTY_DETAILS_FAILURE = "GET_LEDGER_PARTY_DETAILS_FAILURE";

export const GET_LEDGER_OTHER_DETAILS = "GET_LEDGER_OTHER_DETAILS"
export const GET_LEDGER_OTHER_DETAILS_SUCCESS = "GET_LEDGER_OTHER_DETAILS_SUCCESS";
export const GET_LEDGER_OTHER_DETAILS_FAILURE = "GET_LEDGER_OTHER_DETAILS_FAILURE";
// upate password from profile page
export const UPDATE_PASSWORD = "UPDATE_PASSWORD"
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";
// init user profile data
export const INIT_USER_PROFILE_DATA = "INIT_USER_PROFILE_DATA"
export const INIT_USER_PROFILE_DATA_SUCCESS = "INIT_USER_PROFILE_DATA_SUCCESS";
export const INIT_USER_PROFILE_DATA_ERROR = "INIT_USER_PROFILE_DATA_ERROR";
// update user profile data
export const UPDATE_USER_PROFILE_DATA = "UPDATE_USER_PROFILE_DATA"
export const UPDATE_USER_PROFILE_DATA_SUCCESS = "UPDATE_USER_PROFILE_DATA_SUCCESS";
export const UPDATE_USER_PROFILE_DATA_ERROR = "UPDATE_USER_PROFILE_DATA_ERROR";


export const GET_BILL_PAY_RECEIVABLES = "GET_BILL_PAY_RECEIVABLES";
export const GET_BILL_PAY_RECEIVABLES_SUCCESS = "GET_BILL_PAY_RECEIVABLES_SUCCESS";
export const GET_BILL_PAY_RECEIVABLES_FAILURE = "GET_BILL_PAY_RECEIVABLES_FAILURE";
export const GET_BILL_PAY_RECEIVABLES_INIT = "GET_BILL_PAY_RECEIVABLES_INIT";
export const GET_BILL_PAY_RECEIVABLES_DONE = "GET_BILL_PAY_RECEIVABLES_DONE";

export const GET_BILLS_BY_LEDGER_ID_AND_REF_NO = "GET_BILLS_BY_LEDGER_ID_AND_REF_NO";
export const GET_BILLS_BY_LEDGER_ID_AND_REF_NO_SUCCESS = "GET_BILLS_BY_LEDGER_ID_AND_REF_NO_SUCCESS";
export const GET_BILLS_BY_LEDGER_ID_AND_REF_NO_FAILURE = "GET_BILLS_BY_LEDGER_ID_AND_REF_NO_FAILURE";


export const GET_EXPENCE_GROUP_CHART_DATA = "GET_EXPENCE_GROUP_CHART_DATA";
export const GET_EXPENCE_GROUP_CHART_DATA_SUCCESS = "GET_EXPENCE_GROUP_CHART_DATA_SUCCESS";
export const GET_EXPENCE_GROUP_CHART_DATA_FAILURE = "GET_EXPENCE_GROUP_CHART_DATA_FAILURE";

export const GET_PROFIT_AND_LOSS_CHART_DATA = "GET_PROFIT_AND_LOSS_CHART_DATA";
export const GET_PROFIT_AND_LOSS_CHART_DATA_SUCCESS = "GET_PROFIT_AND_LOSS_CHART_DATA_SUCCESS";
export const GET_PROFIT_AND_LOSS_CHART_DATA_FAILURE = "GET_PROFIT_AND_LOSS_CHART_DATA_SUCCESS_FAILURE";

export const GET_INVITED_USERS = "GET_INVITED_USERS";
export const GET_INVITED_USERS_SUCCESS = "GET_INVITED_USERS_SUCCESS";
export const GET_INVITED_USERS_ERROR = "GET_INVITED_USERS_ERROR";

export const GET_MY_TALLY_COMPANIES = "GET_MY_TALLY_COMPANIES";
export const GET_MY_TALLY_COMPANIES_SUCCESS = "GET_MY_TALLY_COMPANIES_SUCCESS";
export const GET_MY_TALLY_COMPANIES_ERROR = "GET_MY_TALLY_COMPANIES_ERROR";

export const GET_MY_TALLY_COMPANIES_NOTIFI = "GET_MY_TALLY_COMPANIES_NOTIFI";
export const GET_MY_TALLY_COMPANIES_NOTIFI_SUCCESS = "GET_MY_TALLY_COMPANIES_NOTIFI_SUCCESS";
export const GET_MY_TALLY_COMPANIES_NOTIFI_FAILURE = "GET_MY_TALLY_COMPANIES_NOTIFI_FAILURE";
export const GET_MY_TALLY_COMPANIES_NOTIFI_DEFAULT = "GET_MY_TALLY_COMPANIES_NOTIFI_DEFAULT";

export const GET_ALL_GROUP_LIST = "GET_ALL_GROUP_LIST";
export const GET_ALL_GROUP_LIST_SUCCESS = "GET_ALL_GROUP_LIST_SUCCESS";
export const GET_ALL_GROUP_LIST_FAILURE = "GET_ALL_GROUP_LIST_FAILURE";

export const GET_ALL_USERS_BY_COMPANY_ID = "GET_ALL_USERS_BY_COMPANY_ID";
export const GET_ALL_USERS_BY_COMPANY_ID_SUCCESS = "GET_ALL_USERS_BY_COMPANY_ID_SUCCESS";
export const GET_ALL_USERS_BY_COMPANY_ID_FAILURE = "GET_ALL_USERS_BY_COMPANY_ID_FAILURE";

export const GET_QUERIES_BY_REFTYPE_AND_ID = "GET_QUERIES_BY_REFTYPE_AND_ID";
export const GET_QUERIES_BY_REFTYPE_AND_ID_SUCCESS = "GET_QUERIES_BY_REFTYPE_AND_ID_SUCCESS";
export const GET_QUERIES_BY_REFTYPE_AND_ID_FAILURE = "GET_QUERIES_BY_REFTYPE_AND_ID_FAILURE";

export const GET_TRAIL_BALANCE_DATA = "GET_TRAIL_BALANCE_DATA";
export const GET_TRAIL_BALANCE_DATA_SUCCESS = "GET_TRAIL_BALANCE_DATA_SUCCESS";
export const GET_TRAIL_BALANCE_DATA_FAILURE = "GET_TRAIL_BALANCE_DATA_FAILURE";

export const TRIGGER_QUERY_SAVE = "TRIGGER_QUERY_SAVE";
export const TRIGGER_QUERY_SAVE_DEFAULT = "TRIGGER_QUERY_SAVE_DEFAULT";


export const CHANGE_QUERY_STATUS = "CHANGE_QUERY_STATUS";
export const CHANGE_QUERY_STATUS_SUCCESS = "CHANGE_QUERY_STATUS_SUCCESS";
export const CHANGE_QUERY_STATUS_FAILURE = "CHANGE_QUERY_STATUS_FAILURE"

export const GET_QUERY_LIST_BY_COMBINATION = "GET_QUERY_LIST_BY_COMBINATION";
export const GET_QUERY_LIST_BY_COMBINATION_SUCCESS = "GET_QUERY_LIST_BY_COMBINATION_SUCCESS";
export const GET_QUERY_LIST_BY_COMBINATION_FAILURE = "GET_QUERY_LIST_BY_COMBINATION_FAILURE"

export const PUSH_NOTI_ADD_QUERY = "PUSH_NOTI_ADD_QUERY";
export const PUSH_NOTI_ADD_QUERY_SUCCESS = "PUSH_NOTI_ADD_QUERY_SUCCESS";
export const PUSH_NOTI_ADD_QUERY_FAILURE = "PUSH_NOTI_ADD_QUERY_FAILURE";

export const PUSH_NOTI_CHANGE_PRIORITY = "PUSH_NOTI_CHANGE_PRIORITY";
export const PUSH_NOTI_CHANGE_PRIORITY_SUCCESS = "PUSH_NOTI_CHANGE_PRIORITY_SUCCESS";
export const PUSH_NOTI_CHANGE_PRIORITY_FAILURE = "PUSH_NOTI_CHANGE_PRIORITY_FAILURE";

export const PUSH_NOTI_USER_COMMENTS = "PUSH_NOTI_USER_COMMENTS";
export const PUSH_NOTI_USER_COMMENTS_SUCCESS = "PUSH_NOTI_USER_COMMENTS_SUCCESS";
export const PUSH_NOTI_USER_COMMENTS_FAILURE = "PUSH_NOTI_USER_COMMENTS_FAILURE";

export const PUSH_NOTI_USER_JOINED = "PUSH_NOTI_USER_JOINED";
export const PUSH_NOTI_USER_JOINED_SUCCESS = "PUSH_NOTI_USER_JOINED_SUCCESS";
export const PUSH_NOTI_USER_JOINED_FAILURE = "PUSH_NOTI_USER_JOINED_FAILURE";

export const GET_STOCK_ITEM_SUMMARY_BY_ID = "GET_STOCK_ITEM_SUMMARY_BY_ID";
export const GET_STOCK_ITEM_SUMMARY_BY_ID_SUCCESS = "GET_STOCK_ITEM_SUMMARY_BY_ID_SUCCESS";
export const GET_STOCK_ITEM_SUMMARY_BY_ID_FAILURE = "GET_STOCK_ITEM_SUMMARY_BY_ID_FAILURE";

export const GET_STOCK_ITEM_INFO_BY_ID = "GET_STOCK_ITEM_INFO_BY_ID";
export const GET_STOCK_ITEM_INFO_BY_ID_SUCCESS = "GET_STOCK_ITEM_INFO_BY_ID_SUCCESS";
export const GET_STOCK_ITEM_INFO_BY_ID_FAILURE = "GET_STOCK_ITEM_INFO_BY_ID_FAILURE";

export const GET_STOCK_ITEM_PURCHASES_BY_ID = "GET_STOCK_ITEM_PURCHASES_BY_ID";
export const GET_STOCK_ITEM_PURCHASES_BY_ID_SUCCESS = "GET_STOCK_ITEM_PURCHASES_BY_ID_SUCCESS";
export const GET_STOCK_ITEM_PURCHASES_BY_ID_FAILURE = "GET_STOCK_ITEM_PURCHASES_BY_ID_FAILURE";

export const GET_STOCK_ITEM_SALES_BY_ID = "GET_STOCK_ITEM_SALES_BY_ID";
export const GET_STOCK_ITEM_SALES_BY_ID_SUCCESS = "GET_STOCK_ITEM_SALES_BY_ID_SUCCESS";
export const GET_STOCK_ITEM_SALES_BY_ID_FAILURE = "GET_STOCK_ITEM_SALES_BY_ID_FAILURE";


export const COMMON_SEARCH_COUNT = "COMMON_SEARCH_COUNT";
export const COMMON_SEARCH_COUNT_SUCCESS = "COMMON_SEARCH_COUNT_SUCCESS";
export const COMMON_SEARCH_COUNT_FAILURE = "COMMON_SEARCH_COUNT_FAILURE";


export const COMMON_SEARCH_TAGS = "COMMON_SEARCH_TAGS";
export const COMMON_SEARCH_TAGS_SUCCESS = "COMMON_SEARCH_TAGS_SUCCESS";
export const COMMON_SEARCH_TAGS_FAILURE = "COMMON_SEARCH_TAGS_FAILURE";


export const GET_MONTHLY_WISE_SALES_REGISTER = "GET_MONTHLY_WISE_SALES_REGISTER";
export const GET_MONTHLY_WISE_SALES_REGISTER_SUCCESS = "GET_MONTHLY_WISE_SALES_REGISTER_SUCCESS";
export const GET_MONTHLY_WISE_SALES_REGISTER_FAILURE = "GET_MONTHLY_WISE_SALES_REGISTER_FAILURE";

export const GET_PARTY_WISE_SALES_REGISTER = "GET_PARTY_WISE_SALES_REGISTER";
export const GET_PARTY_WISE_SALES_REGISTER_SUCCESS = "GET_PARTY_WISE_SALES_REGISTER_SUCCESS";
export const GET_PARTY_WISE_SALES_REGISTER_FAILURE = "GET_PARTY_WISE_SALES_REGISTER_FAILURE";

export const GET_LEDGER_WISE_SALES_REGISTER = "GET_LEDGER_WISE_SALES_REGISTER";
export const GET_LEDGER_WISE_SALES_REGISTER_SUCCESS = "GET_LEDGER_WISE_SALES_REGISTER_SUCCESS";
export const GET_LEDGER_WISE_SALES_REGISTER_FAILURE = "GET_LEDGER_WISE_SALES_REGISTER_FAILURE";

export const GET_ITEM_WISE_SALES_REGISTER = "GET_ITEM_WISE_SALES_REGISTER";
export const GET_ITEM_WISE_SALES_REGISTER_SUCCESS = "GET_ITEM_WISE_SALES_REGISTER_SUCCESS";
export const GET_ITEM_WISE_SALES_REGISTER_FAILURE = "GET_ITEM_WISE_SALES_REGISTER_FAILURE";

export const GET_ITEM_WISE_REGISTER_LIST = "GET_ITEM_WISE_REGISTER_LIST";
export const GET_ITEM_WISE_REGISTER_LIST_SUCCESS = "GET_ITEM_WISE_REGISTER_LIST_SUCCESS";
export const GET_ITEM_WISE_REGISTER_LIST_FAILURE = "GET_ITEM_WISE_REGISTER_LIST_FAILURE";

export const GET_REGISTERWISE_VOUCHER_LIST = "GET_REGISTERWISE_VOUCHER_LIST";
export const GET_REGISTERWISE_VOUCHER_LIST_SUCCESS = "GET_REGISTERWISE_VOUCHER_LIST_SUCCESS";
export const GET_REGISTERWISE_VOUCHER_LIST_FAILURE = "GET_REGISTERWISE_VOUCHER_LIST_FAILURE";

export const GET_CUSTOMERWISE_VOUCHER_LIST = "GET_CUSTOMERWISE_VOUCHER_LIST";
export const GET_CUSTOMERWISE_VOUCHER_LIST_SUCCESS = "GET_CUSTOMERWISE_VOUCHER_LIST_SUCCESS";
export const GET_CUSTOMERWISE_VOUCHER_LIST_FAILURE = "GET_CUSTOMERWISE_VOUCHER_LIST_FAILURE";

export const GET_REG_LIST_BY_CUST_ID = "GET_REG_LIST_BY_CUST_ID";
export const GET_REG_LIST_BY_CUST_ID_SUCCESS = "GET_REG_LIST_BY_CUST_ID_SUCCESS";
export const GET_REG_LIST_BY_CUST_ID_FAILURE = "GET_REG_LIST_BY_CUST_ID_FAILURE";

export const GET_SALES_REGISTER_BY_MONTH = "GET_SALES_REGISTER_BY_MONTH";
export const GET_SALES_REGISTER_BY_MONTH_SUCCESS = "GET_SALES_REGISTER_BY_MONTH_SUCCESS";
export const GET_SALES_REGISTER_BY_MONTH_FAILURE = "GET_SALES_REGISTER_BY_MONTH_FAILURE";

export const GET_SALES_REGISTER_BY_PARTY = "GET_SALES_REGISTER_BY_PARTY";
export const GET_SALES_REGISTER_BY_PARTY_SUCCESS = "GET_SALES_REGISTER_BY_PARTY_SUCCESS";
export const GET_SALES_REGISTER_BY_PARTY_FAILURE = "GET_SALES_REGISTER_BY_PARTY_FAILURE";

export const GET_SALES_REGISTER_BY_LEDGER = "GET_SALES_REGISTER_BY_LEDGER";
export const GET_SALES_REGISTER_BY_LEDGER_SUCCESS = "GET_SALES_REGISTER_BY_LEDGER_SUCCESS";
export const GET_SALES_REGISTER_BY_LEDGER_FAILURE = "GET_SALES_REGISTER_BY_LEDGER_FAILURE";

export const GET_SALES_REGISTER_BY_ITEM = "GET_SALES_REGISTER_BY_ITEM";
export const GET_SALES_REGISTER_BY_ITEM_SUCCESS = "GET_SALES_REGISTER_BY_ITEM_SUCCESS";
export const GET_SALES_REGISTER_BY_ITEM_FAILURE = "GET_SALES_REGISTER_BY_ITEM_FAILURE";

export const GET_PARTY_WISE_BILLS_RECEIVABLES = "GET_PARTY_WISE_BILLS_RECEIVABLES";
export const GET_PARTY_WISE_BILLS_RECEIVABLES_SUCCESS = "GET_PARTY_WISE_BILLS_RECEIVABLES_SUCCESS";
export const GET_PARTY_WISE_BILLS_RECEIVABLES_FAILURE = "GET_PARTY_WISE_BILLS_RECEIVABLES_FAILURE";

export const GET_BILLS_RECEIVABLES_BY_PARTY = "GET_BILLS_RECEIVABLES_BY_PARTY";
export const GET_BILLS_RECEIVABLES_BY_PARTY_SUCCESS = "GET_BILLS_RECEIVABLES_BY_PARTY_SUCCESS";
export const GET_BILLS_RECEIVABLES_BY_PARTY_FAILURE = "GET_BILLS_RECEIVABLES_BY_PARTY_FAILURE";

export const GET_BILLS_DETAILS_BY_LEDGER_ID = "GET_BILLS_DETAILS_BY_LEDGER_ID";
export const GET_BILLS_DETAILS_BY_LEDGER_ID_SUCCESS = "GET_BILLS_DETAILS_BY_LEDGER_ID_SUCCESS";
export const GET_BILLS_DETAILS_BY_LEDGER_ID_FAILURE = "GET_BILLS_DETAILS_BY_LEDGER_ID_FAILURE";


export const GET_BOOK_START_AND_END_DATE = "GET_BOOK_START_AND_END_DATE";
export const GET_BOOK_START_AND_END_DATE_SUCCESS = "GET_BOOK_START_AND_END_DATE_SUCCESS";
export const GET_BOOK_START_AND_END_DATE_FAILURE = "GET_BOOK_START_AND_END_DATE_FAILURE";


//
// ──────────────────────────────────────────────────────────── I ──────────
//   :::::: H I G H L I G H T S : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────
//

// Profitable Summary

export const GET_PROFITABLE_SUMMARY = "GET_PROFITABLE_SUMMARY";
export const GET_PROFITABLE_SUMMARY_SUCCESS = "GET_PROFITABLE_SUMMARY_SUCCESS";
export const GET_PROFITABLE_SUMMARY_FAILURE = "GET_PROFITABLE_SUMMARY_FAILURE";


// Top Expenses


export const GET_TOP_EXPENSES = "GET_TOP_EXPENSES";
export const GET_TOP_EXPENSES_SUCCESS = "GET_TOP_EXPENSES_SUCCESS";
export const GET_TOP_EXPENSES_FAILURE = "GET_TOP_EXPENSES_FAILURE";


export const GET_TOP_RECEIVABLES = "GET_TOP_RECEIVABLES";
export const GET_TOP_RECEIVABLES_SUCCESS = "GET_TOP_RECEIVABLES_SUCCESS";
export const GET_TOP_RECEIVABLES_FAILURE = "GET_TOP_RECEIVABLES_FAILURE";



export const GET_LOANS_AND_ADVANCES = "GET_LOANS_AND_ADVANCES";
export const GET_LOANS_AND_ADVANCES_SUCCESS = "GET_LOANS_AND_ADVANCES_SUCCESS";
export const GET_LOANS_AND_ADVANCES_FAILURE = "GET_LOANS_AND_ADVANCES_FAILURE";


export const GET_TOP_LIABILITIES = "GET_TOP_LIABILITIES";
export const GET_TOP_LIABILITIES_SUCCESS = "GET_TOP_LIABILITIES_SUCCESS";
export const GET_TOP_LIABILITIES_FAILURE = "GET_TOP_LIABILITIES_FAILURE";

export const GET_MONTH_WISE_EXPENSES = "GET_MONTH_WISE_EXPENSES";
export const GET_MONTH_WISE_EXPENSES_SUCCESS = "GET_MONTH_WISE_EXPENSES_SUCCESS";
export const GET_MONTH_WISE_EXPENSES_FAILURE = "GET_MONTH_WISE_EXPENSES_FAILURE";

export const GET_TOP_CAPITAL_AND_RESERVES = "GET_TOP_CAPITAL_AND_RESERVES";
export const GET_TOP_CAPITAL_AND_RESERVES_SUCCESS = "GET_TOP_CAPITAL_AND_RESERVES_SUCCESS";
export const GET_TOP_CAPITAL_AND_RESERVES_FAILURE = "GET_TOP_CAPITAL_AND_RESERVES_FAILURE";

export const GET_TOP_ASSETS = "GET_TOP_ASSETS";
export const GET_TOP_ASSETS_SUCCESS = "GET_TOP_ASSETS_SUCCESS";
export const GET_TOP_ASSETS_FAILURE = "GET_TOP_ASSETS_FAILURE";


export const GET_TOP_SUPPLIERS = "GET_TOP_SUPPLIERS";
export const GET_TOP_SUPPLIERS_SUCCESS = "GET_TOP_SUPPLIERS_SUCCESS";
export const GET_TOP_SUPPLIERS_FAILURE = "GET_TOP_SUPPLIERS_FAILURE";

export const GET_TOP_CUSTOMERS = "GET_TOP_CUSTOMERS";
export const GET_TOP_CUSTOMERS_SUCCESS = "GET_TOP_CUSTOMERS_SUCCESS";
export const GET_TOP_CUSTOMERS_FAILURE = "GET_TOP_CUSTOMERS_FAILURE";

export const GET_FISCAL_YEARS = "GET_FISCAL_YEARS";
export const GET_FISCAL_YEARS_SUCCESS = "GET_FISCAL_YEARS_SUCCESS";
export const GET_FISCAL_YEARS_FAILURE = "GET_FISCAL_YEARS_FAILURE";

export const GET_SELECTED_FISCAL_YEARS = "GET_SELECTED_FISCAL_YEARS";


export const FLAG_FOR_NAVIGATION_FROM_DASHBOARD = "FLAG_FOR_NAVIGATION_FROM_DASHBOARD";
export const RESET_FLAG_FOR_NAVIGATION_FROM_DASHBOARD = "RESET_FLAG_FOR_NAVIGATION_FROM_DASHBOARD";


//
// ──────────────────────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: A C T I O N   T Y P E S   F O T   I N V E N T O R Y : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────────────────────────
//

export const GET_ITEM_LIST_FOR_INVENTORY = "GET_ITEM_LIST_FOR_INVENTORY";
export const GET_ITEM_LIST_FOR_INVENTORY_SUCCESS = "GET_ITEM_LIST_FOR_INVENTORY_SUCCESS";
export const GET_ITEM_LIST_FOR_INVENTORY_FAILURE = "GET_ITEM_LIST_FOR_INVENTORY_FAILURE";

export const GET_HSN_LIST_FOR_INVENTORY = "GET_HSN_LIST_FOR_INVENTORY";
export const GET_HSN_LIST_FOR_INVENTORY_SUCCESS = "GET_HSN_LIST_FOR_INVENTORY_SUCCESS";
export const GET_HSN_LIST_FOR_INVENTORY_FAILURE = "GET_HSN_LIST_FOR_INVENTORY_FAILURE";

export const GET_UNITS_LIST_FOR_INVENTORY = "GET_UNITS_LIST_FOR_INVENTORY";
export const GET_UNITS_LIST_FOR_INVENTORY_SUCCESS = "GET_UNITS_LIST_FOR_INVENTORY_SUCCESS";
export const GET_UNITS_LIST_FOR_INVENTORY_FAILURE = "GET_UNITS_LIST_FOR_INVENTORY_FAILURE";



export const GET_GODOWN_LIST_FOR_INVENTORY = "GET_GODOWN_LIST_FOR_INVENTORY";
export const GET_GODOWN_LIST_FOR_INVENTORY_SUCCESS = "GET_GODOWN_LIST_FOR_INVENTORY_SUCCESS";
export const GET_GODOWN_LIST_FOR_INVENTORY_FAILURE = "GET_GODOWN_LIST_FOR_INVENTORY_FAILURE";


//
// ──────────────────────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: A C T I O N   T Y P E S   F O T   I N V E N T O R Y : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────────────────────────
//



export const GET_SUBSCRIPTION_STATUS = "GET_SUBSCRIPTION_STATUS";


export const SAVE_SOCIAL_AUTH_DATA = "SAVE_SOCIAL_AUTH_DATA";
export const SAVE_SOCIAL_AUTH_DATA_SUCCESS = "SAVE_SOCIAL_AUTH_DATA_SUCCESS";
export const SAVE_SOCIAL_AUTH_DATA_FAILURE = "SAVE_SOCIAL_AUTH_DATA_FAILURE";
export const RESET_SOCIAL_AUTH_DATA = "RESET_SOCIAL_AUTH_DATA";



export const SET_NOTIFICATION_CONTENT = "SET_NOTIFICATION_CONTENT";
export const RESET_NOTIFICATION_CONTENT = "RESET_NOTIFICATION_CONTENT";


export const GET_ALL_NATIFICATIONS_BY_ID = "GET_ALL_NATIFICATIONS_BY_ID";
export const GET_ALL_NATIFICATIONS_BY_ID_SUCCESS = "GET_ALL_NATIFICATIONS_BY_ID_SUCCESS";
export const GET_ALL_NATIFICATIONS_BY_ID_FAILURE = "GET_ALL_NATIFICATIONS_BY_ID_FAILURE";

export const NOTIFICATION_SUBSCRIPTION_DATA = "NOTIFICATION_SUBSCRIPTION_DATA";
export const SET_USER_LOGIN_STATUS = "SET_USER_LOGIN_STATUS";

export const TOGGLE_SUBSCRIPTION_DATA = "TOGGLE_SUBSCRIPTION_DATA";
export const TOGGLE_SUBSCRIPTION_DATA_SUCCESS = "TOGGLE_SUBSCRIPTION_DATA_SUCCESS";
export const TOGGLE_SUBSCRIPTION_DATA_FAILURE = "TOGGLE_SUBSCRIPTION_DATA_FAILURE";

export const GET_SEARCH_RESULTS = "GET_SEARCH_RESULTS";
export const GET_SEARCH_RESULTS_SUCCESS = "GET_SEARCH_RESULTS_SUCCESS";
export const GET_SEARCH_RESULTS_FAILURE = "GET_SEARCH_RESULTS_FAILURE";


export const GET_SEARCH_GROUP_RESULTS_SUCCESS = "GET_SEARCH_GROUP_RESULTS_SUCCESS";
export const GET_SEARCH_LEDGER_RESULTS_SUCCESS = "GET_SEARCH_LEDGER_RESULTS_SUCCESS";
export const GET_SEARCH_STOCK_ITEM_RESULTS_SUCCESS = "GET_SEARCH_STOCK_ITEM_RESULTS_SUCCESS";
export const GET_SEARCH_STOCK_CATEGORY_RESULTS_SUCCESS = "GET_SEARCH_STOCK_CATEGORY_RESULTS_SUCCESS";
export const GET_SEARCH_STOCK_GROUP_RESULTS_SUCCESS = "GET_SEARCH_STOCK_GROUP_RESULTS_SUCCESS";
export const GET_SEARCH_GODOWN_RESULTS_SUCCESS = "GET_SEARCH_GODOWN_RESULTS_SUCCESS";
export const GET_SEARCH_NARRATION_RESULTS_SUCCESS = "GET_SEARCH_NARRATION_RESULTS_SUCCESS";
export const GET_SEARCH_LABEL_RESULTS_SUCCESS = "GET_SEARCH_LABEL_RESULTS_SUCCESS";


export const SET_SEARCH_KEY_WORD = "SET_SEARCH_KEY_WORD";

export const GET_STOCK_GROUP_SUMMARY = "GET_SEARCH_RESULTS";
export const GET_STOCK_GROUP_SUMMARY_SUCCESS = "GET_SEARCH_SUMMARY_SUCCESS";
export const GET_STOCK_GROUP_SUMMARY_FAILURE = "GET_SEARCH_SUMMARY_FAILURE";

export const VERIFY_RESET_PASSWORD = "VERIFY_RESET_PASSWORD";
export const VERIFY_RESET_PASSWORD_SUCCESS = "VERIFY_RESET_PASSWORD_SUCCESS";
export const VERIFY_RESET_PASSWORD_FAILURE = "VERIFY_RESET_PASSWORD_FAILURE";

export const SUBMIT_RESET_PASSWORD_DATA = "SUBMIT_RESET_PASSWORD_DATA";
export const SUBMIT_RESET_PASSWORD_DATA_SUCCESS = "SUBMIT_RESET_PASSWORD_DATA_SUCCESS";
export const SUBMIT_RESET_PASSWORD_DATA_FAILURE = "SUBMIT_RESET_PASSWORD_DATA_FAILURE";


export const GET_MONTHLY_AGGREGATE_VALUES_BY_LEDGER_ID = "GET_MONTHLY_AGGREGATE_VALUES_BY_LEDGER_ID";
export const GET_MONTHLY_AGGREGATE_VALUES_BY_LEDGER_ID_SUCCESS = "GET_MONTHLY_AGGREGATE_VALUES_BY_LEDGER_ID_SUCCESS";
export const GET_MONTHLY_AGGREGATE_VALUES_BY_LEDGER_ID_FAILURE = "GET_MONTHLY_AGGREGATE_VALUES_BY_LEDGER_ID_FAILURE";

export const GET_MONTHLY_CLOSING_BALANCE_BY_ID = "GET_MONTHLY_CLOSING_BALANCE_BY_ID";
export const GET_MONTHLY_CLOSING_BALANCE_BY_ID_SUCCESS = "GET_MONTHLY_CLOSING_BALANCE_BY_ID_SUCCESS";
export const GET_MONTHLY_CLOSING_BALANCE_BY_ID_FAILURE = "GET_MONTHLY_CLOSING_BALANCE_BY_ID_FAILURE";

export const SET_ACTIVATION_PENDING_DATA = "SET_ACTIVATION_PENDING_DATA";
export const SET_ACTIVATION_PENDING_DATA_SUCCESS = "SET_ACTIVATION_PENDING_DATA_SUCCESS";
export const SET_ACTIVATION_PENDING_DATA_FAILURE = "SET_ACTIVATION_PENDING_DATA_FAILURE";

export const GET_TOP_EXPENSE_LIST = "GET_TOP_EXPENSE_LIST";
export const GET_TOP_EXPENSE_LIST_SUCCESS = "GET_TOP_EXPENSE_LIST_SUCCESS";
export const GET_TOP_EXPENSE_LIST_FAILURE = "GET_TOP_EXPENSE_LIST_FAILURE";

export const GET_EXPENSES_BETWEEN_DATES = "GET_EXPENSES_BETWEEN_DATES";
export const GET_EXPENSES_BETWEEN_DATES_SUCCESS = "GET_EXPENSES_BETWEEN_DATES_SUCCESS";
export const GET_EXPENSES_BETWEEN_DATES_FAILURE = "GET_EXPENSES_BETWEEN_DATES_FAILURE";

export const GET_NEW_NOTIFICATION_COUNT = "GET_NEW_NOTIFICATION_COUNT";
export const GET_NEW_NOTIFICATION_COUNT_SUCCESS = "GET_NEW_NOTIFICATION_COUNT_SUCCESS";
export const GET_NEW_NOTIFICATION_COUNT_FAILURE = "GET_NEW_NOTIFICATION_COUNT_FAILURE";


export const CHANGE_NOTIFICATION_STATUS = "CHANGE_NOTIFICATION_STATUS";
export const CHANGE_NOTIFICATION_STATUS_SUCCESS = "CHANGE_NOTIFICATION_STATUS_SUCCESS";
export const CHANGE_NOTIFICATION_STATUS_FAILURE = "CHANGE_NOTIFICATION_STATUS_FAILURE";

export const RESEND_VERIFICATION_LINK = "RESEND_VERIFICATION_LINK";
export const RESEND_VERIFICATION_LINK_SUCCESS = "RESEND_VERIFICATION_LINK_SUCCESS";
export const RESEND_VERIFICATION_LINK_FAILURE = "RESEND_VERIFICATION_LINK_FAILURE";

export const SAVE_LEDGER_TO_HIGHLIGHT = "SAVE_LEDGER_TO_HIGHLIGHT";
export const SAVE_LEDGER_TO_HIGHLIGHT_SUCCESS = "SAVE_LEDGER_TO_HIGHLIGHT_SUCCESS";
export const SAVE_LEDGER_TO_HIGHLIGHT_FAILURE = "SAVE_LEDGER_TO_HIGHLIGHT_FAILURE";
export const SAVE_LEDGER_TO_HIGHLIGHT_DONE = "SAVE_LEDGER_TO_HIGHLIGHT_DONE";

export const GET_LEDGERS_FOR_HIGHLIGHT = "GET_LEDGERS_FOR_HIGHLIGHT";
export const GET_LEDGERS_FOR_HIGHLIGHT_SUCCESS = "GET_LEDGERS_FOR_HIGHLIGHT_SUCCESS";
export const GET_LEDGERS_FOR_HIGHLIGHT_FAILURE = "GET_LEDGERS_FOR_HIGHLIGHT_FAILURE";

export const GET_HIGHLIGHT_LEDGERS_BY_TYPE = "GET_HIGHLIGHT_LEDGERS_BY_TYPE";
export const GET_HIGHLIGHT_LEDGERS_BY_TYPE_SUCCESS = "GET_HIGHLIGHT_LEDGERS_BY_TYPE_SUCCESS";
export const GET_HIGHLIGHT_LEDGERS_BY_TYPE_FAILURE = "GET_HIGHLIGHT_LEDGERS_BY_TYPE_FAILURE";

export const DELETE_HIGHLIGHT_LEDGERS = "GET_HIGHLIGHT_LEDGERS_BY_TYPE";
export const DELETE_HIGHLIGHT_LEDGERS_SUCCESS = "DELETE_HIGHLIGHT_LEDGERS_SUCCESS";
export const DELETE_HIGHLIGHT_LEDGERS_FAILURE = "DELETE_HIGHLIGHT_LEDGERS_FAILURE";
export const DELETE_HIGHLIGHT_LEDGERS_DONE = "DELETE_HIGHLIGHT_LEDGERS_DONE";

export const CHECK_BILL_WISE_CONDITION = "CHECK_BILL_WISE_CONDITION";
export const CHECK_BILL_WISE_CONDITION_SUCCESS = "CHECK_BILL_WISE_CONDITION_SUCCESS";
export const CHECK_BILL_WISE_CONDITION_FAILURE = "CHECK_BILL_WISE_CONDITION_FAILURE";


export const GET_ADD_DETAILS_BY_LEGER = "GET_ADD_DETAILS_BY_LEGER";
export const GET_ADD_DETAILS_BY_LEGER_SUCCESS = "GET_ADD_DETAILS_BY_LEGER_SUCCESS";
export const GET_ADD_DETAILS_BY_LEGER_FAILURE = "GET_ADD_DETAILS_BY_LEGER_FAILURE";

export const GET_ALL_TAGS = "GET_ALL_TAGS";
export const GET_ALL_TAGS_SUCCESS = "GET_ALL_TAGS_SUCCESS";
export const GET_ALL_TAGS_FAILURE = "GET_ALL_TAGS_FAILURE";


export const GET_TAGS_DATA_BY_TAG_NAME = "GET_TAGS_DATA_BY_TAG_NAME";
export const GET_TAGS_DATA_BY_TAG_NAME_SUCCESS = "GET_TAGS_DATA_BY_TAG_NAME_SUCCESS";
export const GET_TAGS_DATA_BY_TAG_NAME_FAILURE = "GET_TAGS_DATA_BY_TAG_NAME_FAILURE";

export const DOCUMENT_ERROR = "DOCUMENT_ERROR";

export const GET_STOCK_SUMMARY_BY_TYPE = "GET_STOCK_SUMMARY_BY_TYPE";
export const GET_STOCK_SUMMARY_BY_TYPE_SUCCESS = "GET_STOCK_SUMMARY_BY_TYPE_SUCCESS";
export const GET_STOCK_SUMMARY_BY_TYPE_FAILURE = "GET_STOCK_SUMMARY_BY_TYPE_FAILURE";

export const GET_STOCK_DATA_BY_TYPE = "GET_STOCK_DATA_BY_TYPE";
export const GET_STOCK_DATA_BY_TYPE_SUCCESS = "GET_STOCK_DATA_BY_TYPE_SUCCESS";
export const GET_STOCK_DATA_BY_TYPE_FAILURE = "GET_STOCK_DATA_BY_TYPE_FAILURE";

export const CHECK_INVENTORY_APPLICABLE = "CHECK_INVENTORY_APPLICABLE";
export const CHECK_INVENTORY_APPLICABLE_SUCCESS = "CHECK_INVENTORY_APPLICABLE_SUCCESS";
export const CHECK_INVENTORY_APPLICABLE_FAILURE = "CHECK_INVENTORY_APPLICABLE_FAILURE";

export const GET_MY_TALLY_COMPANIES_AFTER_SYNC = "GET_MY_TALLY_COMPANIES_AFTER_SYNC";
export const GET_MY_TALLY_COMPANIES_AFTER_SYNC_SUCCESS = "GET_MY_TALLY_COMPANIES_AFTER_SYNC_SUCCESS";
export const GET_MY_TALLY_COMPANIES_AFTER_SYNC_FAILURE = "GET_MY_TALLY_COMPANIES_AFTER_SYNC_FAILURE";
export const GET_MY_TALLY_COMPANIES_AFTER_SYNC_DEFAULT = "GET_MY_TALLY_COMPANIES_AFTER_SYNC_DEFAULT";

export const GET_AGGER_TRANSACTION_LIST = "GET_AGGER_TRANSACTION_LIST";
export const GET_AGGER_TRANSACTION_LIST_SUCCESS = "GET_AGGER_TRANSACTION_LIST_SUCCESS";
export const GET_AGGER_TRANSACTION_LIST_FAILURE = "GET_AGGER_TRANSACTION_LIST_FAILURE";

export const APPLE_SIGN_IN_FAILED = "APPLE_SIGN_IN_FAILED";
export const SAVE_USER_FEEDBACK = "SAVE_USER_FEEDBACK";
export const SAVE_USER_FEEDBACK_SUCCESS = "SAVE_USER_FEEDBACK_SUCCESS";
export const SAVE_USER_FEEDBACK_FAILURE = "SAVE_USER_FEEDBACK_FAILURE";
export const SAVE_USER_FEEDBACK_DEFAULT = "SAVE_USER_FEEDBACK_DEFAULT";

export const NAVIGATION_FLAG_SEARCH = "NAVIGATION_FLAG_SEARCH";


export const CHANGE_VOUCHER_MODE = "CHANGE_VOUCHER_MODE";
export const CHANGE_VOUCHER_MODE_SUCCESS = "CHANGE_VOUCHER_MODE_SUCCESS";
export const CHANGE_VOUCHER_MODE_FAILURE = "CHANGE_VOUCHER_MODE_FAILURE";
export const CHANGE_VOUCHER_MODE_DONE = "CHANGE_VOUCHER_MODE_DONE";

export const ADD_BUDGET_INIT = "ADD_BUDGET_INIT";
export const ADD_BUDGET_FINISH = "ADD_BUDGET_FINISH";
export const ADD_BUDGET_SUCCESS = "ADD_BUDGET_SUCCESS";
export const ADD_BUDGET_FAILED = "ADD_BUDGET_FAILED";

export const GET_BUDGET_INIT = "GET_BUDGET_INIT";
export const GET_BUDGET_FINISH = "GET_BUDGET_FINISH";
export const GET_BUDGET_SUCCESS = "GET_BUDGET_SUCCESS";
export const GET_BUDGET_FAILED = "GET_BUDGET_FAILED";

export const EDIT_BUDGET_INIT = "EDIT_BUDGET_INIT";
export const EDIT_BUDGET_FINISH = "EDIT_BUDGET_FINISH";
export const EDIT_BUDGET_SUCCESS = "EDIT_BUDGET_SUCCESS";
export const EDIT_BUDGET_FAILED = "EDIT_BUDGET_FAILED";

export const ADD_COMPANY_LOGO = "ADD_COMPANY_LOGO";
export const ADD_COMPANY_LOGO_SUCCESS = "ADD_COMPANY_LOGO_SUCCESS";
export const ADD_COMPANY_LOGO_FAILURE = "ADD_COMPANY_LOGO_FAILURE";
export const ADD_COMPANY_LOGO_DONE = "ADD_COMPANY_LOGO_DONE";

export const GET_MONTH_PARTY_WISE_SALES_REGISTER = "GET_MONTH_PARTY_WISE_SALES_REGISTER";
export const GET_MONTH_PARTY_WISE_SALES_REGISTER_SUCCESS = "GET_MONTH_PARTY_WISE_SALES_REGISTER_SUCCESS";
export const GET_MONTH_PARTY_WISE_SALES_REGISTER_FAILURE = "GET_MONTH_PARTY_WISE_SALES_REGISTER_FAILURE";

export const GET_GLOBAL_BUDGET_INIT = "GET_GLOBAL_BUDGET_INIT";
export const GET_GLOBAL_BUDGET_FINISH = "GET_GLOBAL_BUDGET_FINISH";
export const GET_GLOBAL_BUDGET_SUCCESS = "GET_GLOBAL_BUDGET_SUCCESS";
export const GET_GLOBAL_BUDGET_FAILED = "GET_GLOBAL_BUDGET_FAILED";

export const UPDATE_GLOBAL_BUDGET_INIT = "UPDATE_GLOBAL_BUDGET_INIT";
export const UPDATE_GLOBAL_BUDGET_FINISH = "UPDATE_GLOBAL_BUDGET_FINISH";
export const UPDATE_GLOBAL_BUDGET_SUCCESS = "UPDATE_GLOBAL_BUDGET_SUCCESS";
export const UPDATE_GLOBAL_BUDGET_FAILED = "UPDATE_GLOBAL_BUDGET_FAILED";

export const GET_BUDGET_VS_ACTUAL_INIT = "GET_BUDGET_VS_ACTUAL_INIT";
export const GET_BUDGET_VS_ACTUAL_FINISH = "GET_BUDGET_VS_ACTUAL_FINISH";
export const GET_BUDGET_VS_ACTUAL_SUCCESS = "GET_BUDGET_VS_ACTUAL_SUCCESS";
export const GET_BUDGET_VS_ACTUAL_FAILED = "GET_BUDGET_VS_ACTUAL_FAILED";

export const GET_VARIANCE_INIT = "GET_VARIANCE_INIT";
export const GET_VARIANCE_FINISH = "GET_VARIANCE_FINISH";
export const GET_VARIANCE_SUCCESS = "GET_VARIANCE_SUCCESS";
export const GET_VARIANCE_FAILED = "GET_VARIANCE_FAILED";


export const GET_LEDGER_VARIANCE_INIT = "GET_LEDGER_VARIANCE_INIT";
export const GET_LEDGER_VARIANCE_FINISH = "GET_LEDGER_VARIANCE_FINISH";
export const GET_LEDGER_VARIANCE_SUCCESS = "GET_LEDGER_VARIANCE_SUCCESS";
export const GET_LEDGER_VARIANCE_FAILED = "GET_LEDGER_VARIANCE_FAILED";



export const GLOBAL_BUDGET_UPDATE = "GLOBAL_BUDGET_UPDATE";
export const GLOBAL_BUDGET_LOADER_INIT = "GLOBAL_BUDGET_LOADER_INIT";
export const GLOBAL_BUDGET_LOADER_FINISH = "GLOBAL_BUDGET_LOADER_FINISH";

export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const CLEAR_BUDGET_STATE = "CLEAR_BUDGET_STATE";

export const LEDGER_BUDGET_DATE_RANGE = "LEDGER_BUDGET_DATE_RANGE";

export const RESET_GLOBAL_BUDGET_STATUS = "RESET_GLOBAL_BUDGET_STATUS";

export const BUDGET_FROM_DATE = "BUDGET_FROM_DATE";
export const BUDGET_TO_DATE = "BUDGET_TO_DATE";

export const BUDGET_VS_ACTUAL_FROM_DATE = "BUDGET_VS_ACTUAL_FROM_DATE";
export const BUDGET_VS_ACTUAL_TO_DATE = "BUDGET_VS_ACTUAL_TO_DATE";


export const VARIANCE_FROM_DATE = "VARIANCE_FROM_DATE";
export const VARIANCE_TO_DATE = "VARIANCE_TO_DATE";

export const LEDGER_VARIANCE_FROM_DATE = "LEDGER_VARIANCE_FROM_DATE";
export const LEDGER_VARIANCE_TO_DATE = "LEDGER_VARIANCE_TO_DATE";

export const LEDGER_VARIANCE_OLDEST_DATE = "LEDGER_VARIANCE_OLDEST_DATE";
export const CLEAR_LEDGER_VARIANCE_STATE = "CLEAR_LEDGER_VARIANCE_STATE";

export const GLOBAL_VARIANCE_OLDEST_DATE = "GLOBAL_VARIANCE_OLDEST_DATE";
export const GLOBAL_VARIANCE_FROM_DATE = "GLOBAL_VARIANCE_FROM_DATE";
export const GLOBAL_VARIANCE_TO_DATE = "GLOBAL_VARIANCE_TO_DATE";

export const VARIANCE_LOADER_INIT = "VARIANCE_LOADER_INIT";
export const VARIANCE_LOADER_FINISH = "VARIANCE_LOADER_FINISH";

export const BUDGET_VARIANCE_DATE_RANGE = "BUDGET_VARIANCE_DATE_RANGE";


export const SAVE_ALERT_CONFIGURATION = "SAVE_ALERT_CONFIGURATION";
export const SAVE_ALERT_CONFIGURATION_SUCCESS = "SAVE_ALERT_CONFIGURATION_SUCCESS";
export const SAVE_ALERT_CONFIGURATION_FAILURE = "SAVE_ALERT_CONFIGURATION_FAILURE";

export const SAVE_TRANSACTION_ALERT_CONFIG = "SAVE_TRANSACTION_ALERT_CONFIG";
export const SAVE_TRANSACTION_ALERT_CONFIG_SUCCESS = "SAVE_TRANSACTION_ALERT_CONFIG_SUCCESS";
export const SAVE_TRANSACTION_ALERT_CONFIG_FAILURE = "SAVE_TRANSACTION_ALERT_CONFIG_FAILURE";

export const GET_ALERT_CONFIGURATION_BY_USER_ID = "GET_ALERT_CONFIGURATION_BY_USER_ID";
export const GET_ALERT_CONFIGURATION_BY_USER_ID_SUCCESS = "GET_ALERT_CONFIGURATION_BY_USER_ID_SUCCESS";
export const GET_ALERT_CONFIGURATION_BY_USER_ID_FAILURE = "GET_ALERT_CONFIGURATION_BY_USER_ID_FAILURE";


export const DELETE_ALERT_BY_ID = "DELETE_ALERT_BY_ID";
export const DELETE_ALERT_BY_ID_SUCCESS = "DELETE_ALERT_BY_ID_SUCCESS";
export const DELETE_ALERT_BY_ID_FAILURE = "DELETE_ALERT_BY_ID_FAILURE";

export const GET_LEDGERS_BY_GROUP_ID = "GET_LEDGERS_BY_GROUP_ID";
export const GET_LEDGERS_BY_GROUP_ID_SUCCESS = "GET_LEDGERS_BY_GROUP_ID_SUCCESS";
export const GET_LEDGERS_BY_GROUP_ID_FAILURE = "GET_LEDGERS_BY_GROUP_ID_FAILURE";

export const GET_GROUPS_FOR_ALERTS = "GET_GROUPS_FOR_ALERTS";
export const GET_GROUPS_FOR_ALERTS_SUCCESS = "GET_GROUPS_FOR_ALERTS_SUCCESS";
export const GET_GROUPS_FOR_ALERTS_FAILURE = "GET_GROUPS_FOR_ALERTS_FAILURE";


export const SAVE_ALERT_BY_TYPE = "SAVE_ALERT_BY_TYPE";
export const SAVE_ALERT_BY_TYPE_SUCCESS = "SAVE_ALERT_BY_TYPE_SUCCESS";
export const SAVE_ALERT_BY_TYPE_FAILURE = "SAVE_ALERT_BY_TYPE_FAILURE";

export const CHECK_MOBILE_NO_VERIFIED = "CHECK_MOBILE_NO_VERIFIED";
export const CHECK_MOBILE_NO_VERIFIED_SUCCESS = "CHECK_MOBILE_NO_VERIFIED_SUCCESS";
export const CHECK_MOBILE_NO_VERIFIED_FAILURE = "CHECK_MOBILE_NO_VERIFIED_FAILURE";

export const ALERT_ACTIVE_INDEX = "ALERT_ACTIVE_INDEX";
export const SELECTED_VOUCHER_LIST_TYPE = "SELECTED_VOUCHER_LIST_TYPE";

export const GET_MONTH_WISE_STOCK_ITEM_SUMMARY_BY_ID = "GET_MONTH_WISE_STOCK_ITEM_SUMMARY_BY_ID";
export const GET_MONTH_WISE_STOCK_ITEM_SUMMARY_BY_ID_SUCCESS = "GET_MONTH_WISE_STOCK_ITEM_SUMMARY_BY_ID_SUCCESS";
export const GET_MONTH_WISE_STOCK_ITEM_SUMMARY_BY_ID_FAILURE = "GET_MONTH_WISE_STOCK_ITEM_SUMMARY_BY_ID_FAILURE";

export const STOCK_SUMMARY_DATA = "STOCK_SUMMARY_DATA";

export const GET_INVENTORY_VOUCHERS_BY_IDS = "GET_INVENTORY_VOUCHERS_BY_IDS";
export const GET_INVENTORY_VOUCHERS_BY_IDS_SUCCESS = "GET_INVENTORY_VOUCHERS_BY_IDS_SUCCESS";
export const GET_INVENTORY_VOUCHERS_BY_IDS_FAILURE = "GET_INVENTORY_VOUCHERS_BY_IDS_FAILURE";

export const GST_NEW_REPORTS_INIT = "GST_NEW_REPORTS_INIT";
export const GST_NEW_REPORTS_FINISH = "GST_NEW_REPORTS_FINISH";
export const GST_NEW_REPORTS_SUCCESS = "GST_NEW_REPORTS_SUCCESS";
export const GST_NEW_REPORTS_FAILED = "GST_NEW_REPORTS_FAILED";


export const GST_PREV_REPORTS_INIT = "GST_PREV_REPORTS_INIT";
export const GST_PREV_REPORTS_FINISH = "GST_PREV_REPORTS_FINISH";
export const GST_PREV_REPORTS_SUCCESS = "GST_PREV_REPORTS_SUCCESS";
export const GST_PREV_REPORTS_FAILED = "GST_PREV_REPORTS_FAILED";


export const GST_FILING_REPORTS_INIT = "GST_FILING_REPORTS_INIT";
export const GST_FILING_REPORTS_FINISH = "GST_FILING_REPORTS_FINISH";
export const GST_FILING_REPORTS_SUCCESS = "GST_FILING_REPORTS_SUCCESS";
export const GST_FILING_REPORTS_FAILED = "GST_FILING_REPORTS_FAILED";


export const GST_FILING_RETURNS_INIT = "GST_FILING_RETURNS_INIT";
export const GST_FILING_RETURNS_FINISH = "GST_FILING_RETURNS_FINISH";
export const GST_FILING_RETURNS_SUCCESS = "GST_FILING_RETURNS_SUCCESS";
export const GST_FILING_RETURNS_FAILED = "GST_FILING_RETURNS_FAILED";


export const UPDATE_GST_NUM_INIT = "UPDATE_GST_NUM_INIT";
export const UPDATE_GST_NUM_FINISH = "UPDATE_GST_NUM_FINISH";
export const UPDATE_GST_NUM_SUCCESS = "UPDATE_GST_NUM_SUCCESS";
export const UPDATE_GST_NUM_FAILED = "UPDATE_GST_NUM_FAILED";

export const GENERATE_FILING_REPORT_SUCCESS = "GENERATE_FILING_REPORT_SUCCESS";
export const GST_FILING_REPORT_ID = "GST_FILING_REPORT_ID";
export const REPORT_PROGRESS_SUCCESS = "REPORT_PROGRESS_SUCCESS";

export const REPORT_PROGRESS_MODAL_OPEN = "REPORT_PROGRESS_MODAL_OPEN";
export const REPORT_PROGRESS_MODAL_CLOSE = "REPORT_PROGRESS_MODAL_CLOSE";

export const GST_RETURNS_MODAL_OPEN = "GST_RETURNS_MODAL_OPEN";
export const GST_RETURNS_MODAL_CLOSE = "GST_RETURNS_MODAL_CLOSE";

export const SET_GST_ACTIVE_TAB = "SET_GST_ACTIVE_TAB";
export const SET_REGISTERED_NAME_GSTIN = "SET_REGISTERED_NAME_GSTIN";

export const DELETE_TALLY_COMPANY = "DELETE_TALLY_COMPANY";
export const DELETE_TALLY_COMPANY_SUCCESS = "DELETE_TALLY_COMPANY_SUCCESS";
export const DELETE_TALLY_COMPANY_FAILURE = "DELETE_TALLY_COMPANY_FAILURE";
export const COMPANY_LIST_UPDATED = "COMPANY_LIST_UPDATED";

export const UPDATE_SOCIA_AUTH_USER_MOBILE_NUM = "UPDATE_SOCIA_AUTH_USER_MOBILE_NUM";
export const UPDATE_SOCIA_AUTH_USER_MOBILE_NUM_SUCCESS = "UPDATE_SOCIA_AUTH_USER_MOBILE_NUM_SUCCESS";
export const UPDATE_SOCIA_AUTH_USER_MOBILE_NUM_FAILURE = "UPDATE_SOCIA_AUTH_USER_MOBILE_NUM_FAILURE";


export const OTP_VERIFICATION = "OTP_VERIFICATION";
export const OTP_VERIFICATION_RESET = "OTP_VERIFICATION_RESET";
export const OTP_VERIFICATION_SUCCESS = "OTP_VERIFICATION_SUCCESS";
export const OTP_VERIFICATION_FAILURE = "OTP_VERIFICATION_FAILURE";

export const SUBMIT_OTP = "SUBMIT_OTP";
export const SUBMIT_OTP_RESET = "SUBMIT_OTP_RESET";
export const SUBMIT_OTP_SUCCESS = "SUBMIT_OTP_SUCCESS";
export const SUBMIT_OTP_FAILURE = "SUBMIT_OTP_FAILURE";


export const CHECK_WHATSAPP_STATUS = "CHECK_WHATSAPP_STATUS";
export const CHECK_WHATSAPP_STATUS_SUCCESS = "CHECK_WHATSAPP_STATUS_SUCCESS";
export const CHECK_WHATSAPP_STATUS_FAILURE = "CHECK_WHATSAPP_STATUS_FAILURE";


export const GET_SUBSCTIPTION_DATA = "GET_SUBSCTIPTION_DATA";
export const GET_SUBSCTIPTION_DATA_SUCCESS = "GET_SUBSCTIPTION_DATA_SUCCESS";
export const GET_SUBSCTIPTION_DATA_FAILURE = "GET_SUBSCTIPTION_DATA_FAILURE";

export const GET_SUBSCTIPTION_VALIDITY = "GET_SUBSCTIPTION_VALIDITY";
export const GET_SUBSCTIPTION_VALIDITY_SUCCESS = "GET_SUBSCTIPTION_VALIDITY_SUCCESS";
export const GET_SUBSCTIPTION_VALIDITY_FAILURE = "GET_SUBSCTIPTION_VALIDITY_FAILURE";

export const GET_SUBSCRIPTION_INVOICE_LIST = "GET_SUBSCRIPTION_INVOICE_LIST";
export const GET_SUBSCRIPTION_INVOICE_LIST_SUCCESS = "GET_SUBSCRIPTION_INVOICE_LIST_SUCCESS";
export const GET_SUBSCRIPTION_INVOICE_LIST_FAILURE = "GET_SUBSCRIPTION_INVOICE_LIST_FAILURE";

export const GET_ALERT_TRANSACTIONS_TYPES = "GET_ALERT_TRANSACTIONS_TYPES";
export const GET_ALERT_TRANSACTIONS_TYPES_SUCCESS = "GET_ALERT_TRANSACTIONS_TYPES_SUCCESS";
export const GET_ALERT_TRANSACTIONS_TYPES_FAILURE = "GET_ALERT_TRANSACTIONS_TYPES_FAILURE";

export const GST_NOTIFICATION_CLICKED = "GST_NOTIFICATION_CLICKED";

export const GET_NEW_FEATURE_ALERT_STATUS = "GET_NEW_FEATURE_ALERT_STATUS";
export const GET_NEW_FEATURE_ALERT_STATUS_SUCCESS = "GET_NEW_FEATURE_ALERT_STATUS_SUCCESS";
export const GET_NEW_FEATURE_ALERT_STATUS_FAILURE = "GET_NEW_FEATURE_ALERT_STATUS_FAILURE";

export const RESUME_REPORT_FALSE_MESSAGE = "RESUME_REPORT_FALSE_MESSAGE";
export const REPORT_PROGRESS_MESSAGE = "REPORT_PROGRESS_MESSAGE";