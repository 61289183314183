import {
    GET_CREATE_GROUP_DATA_ERROR,
    GET_CREATE_GROUP_DATA_SUCCESS,

   } from "src/actions/actionTypes";
   
   const initialState = {
     groupDataList:[],
   };
   export default function getGroupDataReducer(state = initialState, action) {
     switch (action.type) {
       case GET_CREATE_GROUP_DATA_SUCCESS: {
         return { ...state, groupDataList: action.payload };
       }case GET_CREATE_GROUP_DATA_ERROR: {
        return { ...state, groupDataList: action.payload };
      }
       default:
         return state;
     }
   }
   