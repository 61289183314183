import {
GST_NEW_REPORTS_INIT,
GST_NEW_REPORTS_FINISH,
GST_NEW_REPORTS_SUCCESS,
GST_NEW_REPORTS_FAILED,
GST_PREV_REPORTS_INIT,
GST_PREV_REPORTS_FINISH,
GST_PREV_REPORTS_SUCCESS,
GST_PREV_REPORTS_FAILED,
GST_FILING_REPORTS_INIT,
GST_FILING_REPORTS_FINISH,
GST_FILING_REPORTS_SUCCESS,
GST_FILING_REPORTS_FAILED,
GST_FILING_RETURNS_INIT,
GST_FILING_RETURNS_FINISH,
GST_FILING_RETURNS_SUCCESS,
GST_FILING_RETURNS_FAILED,
GST_RETURNS_MODAL_OPEN,
GST_RETURNS_MODAL_CLOSE,
UPDATE_GST_NUM_INIT,
UPDATE_GST_NUM_FINISH,
UPDATE_GST_NUM_SUCCESS,
UPDATE_GST_NUM_FAILED,
GENERATE_FILING_REPORT_SUCCESS,
GST_FILING_REPORT_ID,
REPORT_PROGRESS_SUCCESS,
REPORT_PROGRESS_MODAL_OPEN,
REPORT_PROGRESS_MODAL_CLOSE,
SET_GST_ACTIVE_TAB,
SET_REGISTERED_NAME_GSTIN,
REPORT_PROGRESS_MESSAGE,
RESUME_REPORT_FALSE_MESSAGE
} from "src/actions/actionTypes";

const inititalState = {
gstReportsReqStatus: false,
gstReportsStatus: null,
gstReportsData: null,
gstReportsMessage: null,
gstPrevReportsReqStatus: false,
gstPrevReportsStatus: null,
gstPrevReportsData: [],
gstPrevReportsMessage: null,
gstFilingReportsReqStatus: false,
gstFilingReportsStatus: null,
gstFilingReportsData: null,
gstFilingReportsMessage: null,
gstFilingReportId: null,
gstFilingReturnsReqStatus: false,
gstReturnsModal: false,
gstFilingReturnsStatus: null,
gstFilingReturnsData: null,
gstFilingReturnsMessage: null,
generateFilingReportReqStatus: false,
generateFilingReportStatus: null,
generateFilingReportData: null,
generateFilingReportMessage: null,
updateGstNumReqStatus: false,
updateGstNumStatus: null,
updateGstNumData: null,
updateGstNumMessage: null,
reportProgressModal: false,
registeredNameGstin: {},
gstActiveTab: 0,
reportProgress: {},

checkFirstLoad: null,

gstNotifClicked: false,

resumeReportFalseMessage: null,
reportProgressMessage: null,

};

export default function gstReducer(state = inititalState, action) {
    switch (action.type) {

    case RESUME_REPORT_FALSE_MESSAGE: {
        return {...state, resumeReportFalseMessage: action.payload}
    }

    case REPORT_PROGRESS_MESSAGE: {
        return {...state, reportProgressMessage: action.payload}
    }

    case "GST_NOTIFICATION_CLICKED": {
        return {...state, gstNotifClicked: action.payload}
    }

    case GST_NEW_REPORTS_INIT: {
        return {...state, gstReportsReqStatus: true}
    }
    case GST_NEW_REPORTS_FINISH: {
        return {...state, gstReportsReqStatus: false}
    }

    case GST_NEW_REPORTS_SUCCESS: {
        return {...state, gstReportsStatus: action.payload[0], gstReportsData: action.payload[1], gstReportsMessage: action.payload[2]}
    }

    case "SET_GST_REPORT_DATA": {
        return {...state, gstReportsData: action.payload}
    }

    case GST_NEW_REPORTS_FAILED: {
        return {...state, gstReportsStatus: action.payload[0], gstReportsMessage: action.payload[1]}
      }

    case GST_PREV_REPORTS_INIT: {
        return {...state, gstPrevReportsReqStatus: true}
    }

    case GST_PREV_REPORTS_FINISH: {
        return {...state, gstPrevReportsReqStatus: false, checkFirstLoad: true}
    }

    case GST_PREV_REPORTS_SUCCESS: {
        return {...state, gstPrevReportsStatus: action.payload[0], gstPrevReportsData: action.payload[1],  gstPrevReportsMessage: action.payload[2]}
    }

    case GST_PREV_REPORTS_FAILED: {
        return {...state, gstPrevReportsStatus: action.payload[0], gstPrevReportsMessage: action.payload[1]}
    }

    
    case GST_FILING_REPORTS_INIT: {
        return {...state, gstFilingReportsReqStatus: true}
    }

    case GST_FILING_REPORTS_FINISH: {
        return {...state, gstFilingReportsReqStatus: false}
    }

    case GST_FILING_REPORTS_SUCCESS: {
        return {...state, gstFilingReportsStatus: action.payload[0], gstFilingReportsData: action.payload[1],  gstFilingReportsMessage: action.payload[2]}
    }

    case GST_FILING_REPORTS_FAILED: {
        return {...state, gstFilingReportsStatus: action.payload[0], gstFilingReportsMessage: action.payload[1]}
    }



    
    case GST_FILING_RETURNS_INIT: {
        return {...state, gstFilingReturnsReqStatus: true}
    }

    case GST_FILING_RETURNS_FINISH: {
        return {...state, gstFilingReturnsReqStatus: false}
    }

    case GST_FILING_RETURNS_SUCCESS: {
        return {...state, gstFilingReturnsStatus: action.payload[0], gstFilingReturnsData: action.payload[1],  gstFilingReturnsMessage: action.payload[2]}
    }

    case GENERATE_FILING_REPORT_SUCCESS: {        
        return {...state, generateFilingReportStatus: action.payload[0], generateFilingReportData: action.payload[1],  generateFilingReportMessage: action.payload[2]}
    }

    case UPDATE_GST_NUM_SUCCESS: {
        return {...state, updateGstNumStatus: action.payload[0], updateGstNumData: action.payload[1],  updateGstNumMessage: action.payload[2]}
    }

    case GST_FILING_RETURNS_FAILED: {
        return {...state, gstFilingReturnsStatus: action.payload[0], gstFilingReturnsMessage: action.payload[1]}
    }

    case GST_FILING_REPORT_ID: {
        return {...state, gstFilingReportId: action.payload}
    }

    case REPORT_PROGRESS_SUCCESS: {
        return {...state, reportProgress: action.payload}
    }


    case "UPDATE_PREV_REPORT_DATA": {
        return {...state, gstPrevReportsData: action.payload}
    }

    case SET_GST_ACTIVE_TAB: {
        return {...state, gstActiveTab: action.payload}
    }

    case SET_REGISTERED_NAME_GSTIN: {
        return {...state, registeredNameGstin: action.payload}
    }

    case REPORT_PROGRESS_MODAL_OPEN: {
        return {...state, reportProgressModal: true}
    }
    
    case REPORT_PROGRESS_MODAL_CLOSE: {
        return {...state, reportProgressModal: false, reportProgress: {}}
    }

    case GST_RETURNS_MODAL_OPEN: {
        return {...state, gstReturnsModal: true}
    }
    
    case GST_RETURNS_MODAL_CLOSE: {
        return {...state, gstReturnsModal: false}
    }

default:
return state;
}
}
