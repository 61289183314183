import {
    SAVE_QUERY,
    SAVE_QUERY_SUCCESS,
    SAVE_QUERY_FAILURE,
    GET_QUERY_BY_TYPE_SUCCESS,
    QUERY_SAVE_DEFAULT,
    GET_QUERY_COUNT_INFO_SUCCESS,
    GET_QUERIES_BY_QUERY_GROUP_SUCCESS,
    GET_QUERY_GROUP_LIST_SUCCESS,
    GET_QUERY_DATA,
    GET_QUERY_DATA_SUCCESS,
    GET_QUERY_COUNT_INFO,
    GET_QUERIES_BY_REFTYPE_AND_ID_SUCCESS,
    CHANGE_QUERY_STATUS_SUCCESS,
    CHANGE_QUERY_STATUS,
    GET_QUERY_LIST_BY_COMBINATION,
    GET_QUERY_LIST_BY_COMBINATION_SUCCESS,
    GET_QUERIES_BY_QUERY_GROUP
} from "src/actions/actionTypes";
import { getList, InsertFunctionStatus } from "src/util/enums";

const initialState = {
    querySaveStatus: InsertFunctionStatus.NONE,
    queriesList: [],
    queryCountInfo: [],
    queriesListByGroup: null,
    queriesGropList: [],
    chatStatus: InsertFunctionStatus.NONE,
    queryData:[],
    queryListByRefTypeAndId: [],
    changeQueryStatus: InsertFunctionStatus.NONE,
    queriesListByCombination:[]
};

export default function queryReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_QUERY: {
            return { ...state, querySaveStatus: InsertFunctionStatus.NONE };
        }
        case SAVE_QUERY_SUCCESS: {
            return { ...state, querySaveStatus: action.payload };
        }
        case SAVE_QUERY_FAILURE: {
            return { ...state, querySaveStatus: InsertFunctionStatus.NONE };
        }
        case GET_QUERY_BY_TYPE_SUCCESS: {
            return { ...state, queriesList: action.payload };
        }
        case QUERY_SAVE_DEFAULT: {
            return { ...state, querySaveStatus: InsertFunctionStatus.NONE };
        }
        case GET_QUERY_COUNT_INFO: {
            return { ...state, queryCountInfo: getList.inprogress };
        }
        case GET_QUERY_COUNT_INFO_SUCCESS: {
            return { ...state, queryCountInfo: action.payload };
        }
        case GET_QUERIES_BY_QUERY_GROUP: {
            return { ...state, queriesListByGroup: null };
        }
        case GET_QUERIES_BY_QUERY_GROUP_SUCCESS: {
            return { ...state, queriesListByGroup: action.payload };
        }
        case GET_QUERY_GROUP_LIST_SUCCESS: {
            return { ...state, queriesGropList: action.payload };
        }
        case GET_QUERY_DATA: {
            return { ...state, queryData: null };
        }
        case GET_QUERY_DATA_SUCCESS: {
            return { ...state, queryData: action.payload };
        }
        case GET_QUERIES_BY_REFTYPE_AND_ID_SUCCESS: {
            return { ...state, queryListByRefTypeAndId: action.payload };
        }
        case CHANGE_QUERY_STATUS_SUCCESS: {
            return { ...state, changeQueryStatus: InsertFunctionStatus.SUCCESS};
        }
        case CHANGE_QUERY_STATUS: {
            return { ...state, changeQueryStatus: InsertFunctionStatus.NONE};
        }
        case GET_QUERY_LIST_BY_COMBINATION: {
            return { ...state, queriesListByCombination: getList.inprogress};
        }
        case GET_QUERY_LIST_BY_COMBINATION_SUCCESS: {
            return { ...state, queriesListByCombination: action.payload};
        }
        // 
        default:
            return state;
    }
}
