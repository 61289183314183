import {
    GET_STOCK_ITEMS,
    GET_STOCK_ITEMS_SUCCESS,
    GET_STOCK_GROUPS,
    GET_STOCK_GROUPS_SUCCESS,
    GET_STOCK_VOUCHERS,
    GET_STOCK_VOUCHERS_SUCCESS,
    GET_GODOWN,
    GET_STOCK_ITEM_LIST_BY_TYPE_ID_SUCCESS,
    GET_STOCK_ITEM_SUMMARY_BY_ID_SUCCESS,
    GET_STOCK_ITEM_INFO_BY_ID_SUCCESS,
    GET_STOCK_ITEM_PURCHASES_BY_ID_SUCCESS,
    GET_STOCK_ITEM_SALES_BY_ID_SUCCESS,
    GET_STOCK_GROUP_SUMMARY,
    GET_STOCK_GROUP_SUMMARY_SUCCESS,
    GET_STOCK_SUMMARY_BY_TYPE_SUCCESS,
    GET_STOCK_SUMMARY_BY_TYPE,
    GET_STOCK_DATA_BY_TYPE_SUCCESS,
    GET_STOCK_DATA_BY_TYPE,
    GET_STOCK_ITEM_SUMMARY_BY_ID,
    GET_STOCK_ITEM_INFO_BY_ID,
    GET_MONTH_WISE_STOCK_ITEM_SUMMARY_BY_ID_SUCCESS,
    GET_STOCK_ITEM_SALES_BY_ID,
    GET_STOCK_ITEM_PURCHASES_BY_ID,
    GET_INVENTORY_VOUCHERS_BY_IDS_SUCCESS,
    GET_INVENTORY_VOUCHERS_BY_IDS
} from "src/actions/actionTypes";
import { getList } from "src/util/enums";

const initialState = {
    stockItems: null,
    stockGroups: [],
    stockVoucherList: [],
    godownList: [],
    stockItemListByTypeId: [],
    stockItemSummaryById: null,
    stockItemInfoById: null,
    stockItemPurchaseById: null,
    stockItemSalesById: null,
    stockGroupSummary: [],
    stockSummaryByType: null,
    stockDataByType: null,
    invVoucherList: null,

};

export default function invenoryReducer(state = initialState, action) {
    switch (action.type) {
        case GET_STOCK_ITEMS: {
            return { ...state, stockItems: null };
        }
        case GET_STOCK_ITEMS_SUCCESS: {
            return { ...state, stockItems: action.payload };
        }
        case GET_STOCK_GROUPS: {
            return { ...state, stockGroups: getList.inprogress };
        }
        case GET_STOCK_GROUPS_SUCCESS: {
            return { ...state, stockGroups: action.payload };
        }
        case GET_STOCK_VOUCHERS: {
            return { ...state, stockVoucherList: getList.inprogress };
        }
        case GET_STOCK_VOUCHERS_SUCCESS: {
            return { ...state, stockVoucherList: action.payload };
        }
        case GET_GODOWN: {
            return { ...state, godownList: getList.inprogress };
        }
        // case GET_GODOWN_SUCCESS: {
        //     return { ...state, godownList: action.payload };
        // }
        case GET_STOCK_DATA_BY_TYPE: {
            return { ...state, stockDataByType: null };
        }
        case GET_STOCK_DATA_BY_TYPE_SUCCESS: {
            return { ...state, stockDataByType: action.payload };
        }
        case GET_STOCK_ITEM_LIST_BY_TYPE_ID_SUCCESS: {
            return { ...state, stockItemListByTypeId: action.payload };
        }
        case GET_STOCK_ITEM_SUMMARY_BY_ID: {
            return { ...state, stockItemSummaryById: null };
        }
        case GET_STOCK_ITEM_SUMMARY_BY_ID_SUCCESS: {
            return { ...state, stockItemSummaryById: action.payload };
        }
        case GET_MONTH_WISE_STOCK_ITEM_SUMMARY_BY_ID_SUCCESS: {
            return { ...state, montWiseSummary: action.payload };
        }
        case GET_STOCK_ITEM_PURCHASES_BY_ID: {
            return { ...state, stockItemPurchaseById: null };
        }
        case GET_STOCK_ITEM_PURCHASES_BY_ID_SUCCESS: {
            return { ...state, stockItemPurchaseById: action.payload };
        }
        case GET_STOCK_ITEM_INFO_BY_ID: {
            return { ...state, stockItemInfoById: null };
        }
        case GET_STOCK_ITEM_INFO_BY_ID_SUCCESS: {
            return { ...state, stockItemInfoById: action.payload };
        }
        case GET_STOCK_ITEM_SALES_BY_ID: {
            return { ...state, stockItemSalesById: null };
        }
        case GET_STOCK_ITEM_SALES_BY_ID_SUCCESS: {
            return { ...state, stockItemSalesById: action.payload };
        }
        case GET_STOCK_GROUP_SUMMARY: {
            return { ...state, stockGroupSummary: getList.inprogress };
        }
        case GET_STOCK_GROUP_SUMMARY_SUCCESS: {
            return { ...state, stockGroupSummary: action.payload };
        }
        case GET_STOCK_SUMMARY_BY_TYPE: {
            return { ...state, stockSummaryByType: null };
        }
        case GET_STOCK_SUMMARY_BY_TYPE_SUCCESS: {
            return { ...state, stockSummaryByType: action.payload };
        }
        case GET_INVENTORY_VOUCHERS_BY_IDS: {
            return { ...state, invVoucherList: null };
        }
        case GET_INVENTORY_VOUCHERS_BY_IDS_SUCCESS: {
            return { ...state, invVoucherList: action.payload };
        }
        default:
            return state;
    }
}

