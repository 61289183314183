import {
    ALERT_ACTIVE_INDEX,
    DELETE_ALERT_BY_ID,
    DELETE_ALERT_BY_ID_SUCCESS,
    GET_ALERT_CONFIGURATION_BY_USER_ID,
    GET_ALERT_CONFIGURATION_BY_USER_ID_SUCCESS,
    GET_GROUPS_FOR_ALERTS_SUCCESS,
    GET_LEDGERS_BY_GROUP_ID,
    GET_LEDGERS_BY_GROUP_ID_SUCCESS,
    SAVE_ALERT_BY_TYPE,
    SAVE_ALERT_BY_TYPE_SUCCESS,
    SAVE_TRANSACTION_ALERT_CONFIG,
    SAVE_TRANSACTION_ALERT_CONFIG_SUCCESS,
    GET_ALERT_TRANSACTIONS_TYPES,
    GET_ALERT_TRANSACTIONS_TYPES_SUCCESS,
    GET_NEW_FEATURE_ALERT_STATUS,
    GET_NEW_FEATURE_ALERT_STATUS_SUCCESS

} from "src/actions/actionTypes";
import { InsertFunctionStatus } from "src/util/enums";

const initialState = {
    alertSaveStatus: InsertFunctionStatus.NONE,
    alertList: null,
    alertDeleteStatus: InsertFunctionStatus.NONE,
    alertsForGroups: [],
    ledgerByGroupId: [],
    alertActiveIndex: null,
    transactionTypes: null,
    newFeatureAlertShown: true
};

export default function alertsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ALERT_CONFIGURATION_BY_USER_ID: {
            return { ...state, alertList: null };
        }
        case GET_ALERT_CONFIGURATION_BY_USER_ID_SUCCESS: {
            return { ...state, alertList: action.payload };
        }
        case SAVE_TRANSACTION_ALERT_CONFIG: {
            return { ...state, alertSaveStatus: InsertFunctionStatus.INPROGRESS };
        }
        case SAVE_TRANSACTION_ALERT_CONFIG_SUCCESS: {
            return { ...state, alertSaveStatus: InsertFunctionStatus.SUCCESS };
        }
        case DELETE_ALERT_BY_ID: {
            return { ...state, alertDeleteStatus: InsertFunctionStatus.INPROGRESS };
        }
        case DELETE_ALERT_BY_ID_SUCCESS: {
            return { ...state, alertDeleteStatus: InsertFunctionStatus.SUCCESS };
        }
        case GET_GROUPS_FOR_ALERTS_SUCCESS: {
            return { ...state, alertsForGroups: action.payload };
        }
        case GET_LEDGERS_BY_GROUP_ID: {
            return { ...state, ledgerByGroupId: [] };
        }
        case GET_LEDGERS_BY_GROUP_ID_SUCCESS: {
            return { ...state, ledgerByGroupId: action.payload };
        }
        case SAVE_ALERT_BY_TYPE: {
            return { ...state, alertSaveStatus: InsertFunctionStatus.INPROGRESS };
        }
        case SAVE_ALERT_BY_TYPE_SUCCESS: {
            return { ...state, alertSaveStatus: InsertFunctionStatus.SUCCESS };
        }
        case ALERT_ACTIVE_INDEX: {
            return { ...state, alertActiveIndex: action.payload };
        }
        case GET_ALERT_TRANSACTIONS_TYPES: {
            return { ...state, transactionTypes: null };
        }
        case GET_ALERT_TRANSACTIONS_TYPES_SUCCESS: {
            return { ...state, transactionTypes: action.payload };
        }
        case GET_NEW_FEATURE_ALERT_STATUS: {
            return { ...state, newFeatureAlertShown: true };
        }
        case GET_NEW_FEATURE_ALERT_STATUS_SUCCESS: {
            return { ...state, newFeatureAlertShown: action.payload };
        }
        default:
            return state;
    }
}
