import React, { useState, useEffect } from 'react'
import Logofull from "src/assets/icons/logo-large.png";
import {
    CCard,
    CCardBody,
    CCol,
    CContainer,
    CInput,
    CInputGroup,
    CRow
} from '@coreui/react'
import { Formik } from "formik"
import ToastMessage from "src/components/toast-message/ToastMessage";
import * as yup from "yup"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { userLogin, getBookStartAndEndDate, initUserProfileData, verifyUser, registerUnRegisteredUser, getOrganisationsListByUserId } from 'src/actions/registration';
import { css } from "@emotion/core";

import cookie from 'react-cookies'


import { useLocation } from "react-router-dom";
import { GET_FISCAL_YEARS, GET_SELECTED_FISCAL_YEARS, GET_USER_COMPANIES_IN_LOGIN_SUCCESS, RESET_FILTERED_JSON, SET_SELECTED_TRANSACTION_TYPES, USER_LOGIN_SUCCESS } from 'src/actions/actionTypes';
import { ScaleLoader } from 'react-spinners';
import { getUserEmail } from 'src/util/miscUtil';
import { BroadcastChannel } from 'broadcast-channel';

const VerifyInvitation = (props) => {
    const { invitationFrom, dataFromMyTallyComapny } = props

    const inviteeStatus = useSelector(state => state?.registration?.inviteeStatus)
    const responceAfterInvitereg = useSelector(state => state?.registration?.responceAfterInvite)
    const initLoginData = useSelector(state => state.registration?.loginUserData)
    const userId = useSelector(state => state.userReduser?.loginUserData?.userId);
    const getMyTallyCompanies = useSelector(state => state?.registration?.getMyTallyCompanies);
    const loginUserData = useSelector(state => state.userReduser?.loginUserData)
    const history = useHistory()
    const { search } = useLocation();
    const token = search.replace('?token=', '');
    const dispatch = useDispatch()
    const [showContent, setShowContent] = useState("")
    const [emailForLogIn, setemailForLogIn] = useState()
    const [passwordForlogIn, setpasswordForlogIn] = useState()

    const channel = new BroadcastChannel('loginReload');
    useEffect(() => {
        channel.onmessage = msg => {
            if (msg == "login") {
                window.location.reload()
            }
        }
        return () => {
            channel.close()
        }
    }, [channel])
    useEffect(() => {
        if (responceAfterInvitereg && Object.keys(responceAfterInvitereg).length > 0) {
            setmailContent(responceAfterInvitereg?.response)
            if (responceAfterInvitereg?.status) {
                setShowContent("user-already-registred")
                setemailForLogIn(responceAfterInvitereg?.invitee_email)
                setloading(false)
            } else {
                setShowContent("user-verification-failed")
                setloading(false)
            }
        }
    }, [responceAfterInvitereg])

    useEffect(() => {
        if (invitationFrom != "myTallyComanyPage") {
            if (token) {
                const data = {
                    "token": token
                }
                dispatch(verifyUser(data))
            } else {
                // if user logged in navigate to dashboard
                // if user not logged in navigate to login
                if (cookie.load("PRACTICE_USER_DATA")) {
                    if (cookie.load("SELECTED_COMPANY")) {
                        history.push("/tasks")
                    } else {
                        history.push("/no-companies-found")
                    }
                } else {
                    history.push("/login")
                }
            }
        }
    }, [token])
    useEffect(() => {
        if (dataFromMyTallyComapny) {
            const data = {
                "token": dataFromMyTallyComapny?.token
            }
            dispatch(verifyUser(data))
        }
    }, [dataFromMyTallyComapny])
    const [inviteeEmail, setinviteeEmail] = useState()
    useEffect(() => {
        setloading(true)
        setmailContent("")
        setmailContent("")
        if (inviteeStatus && Object.keys(inviteeStatus).length > 0) {
            setmailContent(inviteeStatus?.response)
            if (inviteeStatus?.status) {
                if (inviteeStatus.invitee_exists) {
                    setinviteeEmail(inviteeStatus.invitee_email)
                    setShowContent("user-already-registred")
                    if (invitationFrom == "myTallyComanyPage") {
                        dispatch(getOrganisationsListByUserId(userId))
                    }
                } else {
                    setShowContent("user-not-registred")
                }
                setloading(false)
            } else {
                setShowContent("user-verification-failed")
                setloading(false)
            }

        }
    }, [inviteeStatus])

    const [loading, setloading] = useState(true)
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
    `;


    const [mailContent, setmailContent] = useState("")

    const [initialValuesReg, setInitialValuesReg] = useState({
        firstName: "",
        lastName: "",
        password: "",
        confirm_password: ""
    });
    const validationSchema = yup.object().shape({
        firstName: yup
            .string()
            .max(30, 'you can not enter more than 30 charcters')
            .required("first name is required"),
        lastName: yup
            .string()
            .max(30, 'you can not enter more than 30 charcters')
            .required("last name is required"),
        password: yup
            .string()
            .min(4)
            .max(30, 'you can not enter more than 30 charcters')
            .required(),
        confirm_password: yup
            .string()
            .required("confirm password is required")
            .oneOf(
                [yup.ref('password'), null],
                'Passwords must match',
            ),
    })
    const saveUseDetails = (values) => {
        setpasswordForlogIn(values.password)
        const data = {
            "token": token,
            "invitee_data": {
                "first_name": values.firstName,
                "last_name": values.lastName,
                "password": values.password
            }
        }
        dispatch(registerUnRegisteredUser(data))
        setloading(true)
    }

    const [stateForCompanies, setstateForCompanies] = useState()
    useEffect(() => {
        if (loginUserData) {
            setstateForCompanies(loginUserData)
        }
    }, [loginUserData])

    const [visibilityPassWord, setVisibilityPassWord] = useState(false)
    const [visibilityCPassWord, setVisibilityCPassWord] = useState(false)

    useEffect(() => {
        if (getMyTallyCompanies) {
            if (getMyTallyCompanies[0]?.status == "inprogress") {
                // setloading(true)
            } else {
                // if user have companies push the latest one into existing once
                if (getMyTallyCompanies.length > 0) {
                    let data = stateForCompanies
                    if (data) {
                        data['tallyCompaniesList'] = getMyTallyCompanies
                        dispatch({ type: GET_USER_COMPANIES_IN_LOGIN_SUCCESS, payload: data })
                        // console.log(getMyTallyCompanies);;
                        if (getMyTallyCompanies.length == 1) {
                            cookie.save('SELECTED_COMPANY', getMyTallyCompanies[0]?.companyName, { path: '/' })
                            cookie.save('SELECTED_TCUUID', getMyTallyCompanies[0]?.tcUuid || getMyTallyCompanies[0]?.tc_uuid, { path: '/' })
                            cookie.save('TENANT', getMyTallyCompanies[0]?.tallySchema || getMyTallyCompanies[0]?.schemaName, { path: '/' })
                            dispatch({
                                type: RESET_FILTERED_JSON,
                                payload: null,
                            })
                            dispatch({
                                type: SET_SELECTED_TRANSACTION_TYPES,
                                payload: null,
                            })
                            dispatch({
                                type: GET_SELECTED_FISCAL_YEARS,
                                payload: null
                            })
                            dispatch({
                                type: GET_FISCAL_YEARS,
                            })
                        }
                        history.push("/tasks")
                    }
                }
            }
        }
    }, [getMyTallyCompanies]);
    const [showToast, setshowToast] = useState()
    const onClickGetStarted = () => {
        if (cookie.load("PRACTICE_USER_DATA")) {
            let loginEmail = getUserEmail()
            if (inviteeEmail == loginEmail) {
                dispatch(getOrganisationsListByUserId(userId))
            } else {
                setshowToast(true)
                setTimeout(() => {
                    setshowToast(false)
                }, 3000);
            }
        } else {
            history.push("/login")
        }
    }

    const onClickOnLogin = () => {

        if (!cookie.load("PRACTICE_USER_DATA")) {
            dispatch(userLogin(emailForLogIn, passwordForlogIn))
        } else {
            setshowToast(true)
            setTimeout(() => {
                setshowToast(false)
            }, 3000);
        }
    }

    useEffect(() => {
        if (initLoginData) {
            if (initLoginData.loginValidateStatus) {
                dispatch({ type: GET_USER_COMPANIES_IN_LOGIN_SUCCESS, payload: initLoginData });
                let data = JSON.parse(JSON.stringify(initLoginData))
                data.Practices_list = []
                cookie.save("PRACTICE_USER_DATA", data, { path: '/' })
                if (initLoginData?.Practices_list?.length > 0) {
                    history.push("/tasks")
                } else {
                    history.push("/no-companies-found")
                }
                channel.postMessage("login");
            }
            dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: null
            })
        }
    }, [initLoginData]);

    return (
        <>
            {showToast && (
                <ToastMessage
                    severity={"error"}
                    summary={"Error"}
                    detail={"Multiple users not allowed! Please logout and try again"}
                />
            )}
            <div className="">
                <div className="c-app c-default-layout flex-row align-items-center mdb-shadow">
                    <CContainer>
                        <CRow className="justify-content-center">
                            <CCol xl="6" lg="6" md="12" sm="12">
                                <CCard className="border-0">
                                    <CCardBody className="p-0">
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                                <div className="p-2 verify-bg">
                                                    {invitationFrom != "myTallyComanyPage" && <img src={Logofull} className="c-sidebar-brand-full mobile-logo-width img-fluid mb-3" />}{" "}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center p-5 inside-border-radius">
                                            {!loading &&
                                                <div>
                                                    {/* when the user is already registred */}

                                                    {showContent == "user-already-registred" && <div className="success-full">
                                                        <span className="verify-success-icon-view">
                                                            <i className="fas fa-user" aria-hidden="true"></i>
                                                        </span>
                                                        <h5 className="bold-label mb-4 mt-4">Successful</h5>
                                                        <hr className="hr-light-border mt-2 mb-2 w-25"></hr>
                                                        <div className="pl-2 pr-2">
                                                            <h6 className="bold-label mb-4 line-height-custom">{mailContent}</h6>
                                                            {!emailForLogIn && invitationFrom != "myTallyComanyPage" && <button className="btn btn-primary btn-lg status-font btn-shadow" onClick={() => onClickGetStarted()}><i class="fas fa-hand-point-up"></i> Get Started</button>}
                                                            {emailForLogIn && invitationFrom != "myTallyComanyPage" && <button className="btn btn-primary btn-lg status-font btn-shadow" onClick={() => onClickOnLogin()}><i class="fas fa-hand-point-up"></i> Login</button>}
                                                        </div>
                                                    </div>}
                                                    {/* when th user not registred */}
                                                    {showContent == "user-not-registred" &&
                                                        <div>
                                                            <Formik
                                                                enableReinitialize={true}
                                                                initialValues={initialValuesReg}
                                                                validationSchema={validationSchema}
                                                                onSubmit={((values, errors) => {
                                                                    saveUseDetails(values)
                                                                })}

                                                            >
                                                                {({ handleChange, handleSubmit, handleBlur, values, errors, touched, }) => (
                                                                    <form onSubmit={handleSubmit}>


                                                                        <div className="pl-2 pr-2">
                                                                            <h6 className="status-font mb-4 line-height-custom">{mailContent}</h6>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <CInputGroup className="mb-3 mt-1">
                                                                                    <CInput
                                                                                        type="text" className="shadow-sm rounded-pil border-r-b pl-2 form-control-lg border-0 custom-input"
                                                                                        placeholder="First Name"
                                                                                        name="firstName"
                                                                                        value={values.firstName}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                    <i className="fas fa-user inside-icon"></i>
                                                                                </CInputGroup>
                                                                                {errors.firstName && touched.firstName ? (<div className="mb-1 sign-error-msgs text-danger">{errors.firstName}</div>) : null}
                                                                            </div>

                                                                            <div className="col-md-6">
                                                                                <CInputGroup className="mb-3 mt-1">
                                                                                    <CInput
                                                                                        type="text" className="shadow-sm rounded-pil border-r-b pl-2 form-control-lg border-0 custom-input"
                                                                                        placeholder="Last Name"
                                                                                        name="lastName"
                                                                                        value={values.lastName}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                    <i className="fas fa-user inside-icon"></i>
                                                                                </CInputGroup>
                                                                                {errors.lastName && touched.lastName ? (<div className="mb-1 sign-error-msgs text-danger">{errors.lastName}</div>) : null}
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <CInputGroup className="mb-3 mt-1">
                                                                                    <CInput
                                                                                        type={visibilityPassWord ? "text" : "password"}
                                                                                        className="shadow-sm rounded-pil border-r-b pl-2 form-control-lg border-0 custom-input"
                                                                                        placeholder="Password"
                                                                                        name="password"
                                                                                        value={values.password}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                    {!values?.password && <i className="fas fa-key inside-icon"></i>}
                                                                                    {!visibilityPassWord && values?.password && <i className="fa fa-eye inside-icon c-pointer" aria-hidden="true" onClick={e => setVisibilityPassWord(!visibilityPassWord)}></i>}
                                                                                    {visibilityPassWord && values?.password && <i className="fa fa-eye-slash inside-icon c-pointer" aria-hidden="true" onClick={e => setVisibilityPassWord(!visibilityPassWord)}></i>}
                                                                                </CInputGroup>
                                                                                {errors.password && touched.password ? (<div className="mb-1 sign-error-msgs text-danger">{errors.password}</div>) : null}
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <CInputGroup className="mb-3 mt-1">
                                                                                    <CInput
                                                                                        value={values.confirm_password}
                                                                                        type={visibilityCPassWord ? "text" : "password"}
                                                                                        name="confirm_password"
                                                                                        placeholder="Confirm Password" className="shadow-sm border-r-b pl-2 rounded-pil form-control-lg border-0 custom-input"
                                                                                        autoComplete="new-password"
                                                                                        onChange={handleChange}

                                                                                    />
                                                                                    {!values?.confirm_password && <i className="fas fa-key inside-icon"></i>}
                                                                                    {!visibilityCPassWord && values?.confirm_password && <i className="fa fa-eye inside-icon c-pointer" aria-hidden="true" onClick={e => setVisibilityCPassWord(!visibilityCPassWord)}></i>}
                                                                                    {visibilityCPassWord && values?.confirm_password && <i className="fa fa-eye-slash inside-icon c-pointer" aria-hidden="true" onClick={e => setVisibilityCPassWord(!visibilityCPassWord)}></i>}
                                                                                </CInputGroup>
                                                                                {errors.confirm_password && touched.confirm_password ? (<div className="mb-1 sign-error-msgs text-danger">{errors.confirm_password}</div>) : null}
                                                                            </div>
                                                                            <div className="col-md-12 mt-2">
                                                                                <button type="submit" className="btn btn-block btn-primary btn-lg bold-label btn-shadow" >Join</button>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                )}
                                                            </Formik>
                                                        </div>
                                                    }
                                                    {showContent == "user-verification-failed" &&
                                                        <div>
                                                            <span className="verify-warning-icon-view">
                                                                <i className="fas fa-exclamation" aria-hidden="true"></i>
                                                            </span>
                                                            <h5 className="bold-label mb-4 mt-4">Verification Failed</h5>
                                                            <hr className="hr-light-border mt-3 mb-2 w-25"></hr>
                                                            <div className="pl-2 pr-2">
                                                                <h6 className="status-font mb-4 line-height-custom">{mailContent}</h6>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                            }

                                            {loading && <div className="card-body p-3 text-center">
                                                <div className="mt-5 mb-5 text-center">
                                                    <ScaleLoader
                                                        css={override}
                                                        // size={60 , 5}
                                                        height={50}
                                                        width={5}
                                                        margin={5}
                                                        color={"#f58b3d"}
                                                        loading={loading}
                                                    />
                                                </div>
                                            </div>}
                                        </div>

                                    </CCardBody>
                                </CCard>
                            </CCol>
                        </CRow>
                    </CContainer>
                </div>
            </div>
        </>
    )
}

export default VerifyInvitation