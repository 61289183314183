import React, { useEffect, useState } from "react";
import { browserName } from 'react-device-detect'

const Disclaimar = () => {

    const [showAlert, setshowAlert] = useState(false)
  useEffect(() => {
    if(browserName && browserName != "Chrome" && browserName != "Edge" && browserName != "Firefox" && !localStorage.getItem('VISITED'))
      setshowAlert(true)
  }, [browserName])
  const onAlertClose =()=>{
    localStorage.setItem('VISITED', true);
  }
    return (
        <>
            {showAlert && <div class="alert alert-warning alert-dismissible p-2 mb-0 border-0 rounded-0 text-center">
                <button type="button" onClick={onAlertClose} class="close pt-2 bold-label" data-dismiss="alert">&times;</button>
                <strong className="bold-label">Finsights</strong> is optimised for <span className="status-font">Chrome</span> and <span className="status-font">Edge</span>. For best experience, please open the app on <span className="status-font">Google Chrome</span>
            </div>}
        </>
    );
};

export default Disclaimar;