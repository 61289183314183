import { 
    GET_CURRENT_ASSET_VALUE,
    GET_CURRENT_ASSET_VALUE_SUCCESS,
    GET_CURRENT_LIABILITIES_VALUE,
    GET_CURRENT_LIABILITIES_VALUE_SUCCESS,
    GET_FIXED_ASSET_VALUE,
    GET_FIXED_ASSET_VALUE_SUCCESS,
    GET_PROFIT_AND_LOSS,
    GET_PROFIT_AND_LOSS_SUCCESS,
    GET_PURCHASE_VALUE,
    GET_PURCHASE_VALUE_SUCCESS,
    GET_SALES_VALUE,
    GET_SALES_VALUE_SUCCESS
   } from "src/actions/actionTypes";
import { getList } from "src/util/enums";
  
const initialState = {
    profitAndLoss: {},
    salesValue: {},
    purchaseValue: {},
    fixedAssetValue: {},
    currentAssetValue: {},
    currentLiabilities: {}
};
  
  export default function dashBoardReducer(state = initialState, action) {
    switch (action.type) {
      case GET_PROFIT_AND_LOSS_SUCCESS: {
        return { ...state, profitAndLoss: action.payload };
      }
      case GET_PURCHASE_VALUE_SUCCESS: {
        return { ...state, purchaseValue: action.payload };
      }
      case GET_SALES_VALUE_SUCCESS: {
        return { ...state, salesValue: action.payload };
      }
      case GET_FIXED_ASSET_VALUE_SUCCESS: {
        return { ...state, fixedAssetValue: action.payload };
      }
      case GET_CURRENT_ASSET_VALUE_SUCCESS: {
        return { ...state, currentAssetValue: action.payload };
      }
      case GET_CURRENT_LIABILITIES_VALUE_SUCCESS: {
        return { ...state, currentLiabilities: action.payload };
      }
      case GET_PROFIT_AND_LOSS: {
        return { ...state, profitAndLoss: getList.inprogress };
      }
      case GET_PURCHASE_VALUE: {
        return { ...state, purchaseValue: getList.inprogress };
      }
      case GET_SALES_VALUE: {
        return { ...state, salesValue: getList.inprogress };
      }
      case GET_FIXED_ASSET_VALUE: {
        return { ...state, fixedAssetValue: getList.inprogress };
      }
      case GET_CURRENT_ASSET_VALUE: {
        return { ...state, currentAssetValue: getList.inprogress };
      }
      case GET_CURRENT_LIABILITIES_VALUE: {
        return { ...state, currentLiabilities: getList.inprogress };
      }
      default:
        return state;
    }
  }
  