import {
  FETCH_AGAINST_LEDGER_GROUPING_BY_DATE_SUCCESS,
  FETCH_AGAINST_LEDGER_GROUPING_BY_MONTH_SUCCESS,
  FETCH_AGAINST_LEDGER_GROUPING_BY_TRANSACTION_TYPE_SUCCESS,
  FETCH_AGAINST_LEDGER_GROUPING_DATA_SUCCESS,
  FETCH_ALL_LEDGER_TRANSACTIONS_BETWEEN_DATES_SUCCESS,
  FETCH_ALL_LEDGER_TRANSACTIONS_BY_TRANSACTION_TYPE_AND_BETWEEN_DATES_SUCCESS,
  FETCH_ALL_LEDGER_TRANSACTIONS_BY_VOUCHER_ID_SUCCESS,
  FETCH_ALL_LEDGER_TRANSACTIONS_SUCCESS,
  GET_LEDGERS_LIST_SUCCESS,
  TOGGLE_LEDGER_TRANSACTION_AGGR_CHART,
  TOP_PAYABLES_LIST_SUCCESS,
  TOP_RECEIVABLES_LIST_SUCCESS,
  GET_LEDGERS_LIST_REPORT_SUCCESS,
  GET_LEDGERS_BY_TYPES,
  GET_LEDGERS_BY_TYPES_SUCCESS,
  GET_LEDGERS_BY_ID,
  GET_LEDGERS_BY_ID_SUCCESS,
  GET_TRANSACTIONS_BY_LEDGER_ID_SUCCESS,
  GET_LEDGERS_BY_USERS_INPROGESS,
  GET_LEDGERS_BY_USERS_SUCCESS,
  GET_UNDERGROUP_LIST_SUCCESS,
  GET_UNDERGROUP_LIST_FAILURE,
  CREATE_LEDGER_SUCCESS,
  CREATE_LEDGER_ERROR,
  CLEAR_CREATE_LEDGER_RESP,
  GET_LEDGER_MAILING_DETAILS_SUCCESS,
  GET_LEDGER_MAILING_DETAILS_FAILURE,
  GET_LEDGER_BANK_ACC_DETAILS_SUCCESS,
  GET_LEDGER_BANK_ACC_DETAILS_FAILURE,
  GET_LEDGER_STATUTORY_DETAILS_SUCCESS,
  GET_LEDGER_STATUTORY_DETAILS_FAILURE,
  GET_LEDGER_PARTY_DETAILS_SUCCESS,
  GET_LEDGER_PARTY_DETAILS_FAILURE,
  GET_LEDGER_OTHER_DETAILS_SUCCESS,
  GET_LEDGER_OTHER_DETAILS_FAILURE,
  GET_TRANSACTIONS_BY_LEDGER_ID,
  GET_MONTHLY_AGGREGATE_VALUES_BY_LEDGER_ID,
  GET_MONTHLY_AGGREGATE_VALUES_BY_LEDGER_ID_SUCCESS,
  GET_MONTHLY_CLOSING_BALANCE_BY_ID_SUCCESS,
  GET_MONTHLY_CLOSING_BALANCE_BY_ID,
  GET_BILLS_DETAILS_BY_LEDGER_ID_SUCCESS,
  CHECK_BILL_WISE_CONDITION,
  CHECK_BILL_WISE_CONDITION_SUCCESS,
  GET_ADD_DETAILS_BY_LEGER,
  GET_ADD_DETAILS_BY_LEGER_SUCCESS,
  GET_AGGER_TRANSACTION_LIST,
  GET_AGGER_TRANSACTION_LIST_SUCCESS,
  GET_LEDGERS_BY_GROUP_ID_SUCCESS,
  ADD_BUDGET_INIT,
  ADD_BUDGET_FINISH,
  ADD_BUDGET_SUCCESS,
  ADD_BUDGET_FAILED,
  GET_BUDGET_INIT,
  GET_BUDGET_FINISH,
  GET_BUDGET_SUCCESS,
  GET_BUDGET_FAILED,
  EDIT_BUDGET_INIT,
  EDIT_BUDGET_FINISH,
  EDIT_BUDGET_SUCCESS,
  EDIT_BUDGET_FAILED,
  OPEN_MODAL,
  CLOSE_MODAL,
  CLEAR_BUDGET_STATE,
  GET_LEDGER_VARIANCE_INIT,
  GET_LEDGER_VARIANCE_FINISH,
  GET_LEDGER_VARIANCE_SUCCESS,
  GET_LEDGER_VARIANCE_FAILED,
  LEDGER_BUDGET_DATE_RANGE,
  LEDGER_VARIANCE_FROM_DATE,
  LEDGER_VARIANCE_TO_DATE,
  LEDGER_VARIANCE_OLDEST_DATE,
  CLEAR_LEDGER_VARIANCE_STATE,
  VARIANCE_LOADER_INIT,
  VARIANCE_LOADER_FINISH
} from "src/actions/actionTypes";
import { getList } from "src/util/enums";

const inititalState = {
  allLedgerTransactions: [],
  againstLedgerGroupingData: [],
  againstLedgerGroupingByTransactionTypeData: [],
  againstLedgerGroupingByMonthData: [],
  againstLedgerGroupingByDateData: [],
  ledgerTransactions: [],
  expandedLedgerTransactions: {},
  toggleChart: {
    ledgerTransactionAggrByTranType: false,
  },
  ledgersList: [],
  topReceivablesList: [],
  topPayablesList: [],
  ledgerReportsList: [],
  ledgerListByTypes: [],
  ledgerData: [],
  tansactionListByLedgerId: [],
  ledgerListByUsers: [],
  underGroupList: {},
  createLedgerResp: {},
  ledgerListByUsers: [],
  underGroupList: {},
  createLedgerResp: {},
  ledgerMailingDetails: {},
  ledgerBankAccgDetails: {},
  ledgerStatutorygDetails: {},
  ledgerPartyDetails: {},
  ledgerOtherDetails: {},
  monthlyAggregateValues: null,
  monthlyClosingBalances: null,
  billDetailsByLedgerId: null,
  isBillWiseApplicable: null,
  suppDataByLedger: null,
  ledgerAggerTransactions: null,
  ledgerListByGroupId: [],
  addBudgetReqStatus: false,
  addBudgetStatus: null,
  addBudgetData: null,
  addBudgetMessage: null,
  
  editBudgetReqStatus: false,
  editBudgetStatus: null,
  editBudgetData: null,
  editBudgetMessage: null,
  
  getBudgetReqStatus: false,
  getBudgetStatus: null,
  getBudgetData: null,
  getBudgetMessage: null,


  getLedgerVarianceReqStatus: false,
  getLedgerVarianceStatus: null,
  getLedgerVarianceData: null,
  getLedgerVarianceMessage: null,
  ledgerBudgetDateRange: null,

  ledgerVarianceFromDate: null,
  ledgerVarianceToDate: null,
  ledgerVarianceOldestDate: null,

  varianceLoader: false,

  modalStaus: false,
};

export function ledger(state = inititalState, action) {
  switch (action.type) {
    case FETCH_ALL_LEDGER_TRANSACTIONS_SUCCESS: {
      return { ...state, allLedgerTransactions: action.payload };
    }
    case FETCH_AGAINST_LEDGER_GROUPING_DATA_SUCCESS: {
      return { ...state, againstLedgerGroupingData: action.payload };
    }
    case FETCH_AGAINST_LEDGER_GROUPING_BY_TRANSACTION_TYPE_SUCCESS: {
      return {
        ...state,
        againstLedgerGroupingByTransactionTypeData: action.payload,
      };
    }
    case FETCH_AGAINST_LEDGER_GROUPING_BY_MONTH_SUCCESS: {
      return {
        ...state,
        againstLedgerGroupingByMonthData: action.payload,
      };
    }
    case FETCH_AGAINST_LEDGER_GROUPING_BY_DATE_SUCCESS: {
      return {
        ...state,
        againstLedgerGroupingByDateData: action.payload,
      };
    }
    case FETCH_ALL_LEDGER_TRANSACTIONS_BETWEEN_DATES_SUCCESS: {
      return {
        ...state,
        ledgerTransactions: action.payload,
      };
    }
    case FETCH_ALL_LEDGER_TRANSACTIONS_BY_VOUCHER_ID_SUCCESS: {
      return {
        ...state,
        expandedLedgerTransactions: action.payload,
      };
    }
    case FETCH_ALL_LEDGER_TRANSACTIONS_BY_TRANSACTION_TYPE_AND_BETWEEN_DATES_SUCCESS: {
      return { ...state, againstLedgerGroupingData: action.payload };
    }
    case TOGGLE_LEDGER_TRANSACTION_AGGR_CHART: {
      return {
        ...state,
        toggleChart: {
          ...state.toggleChart,
          ledgerTransactionAggrByTranType: action.payload,
        },
      };
    }
    case GET_LEDGERS_LIST_SUCCESS: {
      return {
        ...state,
        ledgersList: action.payload,
      };
    }
    case TOP_RECEIVABLES_LIST_SUCCESS: {
      return {
        ...state,
        topReceivablesList: action.payload,
      };
    }
    case TOP_PAYABLES_LIST_SUCCESS: {
      return {
        ...state,
        topPayablesList: action.payload,
      };
    };
    case GET_LEDGERS_LIST_REPORT_SUCCESS: {
      return { ...state, ledgerReportsList: action.payload }
    };
    case GET_LEDGERS_BY_TYPES: {
      return { ...state, ledgerListByTypes: getList.inprogress }
    }
    case GET_LEDGERS_BY_TYPES_SUCCESS: {
      return { ...state, ledgerListByTypes: action.payload }
    }
    case GET_LEDGERS_BY_ID: {
      return { ...state, ledgerData: getList.inprogress }
    }
    case GET_LEDGERS_BY_ID_SUCCESS: {
      return { ...state, ledgerData: action.payload }
    }
    case GET_TRANSACTIONS_BY_LEDGER_ID: {
      return { ...state, tansactionListByLedgerId: getList.inprogress }
    }
    case GET_TRANSACTIONS_BY_LEDGER_ID_SUCCESS: {
      return { ...state, tansactionListByLedgerId: action.payload }
    }
    case GET_LEDGERS_BY_USERS_INPROGESS: {
      return { ...state, ledgerListByUsers: getList.inprogress }
    }
    case GET_LEDGERS_BY_USERS_SUCCESS: {
      return { ...state, ledgerListByUsers: action.payload }
    }
    case CREATE_LEDGER_SUCCESS: {
      return { ...state, createLedgerResp: action.payload }
    }
    case CREATE_LEDGER_ERROR: {
      return { ...state, createLedgerResp: action.payload }
    }
    case CLEAR_CREATE_LEDGER_RESP: {
      return { ...state, createLedgerResp: inititalState.createLedgerResp }
    }

    case GET_UNDERGROUP_LIST_SUCCESS: {
      return { ...state, underGroupList: action.payload }
    }
    case GET_UNDERGROUP_LIST_FAILURE: {
      return { ...state, underGroupList: action.payload }
    }

    case GET_LEDGER_MAILING_DETAILS_SUCCESS: {
      return { ...state, ledgerMailingDetails: action.payload }
    }
    case GET_LEDGER_MAILING_DETAILS_FAILURE: {
      return { ...state, ledgerMailingDetails: action.payload }
    }
    case GET_LEDGER_BANK_ACC_DETAILS_SUCCESS: {
      return { ...state, ledgerBankAccgDetails: action.payload }
    }
    case GET_LEDGER_BANK_ACC_DETAILS_FAILURE: {
      return { ...state, ledgerBankAccgDetails: action.payload }
    }
    case GET_LEDGER_STATUTORY_DETAILS_SUCCESS: {
      return { ...state, ledgerStatutorygDetails: action.payload }
    }
    case GET_LEDGER_STATUTORY_DETAILS_FAILURE: {
      return { ...state, ledgerStatutorygDetails: action.payload }
    }
    case GET_LEDGER_PARTY_DETAILS_SUCCESS: {
      return { ...state, ledgerPartyDetails: action.payload }
    }
    case GET_LEDGER_PARTY_DETAILS_FAILURE: {
      return { ...state, ledgerPartyDetails: action.payload }
    }
    case GET_LEDGER_OTHER_DETAILS_SUCCESS: {
      return { ...state, ledgerOtherDetails: action.payload }
    }
    case GET_LEDGER_OTHER_DETAILS_FAILURE: {
      return { ...state, ledgerOtherDetails: action.payload }
    }
    case GET_MONTHLY_AGGREGATE_VALUES_BY_LEDGER_ID: {
      return { ...state, monthlyAggregateValues: getList.inprogress }
    }
    case GET_MONTHLY_AGGREGATE_VALUES_BY_LEDGER_ID_SUCCESS: {
      return { ...state, monthlyAggregateValues: action.payload }
    }
    case GET_MONTHLY_CLOSING_BALANCE_BY_ID: {
      return { ...state, monthlyClosingBalances: getList.inprogress }
    }
    case GET_MONTHLY_CLOSING_BALANCE_BY_ID_SUCCESS: {
      return { ...state, monthlyClosingBalances: action.payload }
    }
    case GET_BILLS_DETAILS_BY_LEDGER_ID_SUCCESS: {
      return { ...state, billDetailsByLedgerId: action.payload }
    }
    case CHECK_BILL_WISE_CONDITION: {
      return { ...state, isBillWiseApplicable: null }
    }
    case CHECK_BILL_WISE_CONDITION_SUCCESS: {
      return { ...state, isBillWiseApplicable: action.payload }
    }
    case GET_ADD_DETAILS_BY_LEGER: {
      return { ...state, suppDataByLedger: null }
    }
    case GET_ADD_DETAILS_BY_LEGER_SUCCESS: {
      return { ...state, suppDataByLedger: action.payload }
    }
    case GET_AGGER_TRANSACTION_LIST: {
      return { ...state, ledgerAggerTransactions: null }
    }
    case GET_AGGER_TRANSACTION_LIST_SUCCESS: {
      return { ...state, ledgerAggerTransactions: action.payload }
    }
    case GET_LEDGERS_BY_GROUP_ID_SUCCESS: {
      return { ...state, ledgerListByGroupId: action.payload }
    }

    case ADD_BUDGET_INIT: {
      return {...state, addBudgetReqStatus: true, addBudgetStatus: null}
    }
    case ADD_BUDGET_FINISH: {
      return {...state, addBudgetReqStatus: false}
    }
    case ADD_BUDGET_SUCCESS: {
      return {...state, addBudgetData: action.payload[0], addBudgetStatus: action.payload[1], addBudgetMessage: action.payload[2] }
    }
    case ADD_BUDGET_FAILED: {
      return {...state, addBudgetStatus: action.payload[0], addBudgetMessage: action.payload[1]}
    }
    case EDIT_BUDGET_INIT: {
      return {...state, editBudgetReqStatus: true, editBudgetStatus: null, getBudgetStatus: null, addBudgetStatus: null}
    }
    case EDIT_BUDGET_FINISH: {
      return {...state, editBudgetReqStatus: false}
    }
    case EDIT_BUDGET_SUCCESS: {
      return {...state, editBudgetData: action.payload[0], editBudgetStatus: action.payload[1], editBudgetMessage: action.payload[2] }
    }
    case EDIT_BUDGET_FAILED: {
      return {...state, editBudgetStatus: action.payload[0], editBudgetMessage: action.payload[1]}
    }
    
    case GET_BUDGET_INIT: {
      return {...state, getBudgetReqStatus: true, getBudgetStatus: null}
    }
    case GET_BUDGET_FINISH: {
      return {...state, getBudgetReqStatus: false}
    }
    case GET_BUDGET_SUCCESS: {
      return {...state, getBudgetData: action.payload[0], getBudgetStatus: action.payload[1], getBudgetMessage: action.payload[2] }
    }
    case GET_BUDGET_FAILED: {
      return {...state, getBudgetStatus: action.payload[0], getBudgetMessage: action.payload[1]}
    }




    case GET_LEDGER_VARIANCE_INIT: {
      return {...state, getLedgerVarianceReqStatus: true, getLedgerVarianceStatus: null}
    }
    case GET_LEDGER_VARIANCE_FINISH: {
      return {...state, getLedgerVarianceReqStatus: false}
    }
    case GET_LEDGER_VARIANCE_SUCCESS: {
      return {...state, getLedgerVarianceData: action.payload[0], getLedgerVarianceStatus: action.payload[1], getLedgerVarianceMessage: action.payload[2] }
    }
    case GET_LEDGER_VARIANCE_FAILED: {
      return {...state, getLedgerVarianceStatus: action.payload[0], getLedgerVarianceMessage: action.payload[1]}
    }

    case LEDGER_BUDGET_DATE_RANGE: {
      return {...state, ledgerBudgetDateRange: action.payload}
    }

    case LEDGER_VARIANCE_FROM_DATE: {
      return {...state, ledgerVarianceFromDate: action.payload}
    }
    
    case LEDGER_VARIANCE_TO_DATE: {
      return {...state, ledgerVarianceToDate: action.payload}
    }

    case LEDGER_VARIANCE_OLDEST_DATE: {
      return {...state, ledgerVarianceOldestDate: action.payload}
    }

    case VARIANCE_LOADER_INIT: {
      return {...state, varianceLoader: true}
    }

    case VARIANCE_LOADER_FINISH: {
      return {...state, varianceLoader: false}
    }

    case OPEN_MODAL: {
      return {...state, modalStaus: true}
    }

    case CLOSE_MODAL: {
      return {...state, modalStaus: false}
    }

    case CLEAR_BUDGET_STATE: {
      return {...state,  
        addBudgetReqStatus: false,
        addBudgetStatus: null,
        addBudgetData: null,
        addBudgetMessage: null,        
        editBudgetReqStatus: false,
        editBudgetStatus: null,
        editBudgetData: null,
        editBudgetMessage: null,        
        // getBudgetReqStatus: false,
        getBudgetStatus: null,
        getBudgetData: null,
        getBudgetMessage: null,
      }
    }

    case CLEAR_LEDGER_VARIANCE_STATE: {
      return {...state,  
        getLedgerVarianceReqStatus: false,
        getLedgerVarianceStatus: null,
        getLedgerVarianceData: null,
        getLedgerVarianceMessage: null,
        ledgerBudgetDateRange: null,      
        ledgerVarianceFromDate: null,
        ledgerVarianceToDate: null,
        ledgerVarianceOldestDate: null,
      }
    }

    default:
      return state;
  }
}
