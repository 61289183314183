import {
    GET_PURCHASE_INVOICE_LIST_SUCCESS,
    GET_INVOICE_LIST_BY_TYPE_SUCCESS,
    GET_PURCHASE_GROUP_LIST_SUCCESS,
    GET_PURCHASE_AS_VOUCHER_DATA_SUCCESS,
    CREATE_PURCHASE_SUCCESS,
    CREATE_PURCHASE_INIT,
    CREATE_PURCHASE_DONE,
    GET_PURCHASE_GROUP_LIST_INIT
   } from "src/actions/actionTypes";
import { InsertFunctionStatus } from "src/util/enums";

   
   const initialState = {
     purchaseInvoiceList:[],
     invoiceListByType:[],
     purchaseGroupList:[],
     purchaseAsVoucherData:{},
     purchseSaveStatus: InsertFunctionStatus.NONE
   };
   export default function purchaseReducer(state = initialState, action) {
     switch (action.type) {
       case GET_PURCHASE_INVOICE_LIST_SUCCESS: {
         return { ...state, purchaseInvoiceList: action.payload };
       }
       case GET_INVOICE_LIST_BY_TYPE_SUCCESS: {
        return { ...state, invoiceListByType: action.payload };
      }
      case GET_PURCHASE_GROUP_LIST_INIT: {
        return { ...state, purchaseGroupList: [] }
      }
      case GET_PURCHASE_GROUP_LIST_SUCCESS: {
        return { ...state, purchaseGroupList: action.payload };
      } 
      case GET_PURCHASE_AS_VOUCHER_DATA_SUCCESS: {
        return { ...state, purchaseAsVoucherData: action.payload };
      }
      case CREATE_PURCHASE_SUCCESS: {
        return { ...state, purchseSaveStatus: InsertFunctionStatus.SUCCESS };
      }
      case CREATE_PURCHASE_INIT: {
        return { ...state, purchseSaveStatus: InsertFunctionStatus.NONE };
      }
      case CREATE_PURCHASE_DONE: {
        return { ...state, purchseSaveStatus: InsertFunctionStatus.DONE };
      }
       default:
         return state;
     }
   }
   