import {
    SAVE_USER,
    SAVE_USER_SUCCESS,
    SAVE_USER_FAILURE,
    SAVE_USER_DEFAULT_STATUS,
    LOGGEDIN_USERS_ROLE,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAILURE,
    CLEAR_LOGIN_RESP_DATA,
    GET_ORGANISATION_BY_ORG_ID,
    GET_ORGANISATION_BY_ORG_ID_SUCCESS,
    GET_ORGANISATION_BY_ORG_ID_DEFAULT_STATUS,
    SAVE_ORGANISATION,
    SAVE_ORGANISATION_SUCCESS,
    SAVE_ORGANISATION_DEFAULT_STATUS,
    GET_ORGANISATION_BY_ORG_OWNER_ID,
    GET_ORGANISATION_BY_ORG_OWNER_ID_SUCCESS,
    UPDATE_ORGANISATION,
    UPDATE_ORGANISATION_SUCCESS,
    UPDATE_ORGANISATION_DEFAULT_STATUS,

    EMAIL_TOKEN_SUCCESS,
    EMAIL_TOKEN_FAILURE,
    CLEAR_LOGIN_TOKEN,

    REQ_RESET_EMAIL,
    REQ_RESET_EMAIL_SUCCESS,
    REQ_RESET_EMAIL_ERROR,

    CONFIRM_PASSWORD_SUCCESS,
    CONFIRM_PASSWORD_ERROR,

    USER_REGISTER,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_ERROR,

    USER_EMAIl_EXISTS_SUCCESS,
    USER_EMAIl_EXISTS_ERROR,

    CLEAR_EMAIL_EXIST_RESP,

    GET_PROFILES_SUCCESS,
    GET_ROLES_SUCCESS,
    ASSIGN_USERS,
    ASSIGN_USERS_SUCCESS,
    ASSIGN_USERS_ERROR,
    CLEAR_AFER_LOGIN,

    GET_COMPANY_PROFILE_INFO_SUCCESS,
    UPDATE_PASSWORD,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_ERROR,

    INIT_USER_PROFILE_DATA_SUCCESS,
    INIT_USER_PROFILE_DATA_ERROR,

    UPDATE_USER_PROFILE_DATA_SUCCESS,
    UPDATE_USER_PROFILE_DATA_ERROR,

    GET_INVITED_USERS_SUCCESS,
    GET_INVITED_USERS_ERROR,

    GET_MY_TALLY_COMPANIES,
    GET_MY_TALLY_COMPANIES_SUCCESS,
    GET_ALL_USERS_BY_COMPANY_ID_SUCCESS,
    USER_VERIFICATION_SUCCESS,
    REGISTER_INVITEE_SUCCESS,
    SAVE_SOCIAL_AUTH_DATA_SUCCESS,
    SET_NOTIFICATION_CONTENT,
    RESET_NOTIFICATION_CONTENT,
    GET_ALL_NATIFICATIONS_BY_ID,
    GET_ALL_NATIFICATIONS_BY_ID_SUCCESS,
    RESEND_INVITATION_LINK_SUCCESS,
    RESET_SOCIAL_AUTH_DATA,
    VERIFY_RESET_PASSWORD_SUCCESS,
    SUBMIT_RESET_PASSWORD_DATA_SUCCESS,
    UPDATE_ASSIGN_USERS_SUCCESS,
    DELETE_ASSIGN_USERS_SUCCESS,
    DELETE_ASSIGN_USERS,
    UPDATE_ASSIGN_USERS,
    SET_ACTIVATION_PENDING_DATA_SUCCESS,
    GET_MY_TALLY_COMPANIES_NOTIFI,
    GET_MY_TALLY_COMPANIES_NOTIFI_SUCCESS,
    GET_MY_TALLY_COMPANIES_NOTIFI_DEFAULT,
    GET_NEW_NOTIFICATION_COUNT_SUCCESS,
    CHANGE_NOTIFICATION_STATUS_SUCCESS,
    RESEND_VERIFICATION_LINK_SUCCESS,
    GET_MY_TALLY_COMPANIES_AFTER_SYNC,
    GET_MY_TALLY_COMPANIES_AFTER_SYNC_SUCCESS,
    GET_MY_TALLY_COMPANIES_AFTER_SYNC_DEFAULT,
    APPLE_SIGN_IN_FAILED,
    ADD_COMPANY_LOGO,
    ADD_COMPANY_LOGO_SUCCESS,
    ADD_COMPANY_LOGO_FAILURE,
    ADD_COMPANY_LOGO_DONE,
    GET_COMPANY_PROFILE_INFO,
    GET_INVITED_USERS,
    DELETE_TALLY_COMPANY,
    DELETE_TALLY_COMPANY_SUCCESS,
    COMPANY_LIST_UPDATED,
    UPDATE_SOCIA_AUTH_USER_MOBILE_NUM,
    UPDATE_SOCIA_AUTH_USER_MOBILE_NUM_SUCCESS,
    CHECK_MOBILE_NO_VERIFIED_SUCCESS,
    CHECK_MOBILE_NO_VERIFIED,
    OTP_VERIFICATION_SUCCESS,
    OTP_VERIFICATION,
    SUBMIT_OTP_SUCCESS,
    SUBMIT_OTP,
    CHECK_WHATSAPP_STATUS,
    CHECK_WHATSAPP_STATUS_SUCCESS,
    OTP_VERIFICATION_RESET,
    SUBMIT_OTP_RESET,
    UPDATE_USER_PROFILE_DATA,


} from "src/actions/actionTypes";
import { getList, InsertFunctionStatus, loginInprogressStatus } from "src/util/enums";

const initialState = {
    userRegistered: InsertFunctionStatus.NONE,
    users: [],
    loginUserData: null,
    loginUserDataNew: {},
    verifyEmailResp: {},
    organaisationData: {},
    orgSaveStatus: InsertFunctionStatus.NONE,
    orgUpdateStatus: InsertFunctionStatus.NONE,
    organisationsList: [],
    reqResetEmailResp: {},
    confirmPasswordResp: {},
    userRegistrationResp: {},
    userEmailExistsResp: {},
    profileList: [],
    roleList: [],
    assignUserResp: {},
    companyProfileInfo: null,
    updatePasswordResp: {},
    initProfileResp: {},
    updateUserProfileResp: {},
    getInvitedUserData: null,
    getMyTallyCompanies: "",
    getMyTallyCompaniesForNotification: null,
    userListByCompanyId: [],
    bookStartDateEndDate: [],
    inviteeStatus: {},
    responceAfterInvite: {},
    saveSocialAuthStatus: InsertFunctionStatus.NONE,
    notificationContent: null,
    allUserNotifications: null,
    resendInvitationLinkData: null,
    verifyRestPassword: null,
    submitResetPassResp: null,
    updateUserResponce: null,
    deleteUserResponce: null,
    verificationPendingData: null,
    newNotificationCount: null,
    notificationStatus: null,
    resendVerificatinLinkResp: null,
    getMyTallyCompaniesAfterSync: null,
    appleLoginFailed: null,
    appleLoginFailedMsg: null,
    logoUpdated: InsertFunctionStatus.NONE,
    companyDeleteStatus: null,
    mobileNumUpdateStatus: null,
    mobileNoVerificationStatus: null,
    otpVerificationData: null,
    otpSubmitResponce: null,
    whatsAppStatus: null,
    loggedInUserRole: null,
};

export default function registration(state = initialState, action) {
    switch (action.type) {
        case SAVE_USER: {
            return { ...state, userRegistered: InsertFunctionStatus.INPROGRESS };
        }
        case SAVE_USER_SUCCESS: {
            return { ...state, userRegistered: InsertFunctionStatus.SUCCESS };
        }
        case SAVE_USER_FAILURE: {
            return { ...state, userRegistered: InsertFunctionStatus.FAILED };
        }
        case SAVE_USER_DEFAULT_STATUS: {
            return { ...state, userRegistered: InsertFunctionStatus.NONE };

        } case LOGGEDIN_USERS_ROLE: {
            return { ...state, loggedInUserRole: action.payload };
        }

        case USER_LOGIN_SUCCESS: {
            return { ...state, loginUserData: action.payload };
        }
        case USER_LOGIN_FAILURE: {
            return { ...state, loginUserData: action.payload };
        }
        case CLEAR_LOGIN_RESP_DATA: {
            return { ...state, loginUserData: null };
        }
        case EMAIL_TOKEN_SUCCESS: {
            return { ...state, verifyEmailResp: action.payload };
        } case EMAIL_TOKEN_FAILURE: {
            return { ...state, verifyEmailResp: action.payload };
        } case CLEAR_LOGIN_TOKEN: {
            return { ...state, verifyEmailResp: initialState };
        }
        case REQ_RESET_EMAIL: {
            return { ...state, reqResetEmailResp: null };
        }
        case REQ_RESET_EMAIL_SUCCESS: {
            return { ...state, reqResetEmailResp: action.payload };
        }
        case REQ_RESET_EMAIL_ERROR: {
            return { ...state, reqResetEmailResp: action.payload };
        }
        case CONFIRM_PASSWORD_SUCCESS: {
            return { ...state, confirmPasswordResp: action.payload };
        }
        case CONFIRM_PASSWORD_ERROR: {
            return { ...state, confirmPasswordResp: action.payload };
        }
        case USER_REGISTER: {
            return { ...state, userRegistrationResp: {} };
        }
        case USER_REGISTER_SUCCESS: {
            return { ...state, userRegistrationResp: action.payload };
        }
        case USER_REGISTER_ERROR: {
            return { ...state, userRegistrationResp: action.payload };
        }
        case USER_EMAIl_EXISTS_SUCCESS: {
            return { ...state, userEmailExistsResp: action.payload };
        }
        case USER_EMAIl_EXISTS_ERROR: {
            return { ...state, userEmailExistsResp: action.payload };
        }
        case CLEAR_EMAIL_EXIST_RESP: {
            return { ...state, userEmailExistsResp: initialState };
        }
        case GET_PROFILES_SUCCESS: {
            return { ...state, profileList: action.payload };
        }
        case GET_ROLES_SUCCESS: {
            return { ...state, roleList: action.payload };
        }
        case ASSIGN_USERS: {
            return { ...state, assignUserResp: null };
        }
        case ASSIGN_USERS_SUCCESS: {
            return { ...state, assignUserResp: action.payload };
        }
        case ASSIGN_USERS_ERROR: {
            return { ...state, assignUserResp: action.payload };
        }
        case CLEAR_AFER_LOGIN: {
            return { ...state, loginUserData: action.payload };
        }
        // case USER_LOGIN_FAILURE: {
        //     return { ...state, loginUserData: action.payload };
        // }
        case GET_ORGANISATION_BY_ORG_ID_DEFAULT_STATUS: {
            return { ...state, organaisationData: InsertFunctionStatus.NONE };
        }
        case GET_ORGANISATION_BY_ORG_ID: {
            return { ...state, organaisationData: loginInprogressStatus };
        }
        case GET_ORGANISATION_BY_ORG_ID_SUCCESS: {
            return { ...state, organaisationData: action.payload };
        }
        case SAVE_ORGANISATION: {
            return { ...state, orgSaveStatus: InsertFunctionStatus.INPROGRESS };
        }
        case SAVE_ORGANISATION_SUCCESS: {
            return { ...state, orgSaveStatus: InsertFunctionStatus.SUCCESS };
        }
        case SAVE_ORGANISATION_DEFAULT_STATUS: {
            return { ...state, orgSaveStatus: InsertFunctionStatus.NONE };
        }
        case GET_ORGANISATION_BY_ORG_OWNER_ID: {
            return { ...state, organisationsList: getList.inprogress };
        }
        case GET_ORGANISATION_BY_ORG_OWNER_ID_SUCCESS: {
            return { ...state, organisationsList: action.payload };
        }
        case UPDATE_ORGANISATION: {
            return { ...state, orgUpdateStatus: InsertFunctionStatus.INPROGRESS };
        }
        case UPDATE_ORGANISATION_SUCCESS: {
            return { ...state, orgUpdateStatus: InsertFunctionStatus.SUCCESS };
        }
        case UPDATE_ORGANISATION_DEFAULT_STATUS: {
            return { ...state, orgUpdateStatus: InsertFunctionStatus.NONE };
        }
        case GET_COMPANY_PROFILE_INFO: {
            return { ...state, companyProfileInfo: null }
        }
        case GET_COMPANY_PROFILE_INFO_SUCCESS: {
            return { ...state, companyProfileInfo: action.payload }
        }
        // case GET_COMPANY_PROFILE_INFO_ERROR: {
        //     return { ...state, companyProfileInfo: action.payload }
        // }
        case UPDATE_PASSWORD: {
            return { ...state, updatePasswordResp: null }
        }
        case UPDATE_PASSWORD_SUCCESS: {
            return { ...state, updatePasswordResp: action.payload }
        }
        case UPDATE_PASSWORD_ERROR: {
            return { ...state, updatePasswordResp: action.payload }
        }
        case INIT_USER_PROFILE_DATA_SUCCESS: {
            return { ...state, initProfileResp: action.payload }
        }
        case INIT_USER_PROFILE_DATA_ERROR: {
            return { ...state, initProfileResp: action.payload }
        }
        case UPDATE_USER_PROFILE_DATA: {
            return { ...state, updateUserProfileResp: null }
        }
        case UPDATE_USER_PROFILE_DATA_SUCCESS: {
            return { ...state, updateUserProfileResp: action.payload }
        }
        case UPDATE_USER_PROFILE_DATA_ERROR: {
            return { ...state, updateUserProfileResp: action.payload }
        }
        case GET_INVITED_USERS: {
            return { ...state, getInvitedUserData: null }
        }
        case GET_INVITED_USERS_SUCCESS: {
            return { ...state, getInvitedUserData: action.payload }
        }
        case GET_INVITED_USERS_ERROR: {
            return { ...state, getInvitedUserData: action.payload }
        }
        case GET_MY_TALLY_COMPANIES: {
            return { ...state, getMyTallyCompanies: getList.inprogress }
        }
        case GET_MY_TALLY_COMPANIES_SUCCESS: {
            return { ...state, getMyTallyCompanies: action.payload }
        }
        case GET_MY_TALLY_COMPANIES_NOTIFI: {
            return { ...state, getMyTallyCompaniesForNotification: getList.inprogress }
        }
        case GET_MY_TALLY_COMPANIES_NOTIFI_SUCCESS: {
            return { ...state, getMyTallyCompaniesForNotification: action.payload }
        }
        case GET_MY_TALLY_COMPANIES_NOTIFI_DEFAULT: {
            return { ...state, getMyTallyCompaniesForNotification: null }
        }
        case GET_MY_TALLY_COMPANIES_AFTER_SYNC: {
            return { ...state, getMyTallyCompaniesAfterSync: getList.inprogress }
        }
        case GET_MY_TALLY_COMPANIES_AFTER_SYNC_SUCCESS: {
            return { ...state, getMyTallyCompaniesAfterSync: action.payload }
        }
        case GET_MY_TALLY_COMPANIES_AFTER_SYNC_DEFAULT: {
            return { ...state, getMyTallyCompaniesAfterSync: null }
        }
        case GET_ALL_USERS_BY_COMPANY_ID_SUCCESS: {
            return { ...state, userListByCompanyId: action.payload }
        }
        case REGISTER_INVITEE_SUCCESS: {
            return { ...state, responceAfterInvite: action.payload }
        }
        case USER_VERIFICATION_SUCCESS: {
            return { ...state, inviteeStatus: action.payload };
        }
        case RESET_SOCIAL_AUTH_DATA: {
            return { ...state, saveSocialAuthStatus: action.payload };
        }
        case SAVE_SOCIAL_AUTH_DATA_SUCCESS: {
            return { ...state, saveSocialAuthStatus: action.payload };
        }
        case SET_NOTIFICATION_CONTENT: {
            return { ...state, notificationContent: action.payload };
        }
        case RESET_NOTIFICATION_CONTENT: {
            return { ...state, notificationContent: null };
        }
        case GET_ALL_NATIFICATIONS_BY_ID: {
            return { ...state, allUserNotifications: null };
        }
        case GET_ALL_NATIFICATIONS_BY_ID_SUCCESS: {
            return { ...state, allUserNotifications: action.payload };
        }
        case RESEND_INVITATION_LINK_SUCCESS: {
            return { ...state, resendInvitationLinkData: action.payload };
        }
        case VERIFY_RESET_PASSWORD_SUCCESS: {
            return { ...state, verifyRestPassword: action.payload };
        }
        case SUBMIT_RESET_PASSWORD_DATA_SUCCESS: {
            return { ...state, submitResetPassResp: action.payload };
        }
        case UPDATE_ASSIGN_USERS_SUCCESS: {
            return { ...state, updateUserResponce: action.payload };
        }
        case UPDATE_ASSIGN_USERS: {
            return { ...state, updateUserResponce: null };
        }
        case DELETE_ASSIGN_USERS_SUCCESS: {
            return { ...state, deleteUserResponce: action.payload };
        }
        case DELETE_ASSIGN_USERS: {
            return { ...state, deleteUserResponce: null };
        }
        case SET_ACTIVATION_PENDING_DATA_SUCCESS: {
            return { ...state, verificationPendingData: action.payload };
        }
        case GET_NEW_NOTIFICATION_COUNT_SUCCESS: {
            return { ...state, newNotificationCount: action.payload };
        }
        case CHANGE_NOTIFICATION_STATUS_SUCCESS: {
            return { ...state, notificationStatus: action.payload };
        }
        case RESEND_VERIFICATION_LINK_SUCCESS: {
            return { ...state, resendVerificatinLinkResp: action.payload };
        }

        case APPLE_SIGN_IN_FAILED: {
            return { ...state, appleLoginFailed: true, appleLoginFailedMsg: action.payload }
        }
        case ADD_COMPANY_LOGO: {
            return { ...state, logoUpdated: InsertFunctionStatus.INPROGRESS }
        }
        case ADD_COMPANY_LOGO_SUCCESS: {
            return { ...state, logoUpdated: action.payload }
        }
        case ADD_COMPANY_LOGO_FAILURE: {
            return { ...state, logoUpdated: InsertFunctionStatus.FAILED }
        }
        case ADD_COMPANY_LOGO_DONE: {
            return { ...state, logoUpdated: InsertFunctionStatus.DONE }
        }
        case DELETE_TALLY_COMPANY: {
            return { ...state, companyDeleteStatus: InsertFunctionStatus.inprogress }
        }
        case DELETE_TALLY_COMPANY_SUCCESS: {
            return { ...state, companyDeleteStatus: InsertFunctionStatus.SUCCESS }
        }
        case COMPANY_LIST_UPDATED: {
            return { ...state, companyDeleteStatus: InsertFunctionStatus.DONE }
        }
        case UPDATE_SOCIA_AUTH_USER_MOBILE_NUM: {
            return { ...state, mobileNumUpdateStatus: null }
        }
        case UPDATE_SOCIA_AUTH_USER_MOBILE_NUM_SUCCESS: {
            return { ...state, mobileNumUpdateStatus: action.payload }
        }
        case CHECK_MOBILE_NO_VERIFIED: {
            return { ...state, mobileNoVerificationStatus: null }
        }
        case CHECK_MOBILE_NO_VERIFIED_SUCCESS: {
            return { ...state, mobileNoVerificationStatus: action.payload }
        }
        case OTP_VERIFICATION: {
            return { ...state, otpVerificationData: null }
        }
        case OTP_VERIFICATION_RESET: {
            return { ...state, otpVerificationData: {} }
        }
        case OTP_VERIFICATION_SUCCESS: {
            return { ...state, otpVerificationData: action.payload }
        }
        case SUBMIT_OTP: {
            return { ...state, otpSubmitResponce: null }
        }
        case SUBMIT_OTP_RESET: {
            return { ...state, otpSubmitResponce: {} }
        }
        case SUBMIT_OTP_SUCCESS: {
            return { ...state, otpSubmitResponce: action.payload }
        }
        case CHECK_WHATSAPP_STATUS: {
            return { ...state, whatsAppStatus: null }
        }
        case CHECK_WHATSAPP_STATUS_SUCCESS: {
            return { ...state, whatsAppStatus: action.payload }
        }

        default:
            return state;
    }
}

