import React, { Component } from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import cookie from "react-cookies";

import "./scss/style.scss";
import ProtectedRoute from "./views/pages/protected-route/ProtectedRoute";
import VerifyEmail from "./views/pages/email-verification/VerifyEmail";
import VerificationSuccess from "./views/pages/email-verification/VerificationSuccess";
import VerificationFailed from "./views/pages/email-verification/VerificationFailed";
import ResetPasswordSuccess from "./views/pages/reset-password-messages/ResetPasswordSuccess";
import ResetPasswordVerification from "./views/pages/reset-password-messages/ResetPasswordVerification";
import UsernotRegistered from "./views/pages/user-registered/UsernotRegistered";
import VerifyInvitation from "./views/pages/user-registered/VerifyInvitation";
import VerifyRegistration from "./views/pages/user-registered/VerifyRegistration";
import Activationpending from "./views/pages/user-registered/ActivationPending";
import LiveChat from "./live-chat/LiveChat";
import Disclaimar from "./components/disclaimer/Disclaimer";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const TwoFactorAuthentication = React.lazy(() =>
  import("./views/pages/two-factor-authentication/TwoFactorAuthentication")
);
const Register = React.lazy(() => import("./views/pages/register/Register"));
const ForgetPassword = React.lazy(() =>
  import("./views/pages/forget-password/ForgetPassword")
);
const Password = React.lazy(() =>
  import("./views/pages/forget-password/Password")
);
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const NoCompanies = React.lazy(() =>
  import("./views/no-tally-companies/NoCompanies")
);

class App extends Component {
  constructor() {
    super();
    this.state = {
      isLoggedIn: false,
    };
  }

  render() {
    return (
      <>
        {/* <CacheBuster/> */}
        <Disclaimar/>
        <LiveChat />
        <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/verify-your-email"
                name="Verify Email"
                render={(props) => <VerifyEmail {...props} />}
              />
              <Route
                exact
                path="/activation-pending"
                name="Activation Pending"
                render={(props) => <Activationpending {...props} />}
              />
              <Route
                exact
                path="/email-verification-success"
                name="Verify Email Success"
                render={(props) => <VerificationSuccess {...props} />}
              />
              <Route
                exact
                path="/verify_invitation"
                name="Verify Email Success"
                render={(props) => <VerifyInvitation {...props} />}
              />
              <Route
                exact
                path="/verify_registration"
                name="Verify Email Success"
                render={(props) => <VerifyRegistration {...props} />}
              />
              <Route
                exact
                path="/user_not_registered"
                name="Verify Email Success"
                render={(props) => <UsernotRegistered {...props} />}
              />
              <Route
                exact
                path="/email-verification-failed"
                name="Verify Email Failed"
                render={(props) => <VerificationFailed {...props} />}
              />
              <Route
                exact
                path="/password-updated-successfully"
                name="Password Updated Successfully"
                render={(props) => <ResetPasswordSuccess {...props} />}
              />
              <Route
                exact
                path="/verify_forgot_password"
                name="Reset Password Verification"
                render={(props) => <ResetPasswordVerification {...props} />}
              />
              <Route
                exact
                path="/two-factor-authentication"
                name="Two Factor Authentication"
                render={(props) => <TwoFactorAuthentication {...props} />}
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                render={(props) => <Register {...props} />}
              />
              <Route
                exact
                path="/password"
                name="Password"
                render={(props) => <Password {...props} />}
              />
              <Route
                exact
                path="/forgot-password"
                name="Forget Password"
                render={(props) => <ForgetPassword {...props} />}
              />
              <Route
                exact
                path="/no-companies-found"
                name="No Companies Found"
                render={(props) => <NoCompanies {...props} />}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
              {
                <Route
                  path="/"
                  name="Home"
                  render={(props) => <TheLayout {...props} />}
                />
              }
            </Switch>
          </React.Suspense>
        </HashRouter>
      </>
    );
  }
}

export default App;
