import { ServiceAxios } from 'src/config/axios.config';
import axios from 'axios';
import {

  GET_PRACTICE_REG_INIT,
  GET_PRACTICE_REG_FINISH,
  GET_PRACTICE_REG_SUCCESS,
  GET_PRACTICE_REG_FAILED,

  UPDATE_PRACTICE_REG_STATUS,
  UPDATE_PRACTICE_INIT,
  UPDATE_PRACTICE_FINISH,

  GET_CLIENT_REG_INIT,
  GET_CLIENT_REG_FINISH,
  GET_CLIENT_REG_SUCCESS,
  GET_CLIENT_REG_FAILED,


  GET_TASK_LIST_INIT,
  GET_TASK_LIST_FINISH,
  GET_TASK_LIST_SUCCESS,
  GET_TASK_LIST_FAILED,

  GET_SCHEDULE_TASK_LIST_INIT,
  GET_SCHEDULE_TASK_LIST_FINISH,
  GET_SCHEDULE_TASK_LIST_SUCCESS,
  GET_SCHEDULE_TASK_LIST_FAILED,

  PM_REGISTRATION_INIT,
  PM_REGISTRATION_FINISH,
  PM_REGISTRATION_SUCCESS,
  PM_REGISTRATION_FAILED,

  CLIENT_REGISTRATION_INIT,
  CLIENT_REGISTRATION_FINISH,
  CLIENT_REGISTRATION_SUCCESS,
  CLIENT_REGISTRATION_FAILED,


  TASK_ALLOCATION_INIT,
  TASK_ALLOCATION_FINISH,
  TASK_ALLOCATION_SUCCESS,
  TASK_ALLOCATION_FAILED,

  ADD_USER_INIT,
  ADD_USER_FINISH,
  ADD_USER_SUCCESS,
  ADD_USER_FAILED,


  GET_USERS_INIT,
  GET_USERS_FINISH,
  GET_USERS_SUCCESS,
  GET_USERS_FAILED,

  DELETE_PRACTICE_STATUS,
  DELETE_CLIENT_STATUS,
  DELETE_TASK_STATUS,
  DELETE_TASK_DATA,

  UPDATE_TASK_STATUS,
  UPDATE_TASK_DATA,
  UPDATE_CLIENT_STATUS,

  DELETE_ATTACHMENT_STATUS,
  CATEGORY_LIST_DATA,
  GET_COMMENTS_LIST,
  ADD_COMMENTS_SUCCESS,
  GET_COMPLETED_TASK_LIST,
  GET_COMPLETED_TASK_LIST_SUCCESS,
  GET_COMPLETED_TASK_LIST_FAILURE,
  GET_PRACTICE_DATA_BY_ID,
  GET_PRACTICE_DATA_BY_ID_SUCCESS,
  GET_PRACTICE_DATA_BY_ID_FAILURE,
  GSTIN_DETAILS,


  GET_COMPLIANCE_INIT,
  GET_COMPLIANCE_FINISH,
  GET_COMPLIANCE_SUCCESS,
  GET_COMPLIANCE_FAILED,

  BUCKET_LIST,

  ADD_COMPLIANCE_TO_CLIENT_INIT,
  ADD_COMPLIANCE_TO_CLIENT_FINISH,
  ADD_COMPLIANCE_TO_CLIENT_MESSAGE,

  UPDATE_COMLIANCE_INIT,
  UPDATE_COMLIANCE_FINISH,
  UPDATE_COMLIANCE_DATA,

  GET_CHARTS_INIT,
  GET_CHARTS_FINISH,
  GET_CHARTS_DATA,
  GET_CHARTS_FAILED,

  COMPLIANCE_APPLICABLE_DATA,
  DELETE_COMPLIANCE_APPLICABLE_DATA,

  GET_DOC_LIST_INIT,
  GET_DOC_LIST_FINISH,
  GET_DOC_LIST_SUCCESS,
  GET_DOC_LIST_FAILED,

  DELETE_COMPLIANCE_INIT,
  DELETE_COMPLIANCE_FINISH,
  DELETE_COMPLIANCE_SUCCSESS,
  DELETE_COMPLIANCE_FAILED,

  ADD_COMPLIANCE_INIT,
  ADD_COMPLIANCE_FINISH,
  ADD_COMPLIANCE_SUCCESS,
  ADD_COMPLIANCE_FAILED,

  UPDATE_SINGLE_DUE_DATE_DATA,

  GET_REMAINDER_DATA,
  GET_REMAINDER_MESSAGE,

  REMAINDER_EMAIL_INIT,
  REMAINDER_EMAIL_FINISH,
  REMAINDER_EMAIL_MESSAGE,
  REMAINDER_EMAIL_STATUS,

  REMAINDER_WHATSAPP_INIT,
  REMAINDER_WHATSAPP_FINISH,
  REMAINDER_WHATSAPP_MESSAGE,
  REMAINDER_WHATSAPP_STATUS,


  GET_BILLS_LIST,
  GET_BILLS_LIST_SUCCESS,
  GET_BILL_DATA_BY_ID,
  GET_BILL_DATA_BY_ID_SUCCESS,

  GET_REMAINDER_HISTORY_INIT,
  GET_REMAINDER_HISTORY_FINISH,
  GET_REMAINDER_HISTORY_DATA,


  GET_EXPENDITURE_BILLS_INIT,
  GET_EXPENDITURE_BILLS_FINISH,
  GET_EXPENDITURE_BILLS_SUCCESS,
  GET_EXPENDITURE_BILLS_FAILED,


  GET_BILLS_BY_ID_INIT,
  GET_BILLS_BY_ID_FINISH,
  GET_BILLS_BY_ID_SUCCESS,
  GET_BILLS_BY_ID_FAILED,


  GENERATE_EXP_BILLS_INIT,
  GENERATE_EXP_BILLS_FINISH,
  GENERATE_EXP_BILLS_SUCCESS,
  GENERATE_EXP_BILLS_FAILED,

  DOC_ATTACHMENT_INIT,
  DOC_ATTACHMENT_FINISH,
  DOC_ATTACHMENT_DATA,

  UPDATE_EXP_BILLS_INIT,
  UPDATE_EXP_BILLS_FINISH,
  UPDATE_EXP_BILLS_SUCCESS,
  UPDATE_EXP_BILLS_FAILED,

  GET_EXP_STATEMENT_INIT,
  GET_EXP_STATEMENT_FINISH,
  GET_EXP_STATEMENT_SUCCESS,
  GET_EXP_STATEMENT_FAILED,

  GET_EXP_PAYMENTS_INIT,
  GET_EXP_PAYMENTS_FINISH,
  GET_EXP_PAYMENTS_SUCCESS,
  GET_EXP_PAYMENTS_FAILED,

  CREATE_EXP_PAYMENTS_INIT,
  CREATE_EXP_PAYMENTS_FINISH,
  CREATE_EXP_PAYMENTS_SUCCESS,
  CREATE_EXP_PAYMENTS_FAILED,


  UPDATE_EXP_PAYMENTS_INIT,
  UPDATE_EXP_PAYMENTS_FINISH,
  UPDATE_EXP_PAYMENTS_SUCCESS,
  UPDATE_EXP_PAYMENTS_FAILED,
  UPDATE_BILL_AMOUNT,
  UPDATE_BILL_AMOUNT_SUCCESS,

  DELETE_PRACTICE_INIT,
  DELETE_PRACTICE_FINISH,

  GET_AGAINST_BILL_DATA,

  DELETE_USER_INVITATION_INIT,
  DELETE_USER_INVITATION_FINISH,
  DELETE_USER_INVITATION_DATA,

  DELETE_COMPANY_USER_INIT,
  DELETE_COMPANY_USER_FINISH,
  DELETE_COMPANY_USER_DATA,

} from 'src/actions/PracticeManageActionTypes'
import Axios from 'axios';


const getTaskList = (id, bucketType) => {
  return (dispatch, getState) => {


    if (getState().PMReducer.updateTask != true) {
      dispatch({ type: GET_TASK_LIST_INIT })
    } else {
      dispatch({ type: UPDATE_TASK_STATUS, payload: null });
    }

    return ServiceAxios.get(`tasks_crud_api/?client_id=${id}&filter_by=${bucketType}`)
      .then(res => res)
      .then(res => {
        dispatch({ type: GET_TASK_LIST_FINISH })

        if (res.data.status) {

          dispatch({ type: GET_TASK_LIST_SUCCESS, payload: [res.data.data, res.data.message] })
        } else {
          dispatch({ type: GET_TASK_LIST_FAILED, payload: [res.data.status, res.data.data, res.data.message] })
        }
      })
  }
};


const getChartsData = (id) => {
  return (dispatch) => {
    dispatch({ type: GET_CHARTS_INIT })
    return ServiceAxios.get(`get_dashboard/?client_id=${id}`)
      .then(res => res)
      .then(res => {
        dispatch({ type: GET_CHARTS_FINISH })
        if (res.data.status) {
          dispatch({ type: GET_CHARTS_DATA, payload: res.data.data })
        } else {
          dispatch({ type: GET_CHARTS_FAILED, payload: [res.data.status, res.data.data, res.data.message] })
        }
      })
  }
};



const getScheduleTaskList = (id) => {
  return (dispatch) => {
    dispatch({ type: GET_SCHEDULE_TASK_LIST_INIT })

    return ServiceAxios.get(`get_schedule_tasks/?client_id=${id}`)
      .then(res => res)
      .then(res => {
        dispatch({ type: GET_SCHEDULE_TASK_LIST_FINISH })

        if (res.data.status) {

          dispatch({ type: GET_SCHEDULE_TASK_LIST_SUCCESS, payload: [res.data.data, res.data.message] })
        } else {
          dispatch({ type: GET_SCHEDULE_TASK_LIST_FAILED, payload: [res.data.data, res.data.message] })
        }
      })
  }
};


const getClientRegList = (firm, id) => {
  return (dispatch) => {
    dispatch({ type: GET_CLIENT_REG_INIT })

    return ServiceAxios.get(`client_crud_api/?firm_name=${firm}&practice_uuid=${id}`)

      .then(res => res)
      .then(res => {
        dispatch({ type: GET_CLIENT_REG_FINISH })
        if (res.data.status) {

          dispatch({ type: GET_CLIENT_REG_SUCCESS, payload: [res.data.data, res.data.message] })
        } else {
          dispatch({ type: GET_CLIENT_REG_FAILED, payload: [res.data.status, res.data.message] })
        }
      })
  }
};



const getPracRegList = (email) => {
  return (dispatch) => {
    dispatch({ type: GET_PRACTICE_REG_INIT })

    return ServiceAxios.get(`practice_crud_api/?u_uuid=${email}`)
      .then(res => res)
      .then(res => {
        dispatch({ type: GET_PRACTICE_REG_FINISH })
        if (res.data.status) {

          localStorage.setItem('USER_FIRM_LIST', JSON.stringify(res.data.data));

          if (res.data.data.length > 0) {

            var currentGST = JSON.parse(localStorage.getItem('SELECTED_FIRM_DETAILS'));

            if (currentGST !== null) {
              var firmList = JSON.parse(localStorage.getItem('USER_FIRM_LIST'));
              let obj = firmList.find(o => o.practice_uuid === currentGST.practice_uuid);

              if (obj !== undefined) {
                localStorage.setItem('SELECTED_FIRM_DETAILS', JSON.stringify(obj));
              } else {
                localStorage.setItem('SELECTED_FIRM_DETAILS', JSON.stringify(res.data.data[0]));
              }
            } else {
              localStorage.setItem('SELECTED_FIRM_DETAILS', JSON.stringify(res.data.data[0]));
            }
          } else {
            localStorage.removeItem('SELECTED_FIRM_DETAILS');
            localStorage.removeItem('USER_FIRM_LIST');
          }

          dispatch({ type: GET_PRACTICE_REG_SUCCESS, payload: [res.data.data, res.data.message] })
        } else {
          dispatch({ type: GET_PRACTICE_REG_FAILED, payload: [res.data.status, res.data.message] })
        }
      })
  }
};


const deletePractice = (param) => {
  return (dispatch) => {
    dispatch({ type: DELETE_PRACTICE_INIT })
    dispatch({ type: DELETE_PRACTICE_STATUS, payload: null })
    return ServiceAxios.delete('practice_crud_api/', { data: param })
      .then(res => res)
      .then(res => {
        dispatch({ type: DELETE_PRACTICE_FINISH })
        if (res.data.status) {
          dispatch({ type: DELETE_PRACTICE_STATUS, payload: res.data.status })
        }
      })
  }
};



const deleteClient = (param) => {
  return (dispatch) => {
    dispatch({ type: DELETE_CLIENT_STATUS, payload: null })
    return ServiceAxios.delete('client_crud_api/', { data: param })
      .then(res => res)
      .then(res => {
        if (res.data.status) {
          dispatch({ type: DELETE_CLIENT_STATUS, payload: res.data.status })
        }
      })
  }
};



const deleteTask = (param) => {
  return (dispatch) => {
    dispatch({ type: DELETE_TASK_STATUS, payload: null })
    return ServiceAxios.delete('tasks_crud_api/', { data: param })
      .then(res => res)
      .then(res => {
        if (res.data.status) {
          dispatch({ type: DELETE_TASK_DATA, payload: res.data.data })
          dispatch({ type: DELETE_TASK_STATUS, payload: res.data.status })
        }
      })
  }
};

const PMRegistration = (params) => {
  return (dispatch, getState) => {
    dispatch({ type: PM_REGISTRATION_INIT })
    return ServiceAxios.post('practice_management_registration/', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(res => res)
      .then(res => {
        dispatch({ type: PM_REGISTRATION_FINISH })
        if (res.data.status) {
          dispatch({ type: PM_REGISTRATION_SUCCESS, payload: [res.data.status, res.data.message] });      
          dispatch(getPracRegList(getState().userReduser.loginUserData.userId));
          ServiceAxios.post('insert_compliance_categories/', { practice_uuid: [res.data.practice_uuid] }).then(response => console.log(response));
          ServiceAxios.post('insert_compliance_categories/', { practice_uuid: [res.data.practice_uuid], insert_type: "compliance_income_tax" }).then(response => console.log(response));
          ServiceAxios.post('insert_compliance_categories/', { practice_uuid: [res.data.practice_uuid], insert_type: "complinace_gst" }).then(response => console.log(response));

        } else {
          dispatch({ type: PM_REGISTRATION_FAILED, payload: [res.data.status, res.data.message] })
        }
      })
  }
};


const UpdatePracticeReg = (params) => {
  return (dispatch) => {

    dispatch({ type: UPDATE_PRACTICE_INIT })

    dispatch({ type: UPDATE_PRACTICE_REG_STATUS, payload: null })
    return ServiceAxios.put('practice_crud_api/', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(res => res)
      .then(res => {
        dispatch({ type: UPDATE_PRACTICE_FINISH })
        if (res.data.status) {
          dispatch({ type: UPDATE_PRACTICE_REG_STATUS, payload: res.data.status })
        } else {
          dispatch({ type: UPDATE_PRACTICE_REG_STATUS, payload: res.data.status })
        }
      })
  }
};


const UpdateTaskAPI = (params) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_TASK_STATUS, payload: null })
    return ServiceAxios.put('tasks_crud_api/', params)
      .then(res => res)
      .then(res => {
        // dispatch({ type: PM_REGISTRATION_FINISH })
        if (res.data.status) {
          dispatch({ type: UPDATE_TASK_DATA, payload: res.data.data });
          dispatch({ type: UPDATE_TASK_STATUS, payload: res.data.status });
          // dispatch({ type: PM_REGISTRATION_SUCCESS, payload: [res.data.status, res.data.message] })
        } else {
          // dispatch({ type: PM_REGISTRATION_FAILED, payload: [res.data.status, res.data.message] })
        }
      })
  }
};


const UpdateClient = (params) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_CLIENT_STATUS, payload: null })
    return ServiceAxios.put('client_crud_api/', params)
      .then(res => res)
      .then(res => {
        // dispatch({ type: PM_REGISTRATION_FINISH })
        if (res.data.status) {

          dispatch({ type: UPDATE_CLIENT_STATUS, payload: res.data.status })

          // dispatch({ type: PM_REGISTRATION_SUCCESS, payload: [res.data.status, res.data.message] })
        } else {
          // dispatch({ type: PM_REGISTRATION_FAILED, payload: [res.data.status, res.data.message] })
        }
      })
  }
};

const ClientRegistration = (params) => {
  return (dispatch) => {
    dispatch({ type: CLIENT_REGISTRATION_INIT })
    return ServiceAxios.post('client_registration/', params)
      .then(res => res)
      .then(res => {
        dispatch({ type: CLIENT_REGISTRATION_FINISH })
        if (res.data.status) {
          dispatch({ type: CLIENT_REGISTRATION_SUCCESS, payload: [res.data.status, res.data.message, res.data.data] })
        } else {
          dispatch({ type: CLIENT_REGISTRATION_FAILED, payload: [res.data.status, res.data.message] })
        }
      })
  }
};


const TaskAllocation = (params) => {
  return (dispatch) => {
    dispatch({ type: TASK_ALLOCATION_INIT })
    return ServiceAxios.post('task_allocation/', params)
      .then(res => res)
      .then(res => {
        dispatch({ type: TASK_ALLOCATION_FINISH })
        if (res.data.status) {

          dispatch({ type: TASK_ALLOCATION_SUCCESS, payload: [res.data.status, res.data.message, res.data.data] })
        } else {
          dispatch({ type: TASK_ALLOCATION_FAILED, payload: [res.data.status, res.data.message] })
        }
      })
  }
};


const addUser = (params) => {
  return (dispatch) => {
    dispatch({ type: ADD_USER_INIT })
    return ServiceAxios.post('user_invitation/', params)
      .then(res => res)
      .then(res => {
        dispatch({ type: ADD_USER_FINISH })
        if (res.data.status) {

          dispatch({ type: ADD_USER_SUCCESS, payload: [res.data.status, res.data.response] })
        } else {
          dispatch({ type: ADD_USER_FAILED, payload: [res.data.status, res.data.response] })
        }
      })
  }
};


const getUsers = (id) => {
  return (dispatch) => {
    dispatch({ type: GET_USERS_INIT })
    return ServiceAxios.post('get_pm_company_users/', {
      practice_uuid: id
    })
      .then(res => res)
      .then(res => {
        dispatch({ type: GET_USERS_FINISH })
        if (res.data.status) {

          dispatch({ type: GET_USERS_SUCCESS, payload: [res.data.status, res.data] })
        } else {
          dispatch({ type: GET_USERS_FAILED, payload: res.data.status })
        }
      })
  }
};

const addTaskBucket = (params, id) => {
  return (dispatch, getState) => {
    // dispatch({ type: GET_USERS_INIT })
    return ServiceAxios.post('task_bucket_crud_api/', params)
      .then(res => res)
      .then(res => {
        // dispatch({ type: GET_USERS_FINISH })
        if (res.data.status) {
          dispatch(getTaskList(id, getState().PMReducer.bucketGroup));
          dispatch(getChartsData(id));
          dispatch(getScheduleTaskList(id))


          // dispatch({ type: GET_USERS_SUCCESS, payload: [res.data.status, res.data] })
        } else {
          // dispatch({ type: GET_USERS_FAILED, payload: res.data.status})
        }
      })
  }
};



const deleteTaskBucket = (param, id) => {
  return (dispatch, getState) => {
    // dispatch({type: DELETE_PRACTICE_STATUS, payload: null})
    return ServiceAxios.delete('task_bucket_crud_api/', { data: param })
      .then(res => res)
      .then(res => {
        if (res.data.status) {
          dispatch(getTaskList(id, getState().PMReducer.bucketGroup));
          dispatch(getChartsData(id))
          dispatch(getScheduleTaskList(id))
          // dispatch({type: DELETE_PRACTICE_STATUS, payload: res.data.status})
        }
      })
  }
};



const getbucketList = (id) => {
  return (dispatch, getState) => {
    // dispatch({type: DELETE_PRACTICE_STATUS, payload: null})
    return ServiceAxios.get(`task_bucket_crud_api/?client_id=${id}`)
      .then(res => res)
      .then(res => {
        if (res.data.status) {
          dispatch({ type: BUCKET_LIST, payload: res.data.data });

        }
      })
  }
};



const addAttachments = (params, id) => {
  return (dispatch, getState) => {
    // dispatch({ type: PM_REGISTRATION_INIT })
    return ServiceAxios.post('attachments/', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(res => res)
      .then(res => {
        // dispatch({ type: PM_REGISTRATION_FINISH })
        if (res.data.status) {

          dispatch(getTaskList(id, getState().PMReducer.bucketGroup));
          dispatch(getChartsData(id));
          dispatch(getScheduleTaskList(id))
          // dispatch({ type: PM_REGISTRATION_SUCCESS, payload: [res.data.status, res.data.message] })
        } else {
          // dispatch({ type: PM_REGISTRATION_FAILED, payload: [res.data.status, res.data.message] })
        }
      })
  }
};




const deleteAttachment = (param, id) => {
  return (dispatch, getState) => {
    // dispatch({type: DELETE_PRACTICE_STATUS, payload: null})
    return ServiceAxios.delete('attachments/', { data: param })
      .then(res => res)
      .then(res => {
        if (res.data.status) {
          dispatch({ type: DELETE_ATTACHMENT_STATUS, payload: res.data.data })
          if (id !== undefined) {
            dispatch(getTaskList(id, getState().PMReducer.bucketGroup));
            dispatch(getChartsData(id));
            dispatch(getScheduleTaskList(id))
          }
        }
      })
  }
};





const downloadAttachment = (url, name) => {
  return (dispatch) => {

    return ServiceAxios.get(`download_attachments_s3/?file_url=${url}&file_name=${name}`)
      .then(res => res)
      .then(res => {
        // dispatch({ type: GET_TASK_LIST_FINISH }) 
        if (res.data.status) {
          // dispatch({ type: GET_TASK_LIST_SUCCESS, payload: [res.data.data, res.data.message] })
        } else {
          // dispatch({ type: GET_TASK_LIST_FAILED, payload: [res.data.status, res.data.data, res.data.message] })
        }
      })
  }
};


const getCategoryList = (uuid) => {
  return (dispatch) => {

    return ServiceAxios.get(`get_category_drop_list/?practice_uuid=${uuid}`)
      .then(res => res)
      .then(res => {
        // dispatch({ type: GET_TASK_LIST_FINISH }) 
        if (res.data.status) {
          dispatch({ type: CATEGORY_LIST_DATA, payload: res.data.data })
          // dispatch({ type: GET_TASK_LIST_SUCCESS, payload: [res.data.data, res.data.message] })
        } else {
          // dispatch({ type: GET_TASK_LIST_FAILED, payload: [res.data.status, res.data.data, res.data.message] })
        }
      })
  }
};



const addNewCategory = (params) => {
  return (dispatch) => {

    return ServiceAxios.post('get_category_drop_list/', params)
      .then(res => res)
      .then(res => {
        // dispatch({ type: GET_TASK_LIST_FINISH }) 
        if (res.data.status) {
          dispatch({ type: CATEGORY_LIST_DATA, payload: res.data.data })
          // dispatch({ type: GET_TASK_LIST_SUCCESS, payload: [res.data.data, res.data.message] })
        } else {
          // dispatch({ type: GET_TASK_LIST_FAILED, payload: [res.data.status, res.data.data, res.data.message] })
        }
      })
  }
};

const getCompletedTaskList = (cId) => (dispatch) => {
  dispatch({
    type: GET_COMPLETED_TASK_LIST,
  });

  return ServiceAxios
    .get(`get_completed_tasks/?client_id=${cId}`)
    .then(
      (res) =>
        dispatch({
          type: GET_COMPLETED_TASK_LIST_SUCCESS,
          payload: res.data.data,
        }),
      (err) =>
        dispatch({
          type: GET_COMPLETED_TASK_LIST_FAILURE,
          payload: err,
        })
    );
}

const getPracticeDataById = (pId) => (dispatch) => {
  dispatch({
    type: GET_PRACTICE_DATA_BY_ID,
  });

  return ServiceAxios
    .get(`get_practice_details/?practice_uuid=${pId}`)
    .then(
      (res) =>
        dispatch({
          type: GET_PRACTICE_DATA_BY_ID_SUCCESS,
          payload: res.data.data,
        }),
      (err) =>
        dispatch({
          type: GET_PRACTICE_DATA_BY_ID_FAILURE,
          payload: err,
        })
    );
}



const taskComments = (id) => {
  return (dispatch) => {

    return ServiceAxios.get(`task_comments/?task_id=${id}`)
      .then(res => res)
      .then(res => {
        // dispatch({ type: GET_TASK_LIST_FINISH })         
        if (res.data.status) {          
          // dispatch({type: CATEGORY_LIST_DATA, payload: res.data.data})
          dispatch({ type: GET_COMMENTS_LIST, payload: res.data.data })
        } else {
          dispatch({ type: GET_COMMENTS_LIST, payload: res.data.data })
        }
      })
  }
};




const addComments = (params) => {
  return (dispatch) => {
    return ServiceAxios.post('task_comments/', params)
      .then(res => res)
      .then(res => {
        if (res.data.status) {
          dispatch({ type: ADD_COMMENTS_SUCCESS, payload: res.data.status })
        } else {
          dispatch({ type: ADD_COMMENTS_SUCCESS, payload: res.data.status })
        }
      })
  }
};




const fetchGstinDetails = (params) => {
  return (dispatch) => {
    return axios.post('https://devservices.finsights.biz/finsightsgst/api/get_public_search/', params)
      .then(res => res)
      .then(res => {
        if (res.data.status) {
          dispatch({ type: GSTIN_DETAILS, payload: res.data.data })
        } else {
          dispatch({ type: GSTIN_DETAILS, payload: res.data.data })
        }
      })
  }
};



const getCompliance = (uuid) => {
  return (dispatch) => {

    dispatch({ type: GET_COMPLIANCE_INIT })

    return ServiceAxios.get(`compliance_due_date/?practice_uuid=${uuid}`)
      .then(res => res)
      .then(res => {
        dispatch({ type: GET_COMPLIANCE_FINISH })
        if (res.data.status) {

          dispatch({ type: GET_COMPLIANCE_SUCCESS, payload: res.data.data })
        } else {
          dispatch({ type: GET_COMPLIANCE_FAILED, payload: res.data.data })
        }
      })
  }
}

const addCompliance = (params) => {
  return (dispatch) => {
    dispatch({ type: ADD_COMPLIANCE_INIT })
    return ServiceAxios.post('compliance_due_date/', params)
      .then(res => res)
      .then(res => {
        dispatch({ type: ADD_COMPLIANCE_FINISH })
        if (res.data.status) {
          dispatch({ type: ADD_COMPLIANCE_SUCCESS, payload: [res.data.status, res.data.message, res.data.data] })
        } else {
          dispatch({ type: ADD_COMPLIANCE_FAILED, payload: [res.data.status, res.data.message, res.data.data] })
        }
      })
  }
}



const updateCompliance = (params) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_COMLIANCE_INIT })
    return ServiceAxios.post('compliance_due_date/', params)
      .then(res => res)
      .then(res => {
        dispatch({ type: UPDATE_COMLIANCE_FINISH })
        if (res.data.status) {
          dispatch({ type: UPDATE_COMLIANCE_DATA, payload: res.data.data })
        } else {
          // dispatch({ type: GSTIN_DETAILS, payload: res.data.data })
        }
      })
  }
}


const updateComplianceDueDate = (params) => {
  return (dispatch) => {
    // dispatch({ type: UPDATE_COMLIANCE_INIT })
    return ServiceAxios.post('compliance_due_date/', params)
      .then(res => res)
      .then(res => {
        // dispatch({ type: UPDATE_COMLIANCE_FINISH })
        if (res.data.status) {
          dispatch({ type: UPDATE_SINGLE_DUE_DATE_DATA, payload: res.data.data })
        } else {
          // dispatch({ type: GSTIN_DETAILS, payload: res.data.data })
        }
      })
  }
}


const deleteCompliance = (param) => {
  return (dispatch) => {
    dispatch({ type: DELETE_COMPLIANCE_INIT })
    return ServiceAxios.delete('compliance_due_date/', { data: param })
      .then(res => res)
      .then(res => {
        dispatch({ type: DELETE_COMPLIANCE_FINISH })
        if (res.data.status) {          
          dispatch({ type: DELETE_COMPLIANCE_SUCCSESS, payload: [res.data.status, res.data.message, res.data.data] })
        } else {
          dispatch({ type: DELETE_COMPLIANCE_FAILED, payload: [res.data.status, res.data.message, res.data.data] })
        }
      })
  }
};


const addComplianceToClient = (params) => {
  return (dispatch) => {
    dispatch({ type: ADD_COMPLIANCE_TO_CLIENT_INIT, payload: true });
    return ServiceAxios.post('compliance_to_client_crud/', params)
      .then(res => res)
      .then(res => {
        dispatch({ type: ADD_COMPLIANCE_TO_CLIENT_FINISH, payload: false });
        if (res.data.status) {          
          dispatch({ type: ADD_COMPLIANCE_TO_CLIENT_MESSAGE, payload: res.data.response })
        } else {
          // dispatch({ type: ADD_COMPLIANCE_TO_CLIENT_MESSAGE, payload: res.data.response })
        }
      })
  }
}


const getComplianceApplicable = (id) => {
  return (dispatch) => {

    return ServiceAxios.get(`compliance_to_client_crud/?compliance_id=${id}`)
      .then(res => res)
      .then(res => {
        // dispatch({ type: GET_TASK_LIST_FINISH })         
        if (res.data.status) {
          dispatch({ type: COMPLIANCE_APPLICABLE_DATA, payload: res.data.data })
        } else {
          dispatch({ type: COMPLIANCE_APPLICABLE_DATA, payload: [] })
        }
      })
  }
}


const deleteComplianceApplicable = (param) => {
  return (dispatch) => {
    return ServiceAxios.delete('compliance_to_client_crud/', { data: param })
      .then(res => res)
      .then(res => {
        if (res.data.status) {          
          dispatch({ type: DELETE_COMPLIANCE_APPLICABLE_DATA, payload: res.data.data })
        }
      })
  }
};



const getDocList = (params) => {
  return (dispatch) => {
    dispatch({ type: GET_DOC_LIST_INIT })
    return ServiceAxios.post('doc_management/', params)
      .then(res => res)
      .then(res => {
        dispatch({ type: GET_DOC_LIST_FINISH })
        if (res.data.status) {
          dispatch({ type: GET_DOC_LIST_SUCCESS, payload: [res.data.status, res.data.message, res.data.data] })
        } else {
          dispatch({ type: GET_DOC_LIST_FAILED, payload: [res.data.status, res.data.message, res.data.data] })
        }
      })
  }
};




const getRemainderDetails = (params) => {
  return (dispatch) => {
    // dispatch({type: GET_REMAINDER_DATA, payload: null});
    return ServiceAxios.post('get_remainder_details/', params)
      .then(res => res)
      .then(res => {
        // dispatch({type: ADD_COMPLIANCE_TO_CLIENT_FINISH, payload: false});
        if (res.data.status) {
          dispatch({ type: GET_REMAINDER_DATA, payload: res.data.data });
          dispatch({ type: GET_REMAINDER_MESSAGE, payload: res.data.message });
        } else {
          dispatch({ type: GET_REMAINDER_DATA, payload: res.data.data });
          dispatch({ type: GET_REMAINDER_MESSAGE, payload: res.data.message });
        }
      })
  }
}



const remainderBulkEmails = (params) => {
  return (dispatch, getState) => {
    dispatch({ type: REMAINDER_EMAIL_INIT })
    return ServiceAxios.post('send_bulk_emails/', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(res => res)
      .then(res => {
        dispatch({ type: REMAINDER_EMAIL_FINISH })
        if (res.data.status) {
          dispatch({ type: REMAINDER_EMAIL_MESSAGE, payload: res.data.response });
          dispatch({ type: REMAINDER_EMAIL_STATUS, payload: res.data.status });

        } else {
          dispatch({ type: REMAINDER_EMAIL_MESSAGE, payload: res.data.response });
          dispatch({ type: REMAINDER_EMAIL_STATUS, payload: res.data.status });

        }
      })
  }
};


const remainderBulkWhatsapp = (params) => {
  return (dispatch, getState) => {
    dispatch({ type: REMAINDER_WHATSAPP_INIT })
    return ServiceAxios.post('send_whatsapp_alert/', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(res => res)
      .then(res => {
        dispatch({ type: REMAINDER_WHATSAPP_FINISH })
        if (res.data.status) {
          dispatch({ type: REMAINDER_WHATSAPP_MESSAGE, payload: res.data.response });
          dispatch({ type: REMAINDER_WHATSAPP_STATUS, payload: res.data.status });

        } else {
          dispatch({ type: REMAINDER_WHATSAPP_MESSAGE, payload: res.data.response });
          dispatch({ type: REMAINDER_WHATSAPP_STATUS, payload: res.data.status });

        }
      })
  }
};


const getBillsList = (pId) => {
  return (dispatch) => {

    dispatch({ type: GET_BILLS_LIST })

    return ServiceAxios.get(`get_invoices/?${pId}`)
      .then(res => res)
      .then(res => {
        if (res.data.status) {
          dispatch({ type: GET_BILLS_LIST_SUCCESS, payload: res.data.data })
        }
      })
  }
}

const updateBillAmount = (data) => {
  return (dispatch) => {

    dispatch({ type: UPDATE_BILL_AMOUNT })

    return ServiceAxios.post(`update_invoice_received_amount/`, data)
      .then(res => res)
      .then(res => {
        if (res.data.status) {
          dispatch({ type: UPDATE_BILL_AMOUNT_SUCCESS, payload: "Success" })
        }
      })
  }
}


// const getBillsList = (pId) => {
//   return (dispatch) => {

//     dispatch({ type: GET_BILLS_LIST })

//     return ServiceAxios.get(`get_invoices/?practice_uuid=${pId}`)
//       .then(res => res)
//       .then(res => {
//         if (res.data.status) {
//           dispatch({ type: GET_BILLS_LIST_SUCCESS, payload: res.data.data })
//         }
//       })
//   }
// }

const getBillsDataById = (id) => {
  return (dispatch) => {

    dispatch({ type: GET_BILL_DATA_BY_ID })
    return ServiceAxios.get(`get_invoice_by_id/?invoice_id=${id}`)
      .then(res => res)
      .then(res => {
        if (res.data.status) {
          dispatch({ type: GET_BILL_DATA_BY_ID_SUCCESS, payload: res.data.data })
        }
      })
  }
}


const getClientRemainderHistory = (id) => {
  return (dispatch) => {
    dispatch({ type: GET_REMAINDER_HISTORY_INIT })
    return ServiceAxios.get(`get_client_remainder_history/?client_id=${id}`)
      .then(res => res)
      .then(res => {
        dispatch({ type: GET_REMAINDER_HISTORY_FINISH })
        if (res.data.status) {
          dispatch({ type: GET_REMAINDER_HISTORY_DATA, payload: res.data.data })
        } else {
          dispatch({ type: GET_REMAINDER_HISTORY_DATA, payload: res.data.data })
        }
      })
  }
}


const getExpenditureBills = (practice_uuid, user_uuid) => {
  return (dispatch) => {
    dispatch({ type: GET_EXPENDITURE_BILLS_INIT })
    return ServiceAxios.get(`expenditure_bill_crud/?practice_uuid=${practice_uuid}&user_uuid=${user_uuid}`)
      .then(res => res)
      .then(res => {
        dispatch({ type: GET_EXPENDITURE_BILLS_FINISH })
        if (res.data.status) {
          dispatch({ type: GET_EXPENDITURE_BILLS_SUCCESS, payload: res.data.data })
        } else {
          dispatch({ type: GET_EXPENDITURE_BILLS_FAILED, payload: res.data.data })
        }
      })
  }
}


const getExpenditureByID = (bill_id) => {
  return (dispatch) => {
    dispatch({ type: GET_BILLS_BY_ID_INIT })
    return ServiceAxios.get(`get_expenditure_bill_by_id/?bill_id=${bill_id}`)
      .then(res => res)
      .then(res => {
        dispatch({ type: GET_BILLS_BY_ID_FINISH })
        if (res.data.status) {
          dispatch({ type: GET_BILLS_BY_ID_SUCCESS, payload: res.data.data })
        } else {
          dispatch({ type: GET_BILLS_BY_ID_FAILED, payload: null })
        }
      })
  }
}


const generateExpenditureBill = (params) => {
  return (dispatch) => {
    dispatch({ type: GENERATE_EXP_BILLS_INIT })
    return ServiceAxios.post('expenditure_bill_crud/', params)
      .then(res => res)
      .then(res => {
        dispatch({ type: GENERATE_EXP_BILLS_FINISH })
        if (res.data.status) {
          dispatch({ type: GENERATE_EXP_BILLS_SUCCESS, payload: res.data.data })
        } else {
          dispatch({ type: GENERATE_EXP_BILLS_FAILED, payload: res.data.data })
        }
      })
  }
};




const updateExpenditureBill = (params) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_EXP_BILLS_INIT })
    return ServiceAxios.put('expenditure_bill_crud/', params)
      .then(res => res)
      .then(res => {
        dispatch({ type: UPDATE_EXP_BILLS_FINISH })
        if (res.data.status) {
          dispatch({ type: UPDATE_EXP_BILLS_SUCCESS, payload: res.data.data })
        } else {
          dispatch({ type: UPDATE_EXP_BILLS_FAILED, payload: res.data.data });
        }
      })
  }
};


const docAttachments = (params, id) => {
  return (dispatch, getState) => {
    dispatch({ type: DOC_ATTACHMENT_INIT })
    return ServiceAxios.post('attachments/', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(res => res)
      .then(res => {
        dispatch({ type: DOC_ATTACHMENT_FINISH })
        if (res.data.status) {
          dispatch({ type: DOC_ATTACHMENT_DATA, payload: res.data.data })
        } else {
          dispatch({ type: DOC_ATTACHMENT_DATA, payload: res.data.data })
        }
      })
  }
};


const expenditureStatement = (params, id) => {
  return (dispatch, getState) => {
    dispatch({ type: GET_EXP_STATEMENT_INIT })
    return ServiceAxios.post('ledger_wise_expenditure_statement/', params)
      .then(res => res)
      .then(res => {
        dispatch({ type: GET_EXP_STATEMENT_FINISH })
        if (res.data.status) {          
          dispatch({ type: GET_EXP_STATEMENT_SUCCESS, payload: res.data.data })
        } else {
          dispatch({ type: GET_EXP_STATEMENT_FAILED, payload: res.data.data })
        }
      })
  }
};



const expenditurePR = (practice_uuid, user_uuid) => {
  return (dispatch, getState) => {
    dispatch({ type: GET_EXP_PAYMENTS_INIT })
    return ServiceAxios.get(`expenditure_pr_crud/?practice_uuid=${practice_uuid}&user_uuid=${user_uuid}`)
      .then(res => res)
      .then(res => {
        dispatch({ type: GET_EXP_PAYMENTS_FINISH })
        if (res.data.status) {          
          dispatch({ type: GET_EXP_PAYMENTS_SUCCESS, payload: res.data.data })
        } else {
          dispatch({ type: GET_EXP_PAYMENTS_FAILED, payload: res.data.data })
        }
      })
  }
};


const addPaymentRequest = (params) => {
  return (dispatch) => {
    dispatch({ type: CREATE_EXP_PAYMENTS_INIT })
    return ServiceAxios.post('expenditure_pr_crud/', params)
      .then(res => res)
      .then(res => {
        dispatch({ type: CREATE_EXP_PAYMENTS_FINISH })
        if (res.data.status) {          
          dispatch({ type: CREATE_EXP_PAYMENTS_SUCCESS, payload: res.data.data })
        } else {
          dispatch({ type: CREATE_EXP_PAYMENTS_FAILED, payload: res.data.data })
        }
      })
  }
};


const updatePaymentRequest = (params) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_EXP_PAYMENTS_INIT })
    return ServiceAxios.put('expenditure_pr_crud/', params)
      .then(res => res)
      .then(res => {
        dispatch({ type: UPDATE_EXP_PAYMENTS_FINISH })
        if (res.data.status) {          
          dispatch({ type: UPDATE_EXP_PAYMENTS_SUCCESS, payload: res.data.data })
        } else {
          dispatch({ type: UPDATE_EXP_PAYMENTS_FAILED, payload: res.data.data })
        }
      })
  }
};




const approvePaymentRequest = (params) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_EXP_PAYMENTS_INIT })
    return ServiceAxios.put('expenditure_pr_crud/', params)
      .then(res => res)
      .then(res => {
        dispatch({ type: UPDATE_EXP_PAYMENTS_FINISH })
        if (res.data.status) {          
          dispatch({ type: UPDATE_EXP_PAYMENTS_SUCCESS, payload: res.data.data })
        } else {
          dispatch({ type: UPDATE_EXP_PAYMENTS_FAILED, payload: res.data.data })
        }
      })
  }
};

const autoBillNumber = (id) => {
  return (dispatch, getState) => {
    // dispatch({ type: GET_EXP_PAYMENTS_INIT})
    return ServiceAxios.get(`auto_generate_bill_no/?practice_uuid=${id}`)
      .then(res => res)
      .then(res => {
        // dispatch({ type: GET_EXP_PAYMENTS_FINISH})
        if (res.data.status) {            
          dispatch({ type: "GET_BILL_NUMBER", payload: res.data.data})
        } else {
          // dispatch({ type: GET_EXP_PAYMENTS_FAILED, payload: res.data.data})
        }
      })
  }
};


const getAgainstBills = (params) => {
  return (dispatch) => {
    dispatch({ type: GET_AGAINST_BILL_DATA, payload: null })
    return ServiceAxios.post('get_against_bills/', params)
      .then(res => res)
      .then(res => {
        // dispatch({ type: CREATE_EXP_PAYMENTS_FINISH })
        if (res.data.status) {          
          dispatch({ type: GET_AGAINST_BILL_DATA, payload: res.data.data })
        } else {
          // dispatch({ type: CREATE_EXP_PAYMENTS_FAILED, payload: res.data.data })
        }
      })
  }
};


const deleteInvitation = (params) => {
  return (dispatch) => {
    dispatch({ type: DELETE_USER_INVITATION_INIT })
    return ServiceAxios.delete('delete_invitation/', { data: params })
    // .post('delete_invitation/', params)
      .then(res => res)
      .then(res => {
        dispatch({ type: DELETE_USER_INVITATION_FINISH })
        if (res.data.status) {          
          console.log(res.data.data);
          dispatch({ type: DELETE_USER_INVITATION_DATA, payload: res.data.data })
        } else {
          // dispatch({ type: CREATE_EXP_PAYMENTS_FAILED, payload: res.data.data })
        }
      })
  }
};


const deleteCompanyUser = (params) => {
  return (dispatch) => {
    dispatch({ type: DELETE_COMPANY_USER_INIT })
    return ServiceAxios.delete('delete_pm_company_user/', { data: params })
    // .post('delete_pm_company_user/', params)
      .then(res => res)
      .then(res => {
        dispatch({ type: DELETE_COMPANY_USER_FINISH })
        if (res.data.status) {          
          console.log(res.data.data);
          dispatch({ type: DELETE_COMPANY_USER_DATA, payload: res.data.data })
        } else {
          // dispatch({ type: CREATE_EXP_PAYMENTS_FAILED, payload: res.data.data })
        }
      })
  }
};

export {
  PMRegistration,
  ClientRegistration,
  TaskAllocation,
  getPracRegList,
  getClientRegList,
  getTaskList,
  getChartsData,
  deletePractice,
  UpdatePracticeReg,
  UpdateClient,
  deleteClient,
  deleteTask,
  UpdateTaskAPI,
  addUser,
  getUsers,
  addTaskBucket,
  deleteTaskBucket,
  addAttachments,
  deleteAttachment,
  downloadAttachment,
  getCategoryList,
  addNewCategory,
  taskComments,
  addComments,
  getCompletedTaskList,
  getPracticeDataById,
  fetchGstinDetails,
  getScheduleTaskList,
  getCompliance,
  getbucketList,
  addCompliance,
  updateCompliance,
  updateComplianceDueDate,
  deleteCompliance,
  addComplianceToClient,
  getComplianceApplicable,
  deleteComplianceApplicable,
  getDocList,
  getRemainderDetails,
  remainderBulkEmails,
  remainderBulkWhatsapp,
  getBillsList,
  getBillsDataById,
  getClientRemainderHistory,
  getExpenditureBills,
  getExpenditureByID,
  generateExpenditureBill,
  docAttachments,
  updateExpenditureBill,
  expenditureStatement,
  expenditurePR,
  addPaymentRequest,
  updatePaymentRequest,
  approvePaymentRequest,
  autoBillNumber,
  getAgainstBills,
  updateBillAmount,
  deleteInvitation,
  deleteCompanyUser
};