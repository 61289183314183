export const GET_PRACTICE_REG_INIT = "GET_PRACTICE_REG_INIT";
export const GET_PRACTICE_REG_FINISH = "GET_PRACTICE_REG_FINISH";
export const GET_PRACTICE_REG_SUCCESS = "GET_PRACTICE_REG_SUCCESS";
export const GET_PRACTICE_REG_FAILED = "GET_PRACTICE_REG_FAILED";

export const GET_CLIENT_REG_INIT = "GET_CLIENT_REG_INIT";
export const GET_CLIENT_REG_FINISH = "GET_CLIENT_REG_FINISH";
export const GET_CLIENT_REG_SUCCESS = "GET_CLIENT_REG_SUCCESS";
export const GET_CLIENT_REG_FAILED = "GET_CLIENT_REG_FAILED";


export const GET_TASK_LIST_INIT = "GET_TASK_LIST_INIT";
export const GET_TASK_LIST_FINISH = "GET_TASK_LIST_FINISH";
export const GET_TASK_LIST_SUCCESS = "GET_TASK_LIST_SUCCESS";
export const GET_TASK_LIST_FAILED = "GET_TASK_LIST_FAILED";



export const GET_SCHEDULE_TASK_LIST_INIT = "GET_SCHEDULE_TASK_LIST_INIT";
export const GET_SCHEDULE_TASK_LIST_FINISH = "GET_SCHEDULE_TASK_LIST_FINISH";
export const GET_SCHEDULE_TASK_LIST_SUCCESS = "GET_SCHEDULE_TASK_LIST_SUCCESS";
export const GET_SCHEDULE_TASK_LIST_FAILED = "GET_SCHEDULE_TASK_LIST_FAILED";

export const UPDATE_TASK_LIST = "UPDATE_TASK_LIST";


export const PM_REGISTRATION_INIT = "PM_REGISTRATION_INIT";
export const PM_REGISTRATION_FINISH = "PM_REGISTRATION_FINISH";
export const PM_REGISTRATION_SUCCESS = "PM_REGISTRATION_SUCCESS";
export const PM_REGISTRATION_FAILED = "PM_REGISTRATION_FAILED";


export const CLIENT_REGISTRATION_INIT = "CLIENT_REGISTRATION_INIT";
export const CLIENT_REGISTRATION_FINISH = "CLIENT_REGISTRATION_FINISH";
export const CLIENT_REGISTRATION_SUCCESS = "CLIENT_REGISTRATION_SUCCESS";
export const CLIENT_REGISTRATION_FAILED = "CLIENT_REGISTRATION_FAILED";

export const CLIENT_REGISTRATION_MESSAGE = "CLIENT_REGISTRATION_MESSAGE";


export const TASK_ALLOCATION_INIT = "TASK_ALLOCATION_INIT";
export const TASK_ALLOCATION_FINISH = "TASK_ALLOCATION_FINISH";
export const TASK_ALLOCATION_SUCCESS = "TASK_ALLOCATION_SUCCESS";
export const TASK_ALLOCATION_FAILED = "TASK_ALLOCATION_FAILED";

export const ADD_USER_INIT = "ADD_USER_INIT";
export const ADD_USER_FINISH = "ADD_USER_FINISH";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILED = "ADD_USER_FAILED";



export const GET_USERS_INIT = "GET_USERS_INIT";
export const GET_USERS_FINISH = "GET_USERS_FINISH";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILED = "GET_USERS_FAILED";



export const FIRM_CHANGE_UPDATE = "FIRM_CHANGE_UPDATE";

export const DELETE_PRACTICE_STATUS = "DELETE_PRACTICE_STATUS";
export const DELETE_CLIENT_STATUS = "DELETE_CLIENT_STATUS";
export const DELETE_TASK_STATUS = "DELETE_TASK_STATUS";

export const DELETE_TASK_DATA = "DELETE_TASK_DATA";

export const UPDATE_TASK_STATUS = "UPDATE_TASK_STATUS";
export const UPDATE_TASK_DATA = "UPDATE_TASK_DATA";
export const UPDATE_CLIENT_STATUS = "UPDATE_CLIENT_STATUS";
export const UPDATE_TASK_MODAL = "UPDATE_TASK_MODAL";


export const UPDATE_PRACTICE_INIT = "UPDATE_PRACTICE_INIT";
export const UPDATE_PRACTICE_FINISH = "UPDATE_PRACTICE_FINISH";
export const UPDATE_PRACTICE_REG_STATUS = "UPDATE_PRACTICE_REG_STATUS";


export const DELETE_ATTACHMENT_STATUS = "DELETE_ATTACHMENT_STATUS";

export const GET_COMMENTS_LIST = "GET_COMMENTS_LIST";
export const ADD_COMMENTS_SUCCESS = "ADD_COMMENTS_SUCCESS";
export const CATEGORY_LIST_DATA = "CATEGORY_LIST_DATA"

export const GET_COMPLETED_TASK_LIST = "GET_COMPLETED_TASK_LIST"
export const GET_COMPLETED_TASK_LIST_SUCCESS = "GET_COMPLETED_TASK_LIST_SUCCESS"
export const GET_COMPLETED_TASK_LIST_FAILURE = "GET_COMPLETED_TASK_LIST_FAILURE"

export const GET_PRACTICE_DATA_BY_ID = "GET_PRACTICE_DATA_BY_ID"
export const GET_PRACTICE_DATA_BY_ID_SUCCESS = "GET_PRACTICE_DATA_BY_ID_SUCCESS"
export const GET_PRACTICE_DATA_BY_ID_FAILURE = "GET_PRACTICE_DATA_BY_ID_FAILURE"

export const SELECTED_TASK_DATA = "SELECTED_TASK_DATA"


export const GSTIN_DETAILS = "GSTIN_DETAILS"

export const VIEW_ADD_TASK_MODAL = "VIEW_ADD_TASK_MODAL";


export const GET_COMPLIANCE_INIT = "GET_COMPLIANCE_INIT";
export const GET_COMPLIANCE_FINISH = "GET_COMPLIANCE_FINISH";
export const GET_COMPLIANCE_SUCCESS = "GET_COMPLIANCE_SUCCESS";
export const GET_COMPLIANCE_FAILED = "GET_COMPLIANCE_FAILED";


export const BUCKET_GROUP_TYPE = "BUCKET_GROUP_TYPE";
export const BUCKET_LIST = "BUCKET_LIST";


export const UPDATE_COMLIANCE_INIT = "UPDATE_COMLIANCE_INIT";
export const UPDATE_COMLIANCE_FINISH = "UPDATE_COMLIANCE_FINISH";
export const UPDATE_COMLIANCE_DATA = "UPDATE_COMLIANCE_DATA";


export const UPDATE_SINGLE_DUE_DATE_DATA = "UPDATE_SINGLE_DUE_DATE_DATA";


export const ADD_COMPLIANCE_TO_CLIENT_INIT = "ADD_COMPLIANCE_TO_CLIENT_INIT";
export const ADD_COMPLIANCE_TO_CLIENT_FINISH = "ADD_COMPLIANCE_TO_CLIENT_FINISH";
export const ADD_COMPLIANCE_TO_CLIENT_MESSAGE = "ADD_COMPLIANCE_TO_CLIENT_MESSAGE";

export const VIEW_ADD_CLIENT_MODAL = "VIEW_ADD_CLIENT_MODAL";


export const GET_CHARTS_INIT = "GET_CHARTS_INIT";
export const GET_CHARTS_FINISH = "GET_CHARTS_FINISH";
export const GET_CHARTS_DATA = "GET_CHARTS_DATA";
export const GET_CHARTS_FAILED = "GET_CHARTS_FAILED";



export const COMPLIANCE_APPLICABLE_DATA = "COMPLIANCE_APPLICABLE_DATA";


export const DELETE_COMPLIANCE_APPLICABLE_DATA = "DELETE_COMPLIANCE_APPLICABLE_DATA";


export const GET_DOC_LIST_INIT = "GET_DOC_LIST_INIT";
export const GET_DOC_LIST_FINISH = "GET_DOC_LIST_FINISH";
export const GET_DOC_LIST_SUCCESS = "GET_DOC_LIST_SUCCESS";
export const GET_DOC_LIST_FAILED = "GET_DOC_LIST_FAILED";


export const DELETE_COMPLIANCE_INIT = "DELETE_COMPLIANCE_INIT";
export const DELETE_COMPLIANCE_FINISH = "DELETE_COMPLIANCE_FINISH";
export const DELETE_COMPLIANCE_SUCCSESS = "DELETE_COMPLIANCE_SUCCSESS";
export const DELETE_COMPLIANCE_FAILED = "DELETE_COMPLIANCE_FAILED";



export const ADD_COMPLIANCE_INIT = "ADD_COMPLIANCE_INIT";
export const ADD_COMPLIANCE_FINISH = "ADD_COMPLIANCE_FINISH";
export const ADD_COMPLIANCE_SUCCESS = "ADD_COMPLIANCE_SUCCESS";
export const ADD_COMPLIANCE_FAILED = "ADD_COMPLIANCE_FAILED";


export const GET_REMAINDER_INIT = "GET_REMAINDER_INIT";
export const GET_REMAINDER_FINISH = "GET_REMAINDER_FINISH";
export const GET_REMAINDER_DATA = "GET_REMAINDER_DATA";
export const GET_REMAINDER_MESSAGE = "GET_REMAINDER_MESSAGE";
export const GET_REMAINDER_FAILED = "GET_REMAINDER_FAILED";


export const REMAINDER_EMAIL_INIT = "REMAINDER_EMAIL_INIT";
export const REMAINDER_EMAIL_FINISH = "REMAINDER_EMAIL_FINISH";
export const REMAINDER_EMAIL_MESSAGE = "REMAINDER_EMAIL_MESSAGE";
export const REMAINDER_EMAIL_STATUS = "REMAINDER_EMAIL_STATUS";


export const REMAINDER_WHATSAPP_INIT = "REMAINDER_WHATSAPP_INIT";
export const REMAINDER_WHATSAPP_FINISH = "REMAINDER_WHATSAPP_FINISH";
export const REMAINDER_WHATSAPP_MESSAGE = "REMAINDER_WHATSAPP_MESSAGE";
export const REMAINDER_WHATSAPP_STATUS = "REMAINDER_WHATSAPP_STATUS";

export const GET_BILLS_LIST = "GET_BILLS_LIST";
export const GET_BILLS_LIST_SUCCESS = "GET_BILLS_LIST_SUCCESS";
export const GET_BILLS_LIST_FAILURE = "GET_BILLS_LIST_FAILURE";

export const GET_BILL_DATA_BY_ID = "GET_BILL_DATA_BY_ID";
export const GET_BILL_DATA_BY_ID_SUCCESS = "GET_BILL_DATA_BY_ID_SUCCESS";
export const GET_BILL_DATA_BY_ID_FAILURE = "GET_BILL_DATA_BY_ID_FAILURE";


export const GET_REMAINDER_HISTORY_INIT = "GET_REMAINDER_HISTORY_INIT";
export const GET_REMAINDER_HISTORY_FINISH = "GET_REMAINDER_HISTORY_FINISH";
export const GET_REMAINDER_HISTORY_DATA = "GET_REMAINDER_HISTORY_DATA";



export const GET_EXPENDITURE_BILLS_INIT = "GET_EXPENDITURE_BILLS_INIT";
export const GET_EXPENDITURE_BILLS_FINISH = "GET_EXPENDITURE_BILLS_FINISH";
export const GET_EXPENDITURE_BILLS_SUCCESS = "GET_EXPENDITURE_BILLS_SUCCESS";
export const GET_EXPENDITURE_BILLS_FAILED = "GET_EXPENDITURE_BILLS_FAILED";


export const GET_BILLS_BY_ID_INIT = "GET_BILLS_BY_ID_INIT";
export const GET_BILLS_BY_ID_FINISH = "GET_BILLS_BY_ID_FINISH";
export const GET_BILLS_BY_ID_SUCCESS = "GET_BILLS_BY_ID_SUCCESS";
export const GET_BILLS_BY_ID_FAILED = "GET_BILLS_BY_ID_FAILED";


export const GENERATE_EXP_BILLS_INIT = "GENERATE_EXP_BILLS_INIT";
export const GENERATE_EXP_BILLS_FINISH = "GENERATE_EXP_BILLS_FINISH";
export const GENERATE_EXP_BILLS_SUCCESS = "GENERATE_EXP_BILLS_SUCCESS";
export const GENERATE_EXP_BILLS_FAILED = "GENERATE_EXP_BILLS_FAILED";



export const UPDATE_EXP_BILLS_INIT = "UPDATE_EXP_BILLS_INIT";
export const UPDATE_EXP_BILLS_FINISH = "UPDATE_EXP_BILLS_FINISH";
export const UPDATE_EXP_BILLS_SUCCESS = "UPDATE_EXP_BILLS_SUCCESS";
export const UPDATE_EXP_BILLS_FAILED = "UPDATE_EXP_BILLS_FAILED";



export const DOC_ATTACHMENT_INIT = "DOC_ATTACHMENT_INIT";
export const DOC_ATTACHMENT_FINISH = "DOC_ATTACHMENT_FINISH";
export const DOC_ATTACHMENT_DATA = "DOC_ATTACHMENT_DATA";


export const GET_EXP_STATEMENT_INIT = "GET_EXP_STATEMENT_INIT";
export const GET_EXP_STATEMENT_FINISH = "GET_EXP_STATEMENT_FINISH";
export const GET_EXP_STATEMENT_SUCCESS = "GET_EXP_STATEMENT_SUCCESS";
export const GET_EXP_STATEMENT_FAILED = "GET_EXP_STATEMENT_FAILED";



export const GET_EXP_PAYMENTS_INIT = "GET_EXP_PAYMENTS_INIT";
export const GET_EXP_PAYMENTS_FINISH = "GET_EXP_PAYMENTS_FINISH";
export const GET_EXP_PAYMENTS_SUCCESS = "GET_EXP_PAYMENTS_SUCCESS";
export const GET_EXP_PAYMENTS_FAILED = "GET_EXP_PAYMENTS_FAILED";


export const CREATE_EXP_PAYMENTS_INIT = "CREATE_EXP_PAYMENTS_INIT";
export const CREATE_EXP_PAYMENTS_FINISH = "CREATE_EXP_PAYMENTS_FINISH";
export const CREATE_EXP_PAYMENTS_SUCCESS = "CREATE_EXP_PAYMENTS_SUCCESS";
export const CREATE_EXP_PAYMENTS_FAILED = "CREATE_EXP_PAYMENTS_FAILED";



export const UPDATE_EXP_PAYMENTS_INIT = "UPDATE_EXP_PAYMENTS_INIT";
export const UPDATE_EXP_PAYMENTS_FINISH = "UPDATE_EXP_PAYMENTS_FINISH";
export const UPDATE_EXP_PAYMENTS_SUCCESS = "UPDATE_EXP_PAYMENTS_SUCCESS";
export const UPDATE_EXP_PAYMENTS_FAILED = "UPDATE_EXP_PAYMENTS_FAILED";



export const DELETE_PRACTICE_INIT = "DELETE_PRACTICE_INIT";
export const DELETE_PRACTICE_FINISH = "DELETE_PRACTICE_FINISH";


export const GET_AGAINST_BILL_INIT = "GET_AGAINST_BILL_INIT";
export const GET_AGAINST_BILL_FINISH = "GET_AGAINST_BILL_FINISH";
export const GET_AGAINST_BILL_DATA = "GET_AGAINST_BILL_DATA";
export const GET_AGAINST_BILL_FAILED = "GET_AGAINST_BILL_FAILED";
export const UPDATE_BILL_AMOUNT = "UPDATE_BILL_AMOUNT";
export const UPDATE_BILL_AMOUNT_SUCCESS = "UPDATE_BILL_AMOUNT_SUCCESS";
export const UPDATE_BILL_AMOUNT_FAILURE = "UPDATE_BILL_AMOUNT_FAILURE";



export const DOCUMENT_ERROR = "DOCUMENT_ERROR";


export const COPY_TASK = "COPY_TASK";


export const DELETE_USER_INVITATION_INIT = "DELETE_USER_INVITATION_INIT";
export const DELETE_USER_INVITATION_FINISH = "DELETE_USER_INVITATION_FINISH";
export const DELETE_USER_INVITATION_DATA = "DELETE_USER_INVITATION_DATA";


export const DELETE_COMPANY_USER_INIT = "DELETE_COMPANY_USER_INIT";
export const DELETE_COMPANY_USER_FINISH = "DELETE_COMPANY_USER_FINISH";
export const DELETE_COMPANY_USER_DATA = "DELETE_COMPANY_USER_DATA";
