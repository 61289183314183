import {
  ATTACHMENT_UPLOAD,
  ATTACHMENT_UPLOAD_FAILURE,
  ATTACHMENT_UPLOAD_SUCCESS,
  GET_ATTACHMENT_LIST,
  GET_ATTACHMENT_LIST_SUCCESS,
  GET_ATTACHMENT_LIST_FAILURE,
  ATTACHMENT_DELETE,
  ATTACHMENT_DELETE_SUCCESS,
  ATTACHMENT_DELETE_FAILURE,
  ATT_INIT_STATUS,
  TRIGGER_QUERY_SAVE,
  TRIGGER_QUERY_SAVE_DEFAULT,
  GET_ATTACHMENT_BY_SEARCH,
  GET_ATTACHMENT_BY_SEARCH_SUCCESS,
  GET_ATTACHMENT_BY_SEARCH_FAILURE,
  DOCUMENT_ERROR,
} from "src/actions/actionTypes";
import { AttachmentUploadStatus, getList } from "src/util/enums";

const initialState = {
  uploadStatus: AttachmentUploadStatus.NONE,
  attachmentList:[],
  attachmentListBySearch: null,
  attDeleteStatus:AttachmentUploadStatus.NONE,
  queryTriggerStatus: 0, // 0 - initial // 1 - After save
  documentError: false
};

export default function attachmentsReducer(state = initialState, action) {
  switch (action.type) {
    case ATTACHMENT_UPLOAD: {
      return { ...state, uploadStatus: AttachmentUploadStatus.INPROGRESS };
    }
    case ATTACHMENT_UPLOAD_SUCCESS: {
      return { ...state, uploadStatus: AttachmentUploadStatus.SUCCESS };
    }
    case ATTACHMENT_UPLOAD_FAILURE: {
      return { ...state, uploadStatus: AttachmentUploadStatus.FAILED };
    }
    case GET_ATTACHMENT_LIST_SUCCESS: {
      return { ...state, attachmentList: action.payload };
    }
    case ATTACHMENT_DELETE: {
      return { ...state, attDeleteStatus: AttachmentUploadStatus.INPROGRESS};
    }
    case ATTACHMENT_DELETE_SUCCESS: {
      return { ...state, attDeleteStatus: AttachmentUploadStatus.SUCCESS };
    }
    case ATTACHMENT_DELETE_FAILURE: {
      return { ...state, attDeleteStatus: AttachmentUploadStatus.FAILED };
    }
    case ATT_INIT_STATUS: {
      return { ...state, uploadStatus: AttachmentUploadStatus.NONE };
    }
    case TRIGGER_QUERY_SAVE: {
      return { ...state, queryTriggerStatus: 1 };
    }
    case TRIGGER_QUERY_SAVE_DEFAULT: {
      return { ...state, queryTriggerStatus: 0 };
    }
    case GET_ATTACHMENT_BY_SEARCH: {
      return { ...state, attachmentListBySearch: null };
    }
    case GET_ATTACHMENT_BY_SEARCH_SUCCESS: {
      return { ...state, attachmentListBySearch: action.payload };
    }
    case DOCUMENT_ERROR: {
      return { ...state, documentError: action.payload };
    }    
    default:
      return state;
  }
}
