import React, {  } from 'react'
import Logofull from "src/assets/icons/logo-large.png";
import {
    CCard,
    CCardBody,
    CCol,
    CContainer,
    CRow
} from '@coreui/react'
import { useHistory } from "react-router-dom";




const ResetPasswordSuccess = () => {
    const history = useHistory()
    const redirectToLoginPage = () => {
        history.push('/login')
    }
    return (
        <>
            <div className="">

                <div className="c-app c-default-layout flex-row align-items-center mdb-shadow">
                    <CContainer>
                        <CRow className="justify-content-center">
                            <CCol xl="6" lg="6" md="12" sm="12">
                                <CCard className="border-0">
                                    <CCardBody className="p-0">
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                                <div className="p-2 verify-bg">
                                                    <img src={Logofull} className="c-sidebar-brand-full mobile-logo-width img-fluid" />{" "}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center p-5 inside-border-radius">
                                            <span className="verify-success-icon-view">
                                                <i className="fas fa-check" aria-hidden="true"></i>
                                            </span>
                                            <h5 className="bold-label mb-4 mt-4">Password Changed</h5>
                                            <hr className="hr-light-border mt-3 mb-2 w-25"></hr>
                                            <div className="pl-2 pr-2">
                                                <h6 className="status-font mb-4 line-height-custom">Your password has been updated successfully,</h6>
                                                <button onClick={redirectToLoginPage} className="btn btn-primary btn-lg btn-shadow">Return to Login </button>
                                            </div>    
                                        </div>
                                       
                                    </CCardBody>
                                </CCard>
                                {/* <hr className="hr-light-border mt-5"></hr> */}
                                     
                            </CCol>
                        </CRow>
                    </CContainer>
                </div>
            </div>
        </>
    )
}


export default ResetPasswordSuccess
